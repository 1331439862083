import Router from '../../../routes/router';

import countriesMenuIcon from '../../../assets/images/icon-country.svg';
import frameworkMenuIcon from '../../../assets/images/icon-frameworks.svg';
import companiesMenuIcon from '../../../assets/images/icon-companies.svg';
import usersMenuIcon from '../../../assets/images/icon-users.svg';
import campaignsMenuIcon from '../../../assets/images/icon-campaigns.svg';
import settingsMenuIcon from '../../../assets/images/icon-black-cog.svg';
import reportsMenuIcon from '../../../assets/images/icon-benchmarking.svg';
import notificationIcon from '../../../assets/images/notificationsIcon.svg';


export const allMenuOptions = [

    {
        callback: Router.goToUserManagement,
        name: 'Users',
        imgSrc: usersMenuIcon,
        imgAlt: 'users icon'
    },
    {
        callback: Router.goToCompaniesManagement,
        name: 'Companies',
        imgSrc: companiesMenuIcon,
        imgAlt: 'companies icon'
    },
    {
        callback: Router.goToCountryManagement,
        name: 'Countries',
        imgSrc: countriesMenuIcon,
        imgAlt: 'countries icon'
    },
    {
        callback: Router.goToManageFrameworks,
        name: 'Frameworks',
        imgSrc: frameworkMenuIcon,
        imgAlt: 'frameworks icon'
    },
    {
        callback: Router.goToManageCampaigns,
        name: 'Campaigns',
        imgSrc: campaignsMenuIcon,
        imgAlt: 'campaigns icons'
    },
    {
        callback: Router.goToManageSettings,
        name: 'Settings',
        imgSrc: settingsMenuIcon,
        imgAlt: 'settings icons'
    },
    {
        callback: Router.goToNotificationHub,
        name: 'Notification Hub',
        imgSrc: notificationIcon,
        imgAlt: 'notifications icons'
    },
    {
        callback: Router.goToReports,
        name: 'Reports',
        imgSrc: reportsMenuIcon,
        imgAlt: 'reports icons'
    },
];

//Menu options that need to be hidden for non global admin user
export const menuOptionsToHide = [
    "Countries",
    "Frameworks",
    "Settings",
    "Notification Hub"
];

export const disableReports = [
    "Reports"
];

export const disableNotificationHub = [
    "Notification Hub"
];

export const convertUserRole = (role?: string) => {
    if (role === 'ADMIN') {
        return "Global Admin"
    } else if (role === 'STANDARD') {
        return "Country Admin"
    }
}
