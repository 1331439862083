import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router';
import { styled } from '@mui/material/styles';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';

import Toggle from '../../../components/base/Toggle/Toggle';
import InputTextField from '../../../components/base/Input/TextField';
import RedButton from '../../../components/base/Buttons/RedButton';
import SearchField from '../../../components/base/SearchField/SearchField';
import TransparentButtonInverted from '../../../components/base/Buttons/TransparentButtonInverted';
import Tabs from '../../../components/Tabs/Tab';
import NewTable from '../../../components/Table/Table';
import DropdownSingleSelection from '../../../components/base/Dropdowns/DropdownSingleSelection';
import DropdownWithGroups from '../../../components/base/Dropdowns/DropdownWithGroups';

import iconSave from '../../../assets/images/icon-save.svg';

import { TableRow } from '../../../components/Table/types';
import {
    FrameworkFilters,
    FrameworkSummariesByCountry,
    Framework,
    UserListPage,
    UserSummaries,
    UsersFilters,
    Territory,
    AddEditCountryProps,
    CountryOrder
} from '../countryTypes';
import {
    ChangeToggle
} from '../../../components/base/Toggle/types';
import { disabledEnabledDropdownValues, adminDropdownValues, internalPermissionsDropdownValues } from '../countryHelpers';
import Router from '../../../routes/router';
import { DropdownValue, DropdownValueType } from '../../../components/base/Dropdowns/types';
import {
    ADD_COUNTRIES,
    ALL_FRAMEWORKS,
    ALL_USERS,
    COUNTRY_DETAILS,
    DROPDOWN_VALUES,
    EDIT_COUNTRY,
    FRAMEWORKS_BY_COUNTRY,
    USERS_BY_COUNTRY,
    PROPOSITIONS,
    DELETE_COUNTRY
} from '../countryQueries';
import {
    actionHandler,
    benchmarkingToggleLabels,
    getHeaderFrameworksData,
    getHeaderUsersData,
    setFrameworkTableRows,
    setUsersTableRows,
    statusToggleLabels,
    tabLabels,
    TabOptions
} from './addEditCountryHelpers';
import { CircularProgress } from '@mui/material';
import DialogBox from '../../../components/base/DialogBox';
import { SuccessModal } from '../../../components';
import FailureModal from '../../../components/base/FailureModal';
import { countryDeleteFailed, countryDeleteSuccessful } from '../../../utils/constants';
import { UserStore } from '../../../context/user-store';

const AddEditCountry: React.FC = () => {
    const params = useParams<AddEditCountryProps>();
    const [countryId, setCountryId] = useState<number | undefined>();
    const [isEdit, setIsEdit] = useState<boolean | undefined>(undefined);
    const {
        state: { user },
    } = useContext(UserStore);

    useEffect(() => {
        if (params && params.countryId) {
            setCountryId(parseInt(decodeURIComponent(params.countryId)));
            setIsEdit(true);
        } else {
            setIsEdit(false);
        }
    }, [params]);

    const [currentTab, setCurrentTab] = useState<string>(TabOptions.FRAMEWORKS);
    const [addCountry] = useMutation(ADD_COUNTRIES);
    const [editCountry] = useMutation(EDIT_COUNTRY);

    const { loading: loadingDropdownValues, error:errorDropdownValues, data:dataDropdownValues } = useQuery(DROPDOWN_VALUES);
    const [order, setOrder] = useState<CountryOrder|undefined>();
    const [allGCPRegions, setAllGCPRegions] = useState<DropdownValueType[]>([
        {
            id: 0,
            name: ''
        }
    ]);
    const [allTerritories, setAllTerritories] = useState<Territory[]>([
        {
            territory: '',
            region: ''
        }
    ]);

    useEffect(() => {
        if (loadingDropdownValues || errorDropdownValues) {
            return;
        }

        setAllTerritories(dataDropdownValues.territories);
        // Locking the GCP Region
        // previous : setAllGCPRegions(dataDropdownValues.gcpRegions);
        const gcpRegionSupported = window.__ENV__.GCP_REGION_SUPPORTED ?? 'europe-west2';
        setAllGCPRegions((typeof isEdit === 'boolean' && isEdit) ?
            dataDropdownValues.gcpRegions :
            dataDropdownValues.gcpRegions.filter((gcpRegion: DropdownValueType) => (gcpRegion.name === gcpRegionSupported)));
    }, [dataDropdownValues, loadingDropdownValues, errorDropdownValues]);
    const perPage = 50;

    // Framework tab states
    const [frameworksPage, setFrameworksPage] = useState<number>(0);
    const [frameworkSearchText, setFrameworkSearchText] = useState<string>('');
    const [frameworkFilters, setFrameworkFilters] = useState<FrameworkFilters>({
        frameworkEnabled: 'All'
    });
    const [selectedFrameworkAccess, setFrameworkAccess] = useState<string>('All');
    const [allSelectedFrameworks, setAllSelectedFrameworks] = useState<number[]>([]);
    const [isOpen, setOpen] = useState<boolean>(false);
    const [frameworkRows, setFrameworkRows] = useState<TableRow[]>();
    const [selectedProposition, setSelectedProposition] = useState<string>(
        'All'
    );

    const { data: dataPropositions } = useQuery(PROPOSITIONS, {
        fetchPolicy: 'cache-first'
    });

    useEffect(() => {
        setFrameworksPage(0);
        setFrameworkFilters({
            frameworkEnabled: selectedFrameworkAccess
        });
    }, [selectedFrameworkAccess]);

    const [allFrameworksResponse, setAllFrameworksResponse] = useState<FrameworkSummariesByCountry>();

    const [getFrameworks,{loading:frameworksLoading}] = useLazyQuery(FRAMEWORKS_BY_COUNTRY, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            setAllFrameworksResponse(res.frameworkSummariesByCountry);

            const enabledFrameworksArr = res.frameworkSummariesByCountry.items
                .filter(
                    (item: Framework) => item.frameworkEnabled && !allInteractedFrameworks.includes(item.frameworkId)
                )
                .map((item: Framework) => item.frameworkId);

            const allUniqueSelectedIds = Array.from(new Set([...enabledFrameworksArr, ...allSelectedFrameworks]));
            setAllSelectedFrameworks(allUniqueSelectedIds);
        },
        onError: (err) => {
            console.log(err);
        }
    });

    const [getAllFrameworks,{loading:allFrameworksLoading}] = useLazyQuery(ALL_FRAMEWORKS, {
        onCompleted: (res) => {
            setAllFrameworksResponse(res.frameworkSummaries);
        },
        onError: (err) => {
            console.log(err);
        }
    });

    useEffect(() => {
        // if (loading) {
        //     return;
        // }
        if (currentTab === TabOptions.FRAMEWORKS) {
            if (typeof isEdit === 'boolean' && !isEdit) {
                getAllFrameworks({
                    variables: {
                        filters: {
                            frameworkEnabled: null,
                            proposition:selectedProposition === 'All' ? undefined : selectedProposition,
                            countryFrameworkEnabled: selectedFrameworkAccess === 'Enabled'
                                                        ? true
                                                        : selectedFrameworkAccess === 'Disabled'
                                                        ? false
                                                        : null
                        },
                        search: frameworkSearchText,
                        pagination: {
                            order: !!order ? order : {field: "name", isAsc: true},
                            skip: frameworksPage * perPage,
                            take: perPage
                        },
                        enabledCountryFrameworkIds: allSelectedFrameworks
                    }
                });
            } else if (typeof isEdit === 'boolean' && isEdit) {
                getFrameworks({
                    variables: {
                        countryId,
                        filters: {
                            frameworkEnabled:
                                frameworkFilters.frameworkEnabled === 'Enabled'
                                    ? true
                                    : frameworkFilters.frameworkEnabled === 'Disabled'
                                    ? false
                                    : null,
                                    proposition:selectedProposition === 'All' ? undefined : selectedProposition
                        },
                        search: frameworkSearchText,
                        pagination: {
                            order: !!order ? order : {field: "name", isAsc: true},
                            skip: frameworksPage * perPage,
                            take: perPage
                        }
                    }
                });
            }
        }
    }, [frameworksPage, frameworkFilters, frameworkSearchText, currentTab, isEdit,order,selectedProposition]);

    const createSortHandler = (property: string) => {
        if(order && order.field===property){
            setOrder({field:property,isAsc:!order.isAsc});
        } else {
            setOrder({field:property,isAsc:true});
        }
    };

    const handleFrameworkChangePage = (event: unknown, newPage: number) => {
        setFrameworksPage(newPage);
    };

    const frameworkTablePagination = {
        rowsPerPage: perPage,
        currentPage: frameworksPage,
        handleChangePage: handleFrameworkChangePage,
        totalCount: allFrameworksResponse && allFrameworksResponse.total
    };

    const headerFrameworksData = getHeaderFrameworksData(createSortHandler);

    useEffect(() => {
        if (!allFrameworksResponse) {
            return;
        }
        const rows = setFrameworkTableRows(
            allFrameworksResponse,
            allSelectedFrameworks,
            handleFrameworksToggleChange
        );

        rows && setFrameworkRows(rows);
    }, [allFrameworksResponse, allSelectedFrameworks]);

    const handleFrameworksToggleChange: ChangeToggle = (event, isActive, cellID) => {
        if (allSelectedFrameworks.includes(cellID)) {
            const arrWithoutCell = allSelectedFrameworks.filter((selFr: number) => selFr !== cellID);
            setAllSelectedFrameworks(arrWithoutCell);
        } else {
            setAllSelectedFrameworks([...allSelectedFrameworks, cellID]);
        }

        if (!allInteractedFrameworks.includes(cellID)) {
            setAllInteractedFrameworks([...allInteractedFrameworks, cellID]);
        }
    };

    // User tab states
    const [usersPage, setUsersPage] = useState<number>(0);
    const [userSearchText, setUserSearchText] = useState<string>('');
    const [usersFilters, setUsersFilters] = useState<UsersFilters>({
        permission: 'All',
        userEnabled: 'All'
    });
    const [selectedUsersAccess, setUsersAccess] = useState<string>('All');
    const [selectedPermission, setSelectedPermission] = useState<string>('All');
    const [allSelectedAdminUsers, setAllSelectedAdminUsers] = useState<number[]>([]);
    const [allSelectedUsers, setAllSelectedUsers] = useState<number[]>([]);
    const [allSelectedCreateCampaigns, setAllSelectedCreateCampaigns] = useState<number[]>([]);
    const [allInteractedAdminUsers, setAllInteractedAdminUsers] = useState<number[]>([]);
    const [allInteractedUsers, setAllInteractedUsers] = useState<number[]>([]);
    const [allInteractedCreateCampaigns, setAllInteractedCreateCampaigns] = useState<number[]>([]);
    const [usersRows, setUsersRows] = useState<TableRow[]>();
    useEffect(() => {
        setUsersPage(0);
        setUsersFilters({
            permission: selectedPermission,
            userEnabled: selectedUsersAccess
        });
    }, [selectedUsersAccess, selectedPermission]);

    const [allUsersByCountry, setAllUsersByCountry] = useState<UserListPage>();

    const [getUsers,{loading:usersLoading}] = useLazyQuery(USERS_BY_COUNTRY, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            setAllUsersByCountry(res.usersByCountry);
            const allAdmins = res.usersByCountry.items
                .filter((users: UserSummaries) => users.countryAdmin && !allInteractedAdminUsers.includes(users.id))
                .map((users: UserSummaries) => users.id);
            const allUniqueAdminIds = Array.from(new Set([...allAdmins, ...allSelectedAdminUsers]));
            setAllSelectedAdminUsers(allUniqueAdminIds);

            const allSelected = res.usersByCountry.items
                .filter((users: UserSummaries) => users.countryUserEnabled && !allInteractedUsers.includes(users.id))
                .map((users: UserSummaries) => users.id);
            const allUniqueUserIds = Array.from(new Set([...allSelected, ...allSelectedUsers]));
            setAllSelectedUsers(allUniqueUserIds);

            const allCreateCampaigns = res.usersByCountry.items
                .filter((users: UserSummaries) => users.campaignCreateEnabled && !allInteractedCreateCampaigns.includes(users.id))
                .map((users: UserSummaries) => users.id);
            const allUniqueIds = Array.from(new Set([...allCreateCampaigns, ...allSelectedCreateCampaigns]));
            setAllSelectedCreateCampaigns(allUniqueIds);
        },
        onError: (err) => {
            console.log(err);
        }
    });

    const [getAllUsers,{loading:allusersLoading}] = useLazyQuery(ALL_USERS, {
        onCompleted: (res) => {
            setAllUsersByCountry(res.userSummaries);
        },
        onError: (err) => {
            console.log(err);
        }
    });

    const handleUsersChangePage = (event: unknown, newPage: number) => {
        setUsersPage(newPage);
    };

    const userTablePagination = {
        rowsPerPage: perPage,
        currentPage: usersPage,
        handleChangePage: handleUsersChangePage,
        totalCount: allUsersByCountry && allUsersByCountry.total
    };

    const headerUsersData = getHeaderUsersData(createSortHandler);

    useEffect(() => {
        if (!allUsersByCountry) {
            return;
        }
        const rows = setUsersTableRows(
            allUsersByCountry,
            allSelectedAdminUsers,
            allSelectedUsers,
            handleUsersEnabledToggleChange,
            handleUsersAdminToggleChange,
            allSelectedCreateCampaigns,
            handleUsersCreateCampaignsToggle
        );
        rows && setUsersRows(rows);
    }, [allUsersByCountry, allSelectedAdminUsers, allSelectedUsers,allSelectedCreateCampaigns]);

    useEffect(() => {
        // if (loading) {
        //     return;
        // }
        if (currentTab === TabOptions.USERS) {
            if (typeof isEdit === 'boolean' && !isEdit) {
                getAllUsers({
                    variables: {
                        filters: {
                            countryIds: [],
                            userEnabled: true,
                            role: usersFilters.permission === 'All' ? ["ADMIN","STANDARD"] : usersFilters.permission,
                            countryUserEnabled: usersFilters.userEnabled === 'Enabled'
                                                ? true
                                                : usersFilters.userEnabled === 'Disabled'
                                                ? false
                                                : undefined
                        },
                        search: userSearchText,
                        pagination: {
                            order: !!order ? order : {field: "name", isAsc: true},
                            skip: usersPage * perPage,
                            take: perPage
                        },
                        enabledCountryUserIds: allSelectedUsers
                    }
                });
            } else if (typeof isEdit === 'boolean' && isEdit) {
                getUsers({
                    variables: {
                        countryId,
                        filters: {
                            countryUserEnabled:
                                usersFilters.userEnabled === 'Enabled'
                                    ? true
                                    : usersFilters.userEnabled === 'Disabled'
                                    ? false
                                    : undefined,
                            role: usersFilters.permission === 'All' ? null : usersFilters.permission
                        },
                        search: userSearchText,
                        pagination: {
                            order: !!order ? order : {field: "name", isAsc: true},
                            skip: usersPage * perPage,
                            take: perPage
                        }
                    }
                });
            }
        }
    }, [usersPage, usersFilters, userSearchText, currentTab, isEdit, order]);

    useEffect(() => {
        setOrder(undefined)
     }, [currentTab]);

    const handleUsersAdminToggleChange: ChangeToggle = (event, isActive, cellID) => {
        if (allSelectedAdminUsers.includes(cellID)) {
            const arrWithoutCell = allSelectedAdminUsers.filter((selFr: number) => selFr !== cellID);
            setAllSelectedAdminUsers(arrWithoutCell);
        } else {
            setAllSelectedAdminUsers([...allSelectedAdminUsers, cellID]);
        }
        if (!allInteractedAdminUsers.includes(cellID)) {
            setAllInteractedAdminUsers([...allInteractedAdminUsers, cellID]);
        }
    };

    const handleUsersEnabledToggleChange: ChangeToggle = (event, isActive, cellID) => {
        if (allSelectedUsers.includes(cellID)) {
            const arrWithoutCell = allSelectedUsers.filter((selFr: number) => selFr !== cellID);
            setAllSelectedUsers(arrWithoutCell);
        } else {
            setAllSelectedUsers([...allSelectedUsers, cellID]);
        }
        if (!allInteractedUsers.includes(cellID)) {
            setAllInteractedUsers([...allInteractedUsers, cellID]);
        }
    };

    const handleUsersCreateCampaignsToggle = (cellID: number) => {
        if (allSelectedCreateCampaigns.includes(cellID)) {
            const arrWithoutCell = allSelectedCreateCampaigns.filter((selFr: number) => {
                return selFr !== cellID;
            });
            setAllSelectedCreateCampaigns(arrWithoutCell);
        } else {
            setAllSelectedCreateCampaigns([...allSelectedCreateCampaigns, cellID]);
        }
        if (!allInteractedCreateCampaigns.includes(cellID)) {
            setAllInteractedCreateCampaigns([...allInteractedCreateCampaigns, cellID]);
        }
    };

    // Form states
    const [countryName, setCountryName] = useState<string>('');
    const [countryNameError, setCountryNameError] = useState<string>('');
    const [benchmarkingStatus, setBenchmarkingStatus] = useState<boolean>(true);
    const [countryEnabled, setCountryEnabled] = useState<boolean>(true);
    const [isoCode, setIsoCode] = useState<string>('');
    const [isoCodeError, setIsoCodeError] = useState<string>('');
    const [selectedGCPRegion, setSelectedGCPRegion] = useState<string>('');
    const [gCPRegionError, setGCPRegionError] = useState<string>('');
    const [selectedTerritory, setSelectedTerritory] = useState<DropdownValue>({ child: '', parent: '' });
    const [territoryError, setTerritoryError] = useState<string>('');
    const [allInteractedFrameworks, setAllInteractedFrameworks] = useState<number[]>([]);
    const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
    const [deleteCountry, setDeleteCountry] = useState<boolean>(false);
    const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
    const [openErrorModal, setOpenErrorModal] = useState<boolean>(false)

    useEffect(() => {
        if (!!selectedTerritory.child) {
            setTerritoryError('');
        }
        if (!!selectedGCPRegion) {
            setGCPRegionError('');
        }
    }, [selectedTerritory.child, selectedGCPRegion]);

    const cancelHandler = () => {
        Router.goToCountryManagement();
    };

    const deleteHandler = () => {
        setDeleteConfirmation(true);
    };

    const saveChanges = () => {
        if (!countryName.trim()) {
            setCountryNameError('Please fill in country name');
        }
        if (!isoCode) {
            setIsoCodeError('Please fill in country abbreviation');
        }
        if (!selectedTerritory.child) {
            setTerritoryError('Please select territory');
        }
        if (!selectedGCPRegion) {
            setGCPRegionError('Please select GCP region');
        }
        if (!countryName.trim() || !isoCode || !selectedTerritory.child || !selectedGCPRegion) {
            return;
        }

        let resultAction: any;
        if (typeof isEdit === 'boolean' && isEdit) {
            resultAction = editCountry;
        } else {
            resultAction = addCountry;
        }

        actionHandler(
            resultAction,
            countryName,
            isoCode,
            selectedTerritory,
            countryEnabled,
            benchmarkingStatus,
            setIsoCodeError,
            setCountryNameError,
            allInteractedFrameworks,
            allSelectedFrameworks,
            allGCPRegions,
            selectedGCPRegion,
            allInteractedAdminUsers,
            allInteractedUsers,
            allSelectedUsers,
            allUsersByCountry,
            allSelectedAdminUsers,
            allSelectedCreateCampaigns,
            allInteractedCreateCampaigns,
            countryId,
            isEdit
        );
    };

    const handleChangeTabs = (newTab: string) => {
        setCurrentTab(newTab);
    };

    // EditCountry query
    const [titleLabel, setTitleLabel] = useState<string>('Add country');
    const [getCountry] = useLazyQuery(COUNTRY_DETAILS, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            setCountryName(res.country.name);
            setIsoCode(res.country.isoCode);
            setSelectedTerritory({
                parent: res.country.region,
                child: res.country.territory
            });
            setCountryEnabled(res.country.countryEnabled);
            setBenchmarkingStatus(res.country.benchmarkingEnabled);
            // Locking the GCP Region
            // previous : const currRegion = allGCPRegions.find((region: DropdownValueType) => region.id === res.country.gcpRegionId);
            const currRegion = (typeof isEdit === 'boolean' && isEdit) ?
                allGCPRegions.find((region: DropdownValueType) => region.id === res.country.gcpRegionId) :
                allGCPRegions.find((region: DropdownValueType) => region.name === (window.__ENV__.GCP_REGION_SUPPORTED ?? 'europe-west2'));
            currRegion && setSelectedGCPRegion(currRegion.name);
        },
        onError: (err) => {
            console.log(err);
        }
    });

    const [deleteCountryQuery] = useMutation(DELETE_COUNTRY ,{
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            Router.goToCountryManagement(`deleted`)
            setOpenSuccessModal(true);
        },
        onError: (err) => {
            setOpenErrorModal(true);
            console.log(err);
        }
    });

    useEffect(() => {
        if (typeof isEdit === 'boolean' && isEdit) {
            setTitleLabel('Edit country');
            getCountry({
                variables: {
                    countryId
                }
            });
        }
    }, [isEdit]);

    useEffect(()=>{
        if(deleteCountry){
            deleteCountryQuery({
                variables:{
                    data: {
                        countryId: countryId
                    }
                },
            })
        }
    },[deleteCountry])

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCountryNameError('');
        setCountryName(event.target.value);
    };

    const handleCountryAbbrChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length > 3) {
            setIsoCodeError('ISO code should be 3 characters or less');
        } else if (/[^a-zA-Z]/.test(event.target.value)) {
            setIsoCodeError('ISO code should contain only letters');
        } else {
            setIsoCode(event.target.value.toUpperCase());
            setIsoCodeError('');
        }
    };

    return (
        <MainWrapper>
            <HeaderWrapper>
                <Header>
                    <h1>{titleLabel}</h1>
                    <ButtonsWrapper>
                        <CancelButton disabled={false} onClick={cancelHandler}>
                            Cancel
                        </CancelButton>
                        {/* {user.role === 'ADMIN' &&
                        <DeleteButton disabled={user.role !== 'ADMIN'} onClick={deleteHandler}>
                             Delete
                         </DeleteButton> } */}

                        <SaveButton disabled={false} onClick={saveChanges}>
                            <img src={iconSave} alt="add country icon" />
                            Save
                        </SaveButton>
                    </ButtonsWrapper>
                </Header>
            </HeaderWrapper>
            <div>
                <TitleAndToggles>
                    <TogglesWrapper>
                        Benchmarking
                        <BenchmarkingToggle
                            labels={benchmarkingToggleLabels}
                            state={benchmarkingStatus}
                            actionFunc={() => setBenchmarkingStatus(!benchmarkingStatus)}
                            cellID="Benchmarking-toogle"
                        />
                        Status
                        <StatusToogle
                            labels={statusToggleLabels}
                            state={countryEnabled}
                            actionFunc={() => {
                                setCountryEnabled(!countryEnabled);
                            }}
                            cellID="Status-toogle"
                        />
                    </TogglesWrapper>
                </TitleAndToggles>
                <CountryDetails>
                    <CustomTextField
                        labelText="Country name"
                        value={countryName}
                        isError={countryNameError.length > 0}
                        errorMessage={countryNameError}
                        onBlurFunc={handleNameChange}
                        onChangeFunc={handleNameChange}
                        placeholderText="Add country name"
                    />
                    <CustomTextField
                        labelText="Country abbreviation"
                        value={isoCode}
                        onChangeFunc={handleCountryAbbrChange}
                        isError={isoCodeError.length > 0}
                        errorMessage={isoCodeError}
                        placeholderText="Add country abbreviation"
                    />
                    <DropdownSingleSelection
                        labelText="GCP Location"
                        isDisabled={(typeof isEdit === 'boolean' && isEdit)}
                        selectedValue={selectedGCPRegion}
                        setValue={setSelectedGCPRegion}
                        placeholder={'Select GCP location'}
                        allValues={allGCPRegions}
                        errorMessage={gCPRegionError}
                    />
                    <DropdownWithGroups
                        data={allTerritories.map((item) => ({ child: item.territory, parent: item.region }))}
                        isOpen={isOpen}
                        setOpen={setOpen}
                        placeholder={selectedTerritory.child || 'Select Territory'}
                        buttonDisabled={false}
                        selected={{ child: selectedTerritory.child || '', parent: selectedTerritory.parent }}
                        setSelected={setSelectedTerritory}
                        labelText={'Region | Territory'}
                        errorMessage={territoryError}
                    />
                </CountryDetails>
                <Tabs tabLabels={tabLabels} activeTab={currentTab} action={handleChangeTabs} />
                {currentTab === TabOptions.FRAMEWORKS && (
                    <>
                        <TableFiltersAndSearch>
                            <TableFilters>
                            <DropdownSingleSelection
                                  labelText={'Proposition'}
                                  isDisabled={false}
                                  selectedValue={selectedProposition}
                                  setValue={setSelectedProposition}
                                  allValues={
                                        dataPropositions &&
                                        dataPropositions.frameworkPropositions && [
                                            { id: 0, name: 'All' },
                                            ...dataPropositions.frameworkPropositions.slice().sort().map(
                                                (item: string, i: number) => ({
                                                    id: i + 1,
                                                    name: item
                                                })
                                            )
                                        ]
                                    }
                                width="10em"
                            />
                            <DropdownSingleSelection
                                labelText={'Framework access'}
                                isDisabled={false}
                                selectedValue={selectedFrameworkAccess}
                                setValue={setFrameworkAccess}
                                allValues={disabledEnabledDropdownValues}
                                width={'14em'}
                            />
                            </TableFilters>
                            <SearchAndSave>
                                <SearchField
                                    placeholder="Search"
                                    callback={(value: string) => {
                                        setFrameworksPage(0);
                                        setFrameworkSearchText(value)
                                    }}
                                />
                            </SearchAndSave>
                        </TableFiltersAndSearch>
                        {(frameworksLoading||allFrameworksLoading) ? (
                        <CircularProgress />
                    ):
                        <CustomTable
                            header={headerFrameworksData}
                            tablePagination={frameworkTablePagination}
                            rows={frameworkRows}
                        />}
                    </>
                )}
                {currentTab === TabOptions.USERS && (
                    <>
                        <TableFiltersAndSearch>
                            <TableFilters>
                                <DropdownSingleSelection
                                    labelText="Permission"
                                    isDisabled={false}
                                    selectedValue={selectedPermission}
                                    setValue={setSelectedPermission}
                                    allValues={typeof isEdit === 'boolean' && isEdit ? adminDropdownValues : internalPermissionsDropdownValues}
                                    width="10em"
                                />
                                <DropdownSingleSelection
                                    labelText="User access"
                                    isDisabled={false}
                                    selectedValue={selectedUsersAccess}
                                    setValue={setUsersAccess}
                                    allValues={disabledEnabledDropdownValues}
                                    width="14em"
                                />
                            </TableFilters>
                            <SearchAndSave>
                                <SearchField
                                    placeholder="Search"
                                    callback={(value: string) => {
                                        setUsersPage(0);
                                        setUserSearchText(value)
                                    }}
                                />
                            </SearchAndSave>
                        </TableFiltersAndSearch>
                        {(allusersLoading||usersLoading) ? (
                        <CircularProgress />
                    ):
                        <CustomTable header={headerUsersData} tablePagination={userTablePagination} rows={usersRows} />}
                    </>
                )}
            </div>
            {/* { deleteConfirmation &&
                <DialogBox
                    content={
                        <div>
                          <p><strong>Warning:</strong> Deleting country "{countryName}" will permanently remove it.</p>
                          <p>Are you sure you want to continue?</p>
                        </div>
                      }
                    open={deleteConfirmation}
                    buttonCancelText={'Cancel'}
                    handleClose={()=> setDeleteConfirmation(false)}
                    handleSubmit={()=> {
                        setDeleteCountry(true);
                        setDeleteConfirmation(false);
                    }}
                />
            } */}
             <SuccessModal
                openState={openSuccessModal}
                hideDuration={6000}
                setOpen={setOpenSuccessModal}
                message={countryDeleteSuccessful}
            />
            <FailureModal
                openState={openErrorModal}
                hideDuration={6000}
                setOpen={setOpenErrorModal}
                message={countryDeleteFailed}
            />
        </MainWrapper>
    );
};

const CustomTable = styled(NewTable)`
    && {
        && {
            &&.MuiTableContainer-root {
                max-height: 150px;
            }
        }
    }
`;

const MainWrapper = styled('div')`
    display: flex;
    flex-direction: column;
`;

const HeaderWrapper = styled('div')`
    height: 165px;
`;

const Header = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #151515;
    height: 107px;
    color: white;
    position: absolute;
    width: 78%;
    left: 0;
    padding-left: 22%;
    z-index: 10;
`;

const CancelButton = styled(TransparentButtonInverted)`
    width: 180px;
    font-size: 18px !important;
`;

const DeleteButton = styled(TransparentButtonInverted)`
    margin-left: 10px !important;
    width: 180px;
    font-size: 18px !important;
`;

const SaveButton = styled(RedButton)`
    margin-left: 10px !important;
    width: 180px;
    font-size: 18px !important;
    img {
        padding-right: 3px;
    }
`;

const ButtonsWrapper = styled('div')`
    display: flex;
    padding-right: 56px;
    height: 50%;
`;

const TitleAndToggles = styled('div')`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
`;

const TogglesWrapper = styled('div')`
    display: flex;
    align-items: center;
`;

const StatusToogle = styled(Toggle)`
    margin-left: 0.75rem;
`;

const BenchmarkingToggle = styled(Toggle)`
    margin-left: 0.75rem;
    margin-right: 3.125rem;
`;

const CountryDetails = styled('div')`
    display: flex;
    margin-top: 25px;
`;

const CustomTextField = styled(InputTextField)`
   .MuiTextField-root{
        width: 10em !important;
        margin-right: 0.4em !important;
   }
`;

const TableFiltersAndSearch = styled('div')`
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
`;

const TableFilters = styled('div')`
    display: flex;
`;

const SearchAndSave = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

export default AddEditCountry;
