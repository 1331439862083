import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router';
import { styled } from '@mui/material/styles';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';

import {
    RedButton,
    TransparentButtonInverted,
    SearchField,
    Tabs,
    SuccessModal,
    DropdownSingleSelection
} from '../../../components';
import NewTable from '../../../components/Table/Table';
import InternalUserDetails from './InternalUserDetails';
import { UserStore } from '../../../context/user-store';

import Router from '../../../routes/router';
import * as messages from '../../../utils/Constant/messages';

import iconSave from '../../../assets/images/icon-save.svg';
import addIcon from '../../../assets/images/mdi_add_circle.svg';

import {DropdownValueType} from '../../../components/base/Dropdowns/types';
import { TableRow as TableRowInter } from '../../../components/Table/types';
import {
    InternalFormDetails,
    CountryListModel,
    CountryListPage,
    ClientListModel,
    ClientListPage,
    UsersOrder,
    CountrySummaries,
    ClientSummaries,
    CountryUserOverrides,
    CountryByUserFilters,
    ClientByUserFilters,
    AllCountriesResponse
} from '../userTypes';

import {tabLabels, TabOptions, disabledEnabledDropdownValues} from '../userHelpers';

import {
    COUNTRIES_BYUSER_MODEL,
    CLIENTS_BYUSER_MODEL,
    INTERNAL_DROPDOWN_VALUES,
    LIST_COUNTRIES,
    LIST_COMPANIES,
    CREATE_USER,
    GET_INTERNAL_USER,
    UPDATE_USER,
    DELETE_USER
} from '../userQueries';

import {
    getClientsTableHeader,
    getCountriesTableHeader,
    setTableRowsWithClient,
    setTableRowsWithCounrty,
    createTempCountryUserEnabled,
    createTempCountries,
    createTempClients
} from '../userHelpers';

import {
    emailValidationRegEx,
    pwcEmailRegEx
} from '../../../utils/helpers';
import { ErrorModal } from '../../../utils/ErrorModal';
import DialogBox from '../../../components/base/DialogBox/DialogBox';

const AddEditInternalUser: React.FC<any> = ({ history, location }) => {
    const params = useParams<{
        userId: string;
        addEdit: string;
    }>();
    const { state: { user } } = useContext(UserStore);
    const perPage = 50;
    const userId = decodeURIComponent(params.userId ?? '');
    const [titleLabel, setTitleLabel] = useState<string>('Add User');
    const [isEdit, setIsEdit] = useState<boolean | undefined>(undefined);
    const [currentTab, setCurrentTab] = useState<string>(TabOptions.COUNTRIES);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);

    //Table states
    const [clientPage, setClientPage] = useState<number>(0);
    const [countryPage, setCountryPage] = useState<number>(0);
    const [countrySummaries, setCountrySummaries] = useState<CountryListPage>();
    const [clientSummaries, setClientSummaries] = useState<ClientListPage>();
    const [countryTableRows, setCountryTableRows] = useState<TableRowInter[]>();
    const [clientTableRows, setClientTableRows] = useState<TableRowInter[]>();
    const [searchCompanyText, setSearchCompanyText] = useState<string>('');
    const [searchCountryText, setSearchCountryText] = useState<string>('');
    const [order, setOrder] = useState<UsersOrder|undefined>({field:'name',isAsc:true});
    const [userAccess, setUserAccess] = useState<string>('All');
    const [allSelectedIsAdmin, setAllSelectedIsAdmin] = useState<number[]>([]);
    const [allInteractedIsAdmin,setAllInteractedIsAdmin] = useState<number[]>([]);
    const [allSelectedCreateCampaigns, setAllSelectedCreateCampaigns] = useState<number[]>([]);
    const [allInteractedCreateCampaigns,setAllInteractedCreateCampaigns] = useState<number[]>([]);
    const [allSelectedCountryUserEnabled, setAllSelectedCountryUserEnabled] = useState<number[]>([]);
    const [allInteractedCountryUserEnabled,setAllInteractedCountryUserEnabled] = useState<number[]>([]);
    const [allSelectedClientUserEnabled, setAllSelectedClientUserEnabled] = useState<number[]>([]);
    const [allInteractedClientUserEnabled,setAllInteractedClientUserEnabled] = useState<number[]>([]);
    const [countryUserOverrides,setCountryUserOverrides] = useState<CountryUserOverrides[]>([]);
    const [isDialogBoxApproveOpen, setIsDialogBoxApproveOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');


    const { data:dataDropdownValues, loading: loadingDropdownValues, error:errorDropdownValues } = useQuery(INTERNAL_DROPDOWN_VALUES, {
        fetchPolicy: 'network-only',
        variables: {
            pagination: {
                order: {
                    field: 'name',
                    isAsc: true
                }
            }
            //   ,
            //  filters:{countryEnabled: true}
        }
    });
    const [allCountries, setAllCountries] = useState<DropdownValueType[]>([]);
    const [allJobGrades, setAllJobGrades] = useState<DropdownValueType[]>([]);

    const [isRefresh, setIsRefresh] = useState<boolean>(false);
    const [extraError, setExtraError] = useState<{id: string, message: string} | undefined>();

    const [internalFormDetails, setInternalFormDetails] = useState<InternalFormDetails[]>([{
        userName: '',
        userSurname: '',
        userStatus: true,
        selectedJobGrade: '',
        selectedEmail: '',
        selectedPersmission: '',
        selectedPrimaryCountry: ''
    }]);

    useEffect(() => {
        if (params && params.addEdit) {
            setTitleLabel(decodeURIComponent(params.addEdit) + ' PwC user');
            if(decodeURIComponent(params.addEdit) === 'Add'){
                setIsEdit(false);
            } else if(decodeURIComponent(params.addEdit) === 'Edit'){
                setIsEdit(true);
            }
        }
    }, [params]);

    const [getinternalUserDetails] = useLazyQuery(GET_INTERNAL_USER, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            const selectedCountry = allCountries.filter(country => country.id === res.admin_user.primaryCountryId);
            const localObj = {
                userName: res.admin_user.firstName,
                userSurname: res.admin_user.lastName,
                userStatus: res.admin_user.userEnabled,
                selectedJobGrade: !!res.admin_user.jobTitle ? res.admin_user.jobTitle : '',
                selectedEmail: res.admin_user.email,
                selectedPersmission: res.admin_user.role,
                selectedPrimaryCountry: selectedCountry.length > 0 ? selectedCountry[0].name : ''
            }
            setInternalFormDetails([localObj]);
            setIsRefresh(true);
        },
        onError: (err) => {
            console.log(err);
        }
    });

    useEffect(() => {
        if (typeof isEdit === 'boolean' && isEdit && allCountries.length > 0) {
            getinternalUserDetails({
                variables: {
                    query: {
                        id: parseInt(decodeURIComponent(params.userId ?? ''))
                    }
                }
            })
        }
    }, [isEdit, allCountries]);

    useEffect(() => {
        if (loadingDropdownValues || errorDropdownValues) {
            return;
        }

        const dropdownDTO = dataDropdownValues.countrySummaries.items
        .map((item:CountrySummaries) => {
                return {
                id:item.id,
                name: item.name
            }
        });
        setAllCountries(dropdownDTO);

        const jobGradesDTO = dataDropdownValues.jobGrades.map((grade:string, index:number) => {
            return {
                id: index,
                name: grade
            }
        })
        setAllJobGrades(jobGradesDTO);

    }, [dataDropdownValues, loadingDropdownValues, errorDropdownValues]);

    const createNextForm = () => {
        const blankForm = {
            userName: '',
            userSurname: '',
            userStatus: true,
            selectedJobGrade: '',
            selectedEmail: '',
            selectedPersmission: '',
            selectedPrimaryCountry: ''
        }
        setInternalFormDetails([...internalFormDetails, blankForm]);
    };

    const deleteForm = (index:number) => {
        const formCopy = [...internalFormDetails];
        formCopy.splice(index, 1);
        setInternalFormDetails(formCopy);
        setIsRefresh(true);
    };

    const [clientFilters, setClientFilters] = useState<ClientByUserFilters>({
        userEnabled: undefined,
        countryIds: undefined
    });

    const [countryfilters, setCountryFilters] = useState<CountryByUserFilters>({
        countryEnabled: undefined,
        countryUserEnabled: undefined
    });

    const [getAllCountriesSummaries] = useLazyQuery(LIST_COUNTRIES, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            setCountrySummaries(res.countrySummaries);
        },
        onError: (err) => {
            console.log(err);
        }
    });

    const [getAllCompanies] = useLazyQuery(LIST_COMPANIES, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            const allClientsItemsModel = res.clientSummaries.items.map((client:AllCountriesResponse) => ({
                    clientId: client.id,
                    clientName: client.name,
                    countryIsoCode: client.countryIsoCode
                })
            );
            const allClientsModel = {
                take: res.clientSummaries.take,
                skip: res.clientSummaries.skip,
                total: res.clientSummaries.total,
                count: res.clientSummaries.count,
                items: allClientsItemsModel
            }
            setClientSummaries(allClientsModel);
        },
        onError: (err) => {
            console.log(err);
        }
    });

    const [getCountriesByUser] = useLazyQuery<CountryListModel>(COUNTRIES_BYUSER_MODEL, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            setCountrySummaries(res.countrySummariesByUser);

            const allAdmins = res.countrySummariesByUser.items
                .filter((country: CountrySummaries) => country.countryAdmin && !allInteractedIsAdmin.includes(country.id))
                .map((country: CountrySummaries) => country.id);
            const allUniqueAdminIds = Array.from(new Set([...allAdmins, ...allSelectedIsAdmin]));
            setAllSelectedIsAdmin(allUniqueAdminIds);

            const allCampaignCreates = res.countrySummariesByUser.items
                .filter((country: CountrySummaries) => country.campaignCreateEnabled && !allInteractedCreateCampaigns.includes(country.id))
                .map((country: CountrySummaries) => country.id);
            const allUniqueCampaignCreatorIds = Array.from(new Set([...allCampaignCreates, ...allSelectedCreateCampaigns]));
            setAllSelectedCreateCampaigns(allUniqueCampaignCreatorIds);

            const allSelected = res.countrySummariesByUser.items
                .filter((country: CountrySummaries) => country.countryUserEnabled && !allInteractedCountryUserEnabled.includes(country.id))
                .map((country: CountrySummaries) => country.id);
            const allUniqueUserIds = Array.from(new Set([...allSelected, ...allSelectedCountryUserEnabled]));
            setAllSelectedCountryUserEnabled(allUniqueUserIds);
        },
        onError: (err) => {
            console.log(err);
        }
    });

    const [getCompaniesByUser] = useLazyQuery<ClientListModel>(CLIENTS_BYUSER_MODEL, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            setClientSummaries(res.clientSummariesByUser);
            const allSelected = res.clientSummariesByUser.items
                .filter((client: ClientSummaries) => client.clientUserEnabled && !allInteractedClientUserEnabled.includes(client.clientId))
                .map((client: ClientSummaries) => client.clientId);
            const allUniqueUserIds = Array.from(new Set([...allSelected, ...allSelectedClientUserEnabled]));
            setAllSelectedClientUserEnabled(allUniqueUserIds);
        },
        onError: (err) => {
            console.log(err);
        }
    });

    useEffect(() => {
        if (currentTab === TabOptions.COUNTRIES) {
            if(typeof isEdit === 'boolean' && isEdit){
                getCountriesByUser({
                    variables: {
                        countryUserId: parseInt(userId),
                        pagination: {
                            order: !!order ? order : {field: "countryName", isAsc: true},
                            skip: countryPage * perPage,
                            take: perPage
                        },
                        search: searchCountryText,
                        filters: countryfilters
                    }
                });
            } else if(typeof isEdit === 'boolean' && !isEdit){
                getAllCountriesSummaries({
                    variables: {
                        pagination: {
                            order: !!order ? order : {field: "name", isAsc: true},
                            skip: countryPage * perPage,
                            take: perPage
                        },
                        search: searchCountryText,
                        filters: {countryEnabled:true}
                    }
                })
            }
        }
        return undefined;
    }, [currentTab, countryPage, countryfilters, searchCountryText, order]);

    useEffect(() => {
        if (currentTab === TabOptions.COMPANIES) {
            if(typeof isEdit === 'boolean' && isEdit){
                getCompaniesByUser({
                    variables: {
                        countryUserOverrides,
                        userId: parseInt(userId),
                        pagination: {
                            order: !!order ? order : {field: "companyName", isAsc: true},
                            skip: clientPage * perPage,
                            take: perPage
                        },
                        search: searchCompanyText,
                        filters: clientFilters
                    }
                });
            }else if(typeof isEdit === 'boolean' && !isEdit){
                if(!allSelectedCountryUserEnabled || allSelectedCountryUserEnabled.length === 0) {
                    setAllSelectedClientUserEnabled([]);
                    setAllInteractedClientUserEnabled([]);
                    return;
                }
                const countryIds = countryUserOverrides.filter(userObj => userObj.countryUserEnabled).map(userObj => userObj.countryId)
                getAllCompanies({
                    variables: {
                        pagination: {
                            order: !!order ? order : {field: "name", isAsc: true},
                            skip: clientPage * perPage,
                            take: perPage
                        },
                        search: searchCompanyText,
                        filters:  {
                            clientEnabled:true,
                            countryIds: countryIds
                        }
                    }
                });
            }
        }
        return undefined;
    }, [currentTab, clientPage, clientFilters, searchCompanyText, order]);

   useEffect(()=>{
        const filter: ClientByUserFilters = {
            userEnabled: undefined,
            countryIds: undefined
        };

        filter.userEnabled = userAccess === 'Enabled' ? true : userAccess === 'Disabled' ? false : undefined;
        if(allSelectedCountryUserEnabled.length !== 0) filter.countryIds = allSelectedCountryUserEnabled;
        setClientPage(0);
        setClientFilters(filter);
   },[userAccess, allSelectedCountryUserEnabled]);

   useEffect(()=>{
        const filter: CountryByUserFilters = {
            countryUserEnabled: undefined
        };
        filter.countryUserEnabled = userAccess === 'Enabled' ? true : userAccess === 'Disabled' ? false : undefined;
        setCountryPage(0)
        setCountryFilters(filter);
   },[userAccess]);

    useEffect(() => {
        setOrder(undefined);
        setUserAccess('All');
     }, [currentTab]);

    useEffect(() => {
        if (!countrySummaries) {
            return undefined;
        }
        const rows = setTableRowsWithCounrty(countrySummaries,allSelectedIsAdmin,handleCountryAdminToggle,allSelectedCreateCampaigns,handleCreateCampignToggle,allSelectedCountryUserEnabled,handleCountryUserEnabledToggle,'STANDARD',user.role);
        setCountryTableRows(rows);
    }, [countrySummaries, allSelectedIsAdmin, allSelectedCreateCampaigns, allSelectedCountryUserEnabled]);

    useEffect(() => {
        if (!clientSummaries) {
            return undefined;
        }
        const rows = setTableRowsWithClient(clientSummaries,allSelectedClientUserEnabled,handleClientUserEnableToggle,'STANDARD', user.role);

        setClientTableRows(rows);
    }, [clientSummaries, allSelectedClientUserEnabled]);

    useEffect(() => {
        const rows = createTempCountryUserEnabled(allSelectedCountryUserEnabled,allInteractedCountryUserEnabled);
        setCountryUserOverrides(rows);
    }, [allSelectedCountryUserEnabled, allInteractedCountryUserEnabled]);

    const handleCountryAdminToggle = (cellID: number) => {
        if (allSelectedIsAdmin.includes(cellID)) {
            const arrWithoutCell = allSelectedIsAdmin.filter((selFr: number) => {
                return selFr !== cellID;
            });
            setAllSelectedIsAdmin(arrWithoutCell);
        } else {
            setAllSelectedIsAdmin([...allSelectedIsAdmin, cellID]);
        }

        if (!allInteractedIsAdmin.includes(cellID)) {
            setAllInteractedIsAdmin([...allInteractedIsAdmin, cellID]);
        }
    };

    const handleCreateCampignToggle = (cellID: number) => {
        if (allSelectedCreateCampaigns.includes(cellID)) {
            const arrWithoutCell = allSelectedCreateCampaigns.filter((selFr: number) => {
                return selFr !== cellID;
            });
            setAllSelectedCreateCampaigns(arrWithoutCell);
        } else {
            setAllSelectedCreateCampaigns([...allSelectedCreateCampaigns, cellID]);
        }

        if (!allInteractedCreateCampaigns.includes(cellID)) {
            setAllInteractedCreateCampaigns([...allInteractedCreateCampaigns, cellID]);
        }
    };

    const handleClientUserEnableToggle = (cellID:number) => {
        if (allSelectedClientUserEnabled.includes(cellID)) {
            const arrWithoutCell = allSelectedClientUserEnabled.filter((selFr: number) => {
                return selFr !== cellID;
            });
            setAllSelectedClientUserEnabled(arrWithoutCell);
        } else {
            setAllSelectedClientUserEnabled([...allSelectedClientUserEnabled, cellID]);
        }
        if (!allInteractedClientUserEnabled.includes(cellID)) {
            setAllInteractedClientUserEnabled([...allInteractedClientUserEnabled, cellID]);
        }
    };

    const handleCountryUserEnabledToggle = (cellID:number) => {
        if (allSelectedCountryUserEnabled.includes(cellID)) {
            const arrWithoutCell = allSelectedCountryUserEnabled.filter((selFr: number) => {
                return selFr !== cellID;
            });
            setAllSelectedCountryUserEnabled(arrWithoutCell);
        } else {
            setAllSelectedCountryUserEnabled([...allSelectedCountryUserEnabled, cellID]);
        }
        if (!allInteractedCountryUserEnabled.includes(cellID)) {
            setAllInteractedCountryUserEnabled([...allInteractedCountryUserEnabled, cellID]);
        }
    };

    const createSortHandler = (property: string) => {
        if(order&&order.field===property)
        {
            setOrder({field:property,isAsc:!order.isAsc});
        }
        else{
            setOrder({field:property,isAsc:true});
        }

    };

    const handleChangeTabs = (newTab: string) => {
        setCurrentTab(newTab);
    };

    const handleChangeClientPage = (event: unknown, newPage: number) => {
        setClientPage(newPage);
    };

    const handleChangeCountryPage = (event: unknown, newPage: number) => {
        setCountryPage(newPage);
    };

    const countrySearchCallBack=(value:string)=>{
        setSearchCountryText(value);
        setCountryPage(0);
    }

    const companySearchCallBack=(value:string)=>{
        setSearchCompanyText(value);
        setClientPage(0);
    }
    const countriesTableHeader = isEdit ? getCountriesTableHeader(createSortHandler,'STANDARD','edit',user.role) : getCountriesTableHeader(createSortHandler,'STANDARD','add',user.role);
    const clientsTableHeader = isEdit ? getClientsTableHeader(createSortHandler, 'STANDARD', user.role):getClientsTableHeader(createSortHandler, 'STANDARD', user.role,'add');

    const countryTablePagination = {
        rowsPerPage: perPage,
        currentPage: countryPage,
        handleChangePage: handleChangeCountryPage,
        totalCount: countrySummaries && countrySummaries.total
    };

    const clientTablePagination = {
        rowsPerPage: perPage,
        currentPage: clientPage,
        handleChangePage: handleChangeClientPage,
        totalCount: clientSummaries && clientSummaries.total
    };

    const onChangeString = (value:string, field:string, index:number) => {
        const formCopy = [...internalFormDetails];
        if(
            field === 'userName' ||
            field === 'userSurname' ||
            field === 'selectedJobGrade' ||
            field === 'selectedEmail' ||
            field === 'selectedPersmission' ||
            field === 'selectedPrimaryCountry'
        ){
            formCopy[index][field] = value;
        }
        setInternalFormDetails(formCopy);
    }

    const onChangeBoolean = (value:boolean, field:string, index:number) => {
        const formCopy = [...internalFormDetails];
        if(
            field === 'userStatus'
        ){
            formCopy[index][field] = value;
        }
        setInternalFormDetails(formCopy);
    };

    let isDisabled;

    if(typeof isEdit === 'boolean' && !isEdit &&
        allSelectedCountryUserEnabled.length === 0
    ) {
        isDisabled = true;
    } else if(
        typeof isEdit === 'boolean' && !isEdit &&
        allSelectedCountryUserEnabled.length > 0) {
        isDisabled = false;
    }


    const createInternalUserDTO = () => {
        const allCountriesMerged = Array.from(
            new Set([...allSelectedIsAdmin, ...allSelectedCreateCampaigns, ...allSelectedCountryUserEnabled]));

        return internalFormDetails.map(detail => {
            return {
                firstName: detail.userName,
                lastName: detail.userSurname,
                userEnabled: detail.userStatus,
                jobTitle: detail.selectedJobGrade,
                email: detail.selectedEmail,
                role: detail.selectedPersmission,
                primaryCountryId: allCountries.filter(country => {
                    return country.name === detail.selectedPrimaryCountry
                })[0].id,
                clientUsers: allSelectedClientUserEnabled.map(selectedUser => {
                    return{
                        clientId: selectedUser,
                        clientUserEnabled: true
                    }
                }),
                countryUsers: allCountriesMerged.map(country => {
                    return{
                        countryId: country,
                        countryUserEnabled: allSelectedCountryUserEnabled.includes(country),
                        campaignCreateEnabled: allSelectedCreateCampaigns.includes(country),
                        isCountryAdmin: allSelectedIsAdmin.includes(country)
                    }
                })
            }
        })
    };

    const [isError, setIsError] = useState<boolean>(false)

    const [createInternalUser] = useMutation(CREATE_USER);
    const [updateInternalUser] = useMutation(UPDATE_USER);
    const [deleteInternalUser] = useMutation(DELETE_USER);

    const [openErrorModal,setOpenErrorModal] = useState(false)
    const onSaveHandler = () => {
        let isErrorLocal = false;
        if(user.id === parseInt(userId)){
            setOpenErrorModal(!openErrorModal);
        }else{
        for(const detail of internalFormDetails){
            for(const field in detail) {
                if(
                    field === 'userName' ||
                    field === 'userSurname' ||
                    field === 'selectedJobGrade' ||
                    field === 'selectedPersmission' ||
                    field === 'selectedPrimaryCountry'
                ){
                    if(!detail[field]){
                        isErrorLocal=true;
                    }
                } else if( field === 'selectedEmail' ){
                    if(!detail[field] || !emailValidationRegEx.test(detail[field])){
                        isErrorLocal=true;
                    }
                    if(!detail[field] || !pwcEmailRegEx.test(detail[field])){
                        isErrorLocal=true;
                    }
                }
            }
        }
        if(isErrorLocal){
            setIsError(true);
            return
        }
        if(typeof isEdit === 'boolean' && !isEdit){
            createInternalUser({
                variables:{
                    data: createInternalUserDTO()
                }
            }).then(() => {
                Router.goToUserManagement('true');
            })
            .catch(err => {
                const errString = err.toString()
                if (
                    errString.search(
                            'Error: GraphQL error: User with email:'
                        ) !== -1 &&
                    errString.search('already exist') !== -1
                ) {
                    const duplicateEmail = errString.substring(
                        errString.lastIndexOf(":") + 1,
                        errString.lastIndexOf("already exist")).trim();
                    setExtraError({id:duplicateEmail, message: messages.ERROR_USERS_EMAIL_DUPLICATE});
                    setIsRefresh(true);
                }
            })
        } else if (typeof isEdit === 'boolean' && isEdit){
            updateInternalUser({
                variables:{
                    data:{
                        id: !!parseInt(userId) ? parseInt(userId) : undefined,
                        firstName: internalFormDetails[0].userName,
                        lastName: internalFormDetails[0].userSurname,
                        userEnabled: internalFormDetails[0].userStatus,
                        jobTitle: internalFormDetails[0].selectedJobGrade,
                        email: internalFormDetails[0].selectedEmail,
                        role: internalFormDetails[0].selectedPersmission,
                        primaryCountryId: allCountries.filter(country => {
                            return country.name === internalFormDetails[0].selectedPrimaryCountry
                        })[0].id,
                        clientUsers: createTempClients(allInteractedClientUserEnabled, allSelectedClientUserEnabled),
                        countryUsers: createTempCountries(
                            allInteractedIsAdmin,
                            allInteractedCreateCampaigns,
                            allInteractedCountryUserEnabled,
                            allSelectedIsAdmin,
                            allSelectedCreateCampaigns,
                            allSelectedCountryUserEnabled,
                            countrySummaries
                        )
                    }
                }
            }).then(() => {
                Router.goToUserManagement();
            })
            .catch(err => {
                console.log("Error updating external users - ", err)
            })
        }
    }}

    const deleteHandler = () => {
        setIsDialogBoxApproveOpen(true);
    };

    const handleDelete = () => {
        deleteInternalUser({
            variables: {
                data: {
                    userId: !!parseInt(userId) ? parseInt(userId) : undefined
                }
            }
        }).then(() => {
            Router.goToUserManagement('deleted');
        }
        ).catch((error: any) => {
            console.log("Error deleting internal users - ", error.message);
            setIsDialogBoxApproveOpen(false);
            setOpenErrorModal(true);
            setMessage(error.message);


        })
    };

    return (
        <MainWrapper>
            <HeaderWrapper>
                <Header>
                    <h1>{titleLabel}</h1>
                    <ButtonsWrapper>
                        <CancelButton disabled={false} onClick={() => Router.goToUserManagement()}>
                            Cancel
                        </CancelButton>
                        {isEdit &&
                        <DeleteButton disabled={false} onClick={deleteHandler}>
                            Delete
                        </DeleteButton>
                     }
                        <SaveButton
                            disabled={isDisabled}
                            onClick={onSaveHandler}
                        >
                            <img src={iconSave} alt="add user icon" />
                            Save
                        </SaveButton>
                    </ButtonsWrapper>
                </Header>
            </HeaderWrapper>
            <div>
                { internalFormDetails.map((details:InternalFormDetails, index:number) => {
                    let isLast = false;

                    if((internalFormDetails.length - 1) === index){
                        isLast=true;
                    }
                    return (
                            <InternalUserDetails
                                key={index}
                                allCountries={allCountries}
                                userIndex={index}
                                deleteForm={deleteForm}
                                internalFormDetails={details}
                                onChangeString={onChangeString}
                                onChangeBoolean={onChangeBoolean}
                                isRefresh={isRefresh}
                                isLast={isLast}
                                setIsRefresh={setIsRefresh}
                                allJobGrades={allJobGrades}
                                isError={isError}
                                setIsError={setIsError}
                                extraError={extraError}
                                setExtraError={setExtraError}
                                isEdit={isEdit}
                                adminRole={user.role}
                            />
                    )
                })}
                  { typeof isEdit === 'boolean' && isEdit &&
                  <DialogBox
                  buttonSaveText="Done"
                  buttonCancelText="Cancel"
                  content={
                      <div>
                        <p><strong>Warning:</strong> Deleting user "{internalFormDetails[0]?.userName}" will permanently remove it.</p>
                        <p>Are you sure you want to continue?</p>
                      </div>
                    }
                  open={isDialogBoxApproveOpen}
                  handleClose={() => setIsDialogBoxApproveOpen(false)}
                  handleSubmit={() => handleDelete()}
              />
                  }

                { typeof isEdit === 'boolean' && !isEdit && (
                    <>
                        <AddAnotherButton
                            onClick = {createNextForm}
                        >
                            <img src={addIcon} />
                            Add another user
                        </AddAnotherButton>
                        <AddAnotherNote>
                            Note: these users will be part of the same countries and organisations.
                        </AddAnotherNote>
                    </>
                )}
                <Tabs tabLabels={tabLabels} activeTab={currentTab} action={handleChangeTabs} />
                {currentTab === TabOptions.COUNTRIES && (
                    <>
                        <TableFiltersAndSearch>
                            <TableFilters>
                            { typeof isEdit === 'boolean' && isEdit && (
                                <DropdownSingleSelection
                                    labelText="User access"
                                    isDisabled={false}
                                    selectedValue={userAccess}
                                    setValue={setUserAccess}
                                    allValues={disabledEnabledDropdownValues}
                                    width="14em"
                                />
                            )}
                            </TableFilters>
                            <SearchAndSave>
                                <SearchField
                                    placeholder="Search"
                                    callback={(value: string) => countrySearchCallBack(value)}
                                />
                            </SearchAndSave>
                        </TableFiltersAndSearch>
                        <CustomTable
                            header={countriesTableHeader}
                            tablePagination={countryTablePagination}
                            rows={countryTableRows || []}
                        />
                        <SuccessModal
                            openState={openSuccessModal}
                            hideDuration={6000}
                            setOpen={setOpenSuccessModal}
                            message={messages.USER_SUCCESS_MESSAGE}
                        />
                    </>
                )}
                {currentTab === TabOptions.COMPANIES && (
                    <>
                        <TableFiltersAndSearch>
                            <TableFilters>
                                { typeof isEdit === 'boolean' && isEdit && (
                                    <DropdownSingleSelection
                                        labelText="User access"
                                        isDisabled={false}
                                        selectedValue={userAccess}
                                        setValue={setUserAccess}
                                        allValues={disabledEnabledDropdownValues}
                                        width="14em"
                                    />)
                                }
                            </TableFilters>
                            <SearchAndSave>
                                <SearchField
                                    placeholder="Search"
                                    callback={(value: string) => companySearchCallBack(value)}
                                />
                            </SearchAndSave>
                        </TableFiltersAndSearch>
                        <CustomTable
                            header={clientsTableHeader}
                            tablePagination={clientTablePagination}
                            rows={clientTableRows || []}
                        />
                        <SuccessModal
                            openState={openSuccessModal}
                            hideDuration={6000}
                            setOpen={setOpenSuccessModal}
                            message={messages.USER_SUCCESS_MESSAGE}
                        />
                    </>
                )}
            </div>
            <ErrorModal
                openErrorModal={openErrorModal}
                message={message}
                setOpenErrorModal={setOpenErrorModal}
            />
        </MainWrapper>
    );
};

const DeleteButton = styled(TransparentButtonInverted)`
    width: 180px;
    margin-left: 10px !important;
    font-size: 18px !important;
`;

const MainWrapper = styled('div')`
    display: flex;
    flex-direction: column;
`;

const HeaderWrapper = styled('div')`
    height: 165px;
`;

const Header = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #151515;
    height: 107px;
    color: white;
    position: absolute;
    width: 78%;
    left: 0;
    padding-left: 22%;
    z-index: 10;
`;

const CancelButton = styled(TransparentButtonInverted)`
    width: 180px;
    font-size: 18px !important;
`;

const SaveButton = styled(RedButton)`
    margin-left: 10px !important;
    width: 180px;
    font-size: 18px !important;
    img {
        padding-right: 3px;
    }
`;

const ButtonsWrapper = styled('div')`
    display: flex;
    padding-right: 56px;
    height: 50%;
`;

const AddAnotherButton = styled('div')`
    display: block;
    width: fit-content;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 10px;
    img {
        vertical-align: middle;
        margin-right: 0.2em;
    }
`;

const AddAnotherNote = styled('div')`
    font-size: 14px;
    padding: 5px 0 20px;
`;

const TableFiltersAndSearch = styled('div')`
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
`;

const TableFilters = styled('div')`
    display: flex;
`;

const SearchAndSave = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

const CustomTable = styled(NewTable)`
    && {
        && {
            &&.MuiTableContainer-root {
                max-height: 150px;
            }
        }
    }
`;

export default AddEditInternalUser;
