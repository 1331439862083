import React from 'react';
import { styled } from '@mui/material/styles';

import {
    TabsProps
} from './types';


const Tabs : React.FC<TabsProps> = ({tabLabels, activeTab, action}) => {

    return(
        <TabsWrapper>
            {tabLabels.map((tab:string, index: number) => (
                <TabOption
                    key={index}
                    isActive={activeTab === tab}
                    onClick={(e: any) => {
                        action(tab)
                    }}
                >
                    {tab}
                </TabOption>
            ))}
        </TabsWrapper>
    )
}

const TabsWrapper = styled('div')`
    width: 100%;
    display: flex;
    border-bottom: 1px solid #818181;
    margin-top: 20px;
`;

const TabOption = styled('div')<{
    isActive?: boolean;
}>`
    border-bottom: ${props => props.isActive ? '6px solid #DE3328' : 'none'};
    padding: 8px;
    font-weight: ${props => props.isActive ? '500' : 'normal'};
    font-size: 20px;
    margin-bottom: -6px;
    cursor: pointer;
`;

export default Tabs;