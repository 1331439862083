import noDataIcon from '../../assets/images/no-data.svg';
import iconZoom from '../../assets/images/icon-zoom.svg';
import React from 'react';
import { styled } from '@mui/material/styles';

type Props = {
    title: string;
    description: string;
};
const NoDataScreen: React.FC<Props> = ({ title, description }) => {
    return (
         <AlignItemsByColumn>
                <div>
                    <img src={noDataIcon} alt="no data icon" />
                    <InsideImageContainer src={iconZoom} alt="no data icon" />
                </div>
                <h1>{title}</h1>
                <span>{description}</span>
        </AlignItemsByColumn>
    );
};

export default NoDataScreen;

const InsideImageContainer = styled('img')`
    margin-left: -1em;
`;

const AlignItemsByColumn =styled('div')`
    display: flex;
    align-items: center;
    flex-direction:column;
    min-block-size:50em;
    justify-content:center;
`;
