import { gql } from 'apollo-boost';

export const ALL_CAMPAIGNS = gql`query campaignSummaries(
    $pagination: PaginationInput,
    $search: String,
    $filters: CampaignFilters)
{
    campaignSummaries(
        pagination: $pagination,
        search: $search,
        filters: $filters)
    {
        items {
                campaignName
                id
                countryCode
                framework
                company
                manager
                members
                status
        }
        take
        skip
        total
        count
    }
}`;

export const LIST_COUNTRIES = gql`
    query countrySummaries(
        $pagination: PaginationInput
        $search: String
        $filters: CountryFilters
    ) {
        countrySummaries(
            pagination: $pagination
            search: $search
            filters: $filters
        ) {
            items {
                id
                name
                isoCode
            }
            take
            skip
            total
            count
        }
    }
`;

export const LIST_FRAMEWORKS = gql`
    query frameworkSummaries(
        $pagination: PaginationInput,
        $search: String,
        $filters: FrameworkFilters)
    {
        frameworkSummaries(
            pagination: $pagination,
            search: $search,
            filters: $filters)
        {
            items {
                frameworkId,
                name
            }
        }
    }
`;

export const DELETE_CAMPAIGN  = gql`
    mutation deleteCampaign($id: ID!) {
        deleteCampaign(id: $id) {
            message
        }
    }
`;