import { gql } from 'apollo-boost';

export const FRAMEWORKS_LIST_MODEL = gql`
    query frameworkSummaries(
        $pagination: PaginationInput,
        $search: String,
        $filters: FrameworkFilters)
    {
        frameworkSummaries(
            pagination: $pagination,
            search: $search,
            filters: $filters)
        {
            items {
                frameworkId,
                name,
                countryOwner,
                proposition,
                benchmarkingEnabled,
                frameworkEnabled
            }
            take
            skip
            total
            count
        }
    }
`;

export const PROPOSITIONS = gql`
    query frameworkPropositions {
        frameworkPropositions
    }
`;

export const EDIT_FRAMEWORKS = gql`
mutation updateFrameworks($data: [UpdateFrameworksInput!]!) {
    updateFrameworks(data: $data)
}
`;
export const CLIENT_SUMMARIES_BYFRAMEWORK = gql`
query clientSummariesByFramework($frameworkId: Int!, $countries: [CountriesOverride!], $pagination: PaginationInput, $search: String, $filters: ClientsByFrameworkFilters) {
    clientSummariesByFramework(frameworkId: $frameworkId, countries: $countries, pagination: $pagination, search: $search, filters: $filters) {
        items {
          id
          name
          countryIsoCode
          frameworkEnabled
        }
        take
        skip
        total
        count

    }
}
`;

export const GET_SETTINGS_BYFRAMEWORK = gql`
query frameworkSettingsByFrameworkId($frameworkId: Int!) {
    frameworkSettingsByFrameworkId(frameworkId: $frameworkId) {
        id
        name,
        status
    }
}
`;

// Update one settings status only by id.
export const UPDATE_FRAMEWORK_SETTINGS_STATUS = gql`
    mutation updateFrameworkSettingsStatus(
        $id: Int!
        $status: Boolean!
    ) {
        updateFrameworkSettingsStatus(id: $id, status: $status) {
            id
            status
        }
    }
`;

export const COUNTRY_SUMMARIES_BYFRAMEWORK = gql`
query countrySummariesByFramework($frameworkId: Int!, $pagination: PaginationInput, $search: String, $filters: CountriesByFrameworkFilters) {
    countrySummariesByFramework(frameworkId: $frameworkId, pagination: $pagination, search: $search, filters: $filters) {
        items {
          id
          name
          countryIsoCode
          frameworkEnabled
        }
        take
        skip
        total
        count

    }
}
`;

export const EDIT_FRAMEWORK = gql`
mutation updateFramework($data: UpdateFrameworkInput!) {
    updateFramework(data: $data){
     id
    version
    frameworkVersionId
    frameworkEnabled
    mapping {
        sourceVersionId
        capabilities
    }
    definition {
        name
        shortName
        scores
        domains {
            name
            shortName
            capabilities {
                name
                shortName
                category
                description
                objectives {
                    title
                    name
                    reference
                    guidance
                    results
                    threatAndRiskType
                }
            }
        }
        supportsThreatAndRisk
        metadata {
            frameworkNature
            owner
            publishDate
            website
            aidUrl
        }
    }
    updatedAt
    createdAt
    }
}
`;


export const CREATE_FRAMEWORK = gql`
    mutation createFramework($data: CreateFrameworkInput!){
        createFramework(data: $data) {
            id
            version
            frameworkVersionId
            frameworkEnabled
            benchmarkingEnabled
            definition {
                name
                shortName
                scores
                domains {
                    name
                    shortName
                    capabilities {
                        name
                        shortName
                        category
                        description
                        objectives {
                            title
                            name
                            reference
                            guidance
                            results
                            threatAndRiskType
                        }
                    }
                }
                supportsThreatAndRisk
                metadata {
                    frameworkNature
                    owner
                    publishDate
                    website
                    aidUrl
                }
            }
            updatedAt
            createdAt
          }
    }
`;

export const CREATE_FRAMEWORK_VERSION = gql`
mutation createDynamicFrameworkVersion($data: CreateFrameworkVersionInput!, $majorVersion: Boolean!)
{
    createDynamicFrameworkVersion(data: $data, majorVersion: $majorVersion){
        id
        version
        frameworkVersionId
        frameworkEnabled
        mapping {
            sourceVersionId
            capabilities
        }
        definition {
            name
            shortName
            scores
            domains {
                name
                shortName
                capabilities {
                    name
                    shortName
                    category
                    description
                    objectives {
                        title
                        name
                        reference
                        guidance
                        results
                        threatAndRiskType
                    }
                }
            }
            supportsThreatAndRisk
            metadata {
                frameworkNature
                owner
                publishDate
                website
                aidUrl
            }
        }
        updatedAt
        createdAt
     }
}
`;
