import React from 'react';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { Text } from '../Text';

type Props = {
    labelText:string,
    value:string,
    onChangeFunc: Function,
    isError:boolean,
    errorMessage?: string,
    placeholderText: string,
    className?:string,
    onBlurFunc?:Function,
    disabled?: boolean,
    type?: string
}

const InputTextField: React.FC<Props> = ({
    labelText,
    value,
    onChangeFunc,
    isError,
    errorMessage,
    placeholderText,
    className,
    onBlurFunc,
    disabled,
    type = 'text'
}) => {
    return(
        <div
            className={className}
        >
            <DropdownLabel>{labelText}</DropdownLabel>
            <CustomTextField
                variant="outlined"
                inputProps={{
                    style: {
                        height: '2.375em',
                        padding: '0 10px'
                    },
                }}
                value={value}
                onBlur={(event: any) => {
                    if(onBlurFunc) onBlurFunc(event)
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeFunc(event)}
                error={isError}
                helperText={
                    <Text style={{color: 'red'}}>{errorMessage}</Text>
                }
                placeholder={placeholderText}
                disabled={disabled}
                type={type}
            />
        </div>
    )
};

export default InputTextField;

const DropdownLabel = styled(InputLabel)`
    margin-bottom: 0.5em;
    margin-left: 0.3em;
    font-size: 14px !important;
`;

const CustomTextField = styled(TextField)`
&&{
    &&.MuiTextField-root{
        width: 14em;
        margin-right: 1em;
        margin-bottom: '0px'
    }
}
`;
