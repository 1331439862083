import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router';

import NewTable from '../../components/Table/Table';
import Router from '../../routes/router';
import addIcon from '../../assets/images/add-circle.svg';
import iconSave from '../../assets/images/icon-save.svg';

import { TableRow as TableRowInter } from '../../components/Table/types';
import { CompanyListPage, CompanyListModel, CompanySummary, Country, Filters, CompanyOrder, CompanyMainProps } from './companyTypes';

import RedButton from '../../components/base/Buttons/RedButton';
import ExportButton from '../../components/base/Buttons/ExportButton';
import SearchField from '../../components/base/SearchField/SearchField';
import SuccessModal from '../../components/base/SuccessModal/SuccessModal';
import DropdownSingleSelection from '../../components/base/Dropdowns/DropdownSingleSelection';

import {
    exportCompaniesCSV,
    disabledEnabledDropdownValues,
    setTableRows,
    getFilters,
    getHeaderData,
    saveHandler
} from './companyHelpers';
import iconDownload from '../../assets/images/download-btn.svg';
import { COMPANIES_LIST_MODEL, EDIT_COMPANIES, LIST_COUNTRIES } from './companyQueries';
import * as messages from '../../utils/Constant/messages';
import { getLocalStorageItem } from '../../utils/local-storage';

const ManageCompanies: React.FC = () => {
    const params = useParams<CompanyMainProps>();
    const perpage = 50;
    const isCompanyCreated = params.isCompanyCreated ?? '';
    // dropdown values
    const { loading:loadingAllCountries, error:errorAllCountries, data:dataAllCountries } = useQuery(LIST_COUNTRIES, {
        fetchPolicy: 'network-only',
        variables: {
            pagination: {
                order: {
                    field: 'name',
                    isAsc: true
                }
            }
        }
    });
    const [allCountries, setAllCountries] = useState<Country[]>([
        {
            id: 0,
            name: '',
        }
    ]);
    const [selectedBenchmarking, setBenchmarking] = useState<string>('All');
    const [selectedStatus, setStatus] = useState<string>('All');
    const [selectedCountry, setSelectedCountry] = useState<string>('All');
    const [allSelectedBenchmarking, setAllSelectedBenchmarking] = useState<number[]>([]);
    const [allSelectedCompanies, setAllSelectedCompanies] = useState<number[]>([]);
    const [allInteractedBenchmarking, setAllInteractedBenchmarking] = useState<number[]>([]);
    const [allInteractedCompanies, setAllInteractedCompanies] = useState<number[]>([]);
    const [page, setPage] = useState<number>(0);
    const [tableRow, setRow] = useState<TableRowInter[]>();
    const [filters, setFilters] = useState<Filters>({
        countryIds: undefined,
        clientEnabled: undefined,
        benchmarkingEnabled: undefined
    });
    const [searchText, setSearchText] = useState<string>('');
    const [companiesListPage, setCompaniesListPage] = useState<CompanyListPage>();
    const [exportSpinner, setExportSpinner] = useState<boolean>(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [isModalInternal, setIsModalInternal] = useState(true);
    const [order, setOrder] = useState<CompanyOrder|undefined>({field:'name',isAsc:true});

    useEffect(() => {
        if(isCompanyCreated === 'true') {
            setOpenSuccessModal(true)
            setIsModalInternal(false)
        }
    }, [isCompanyCreated]);

    const [editCompanies] = useMutation(EDIT_COMPANIES);
    const [getCompanies, {loading:loadingAllCompanies}] = useLazyQuery<CompanyListModel>(COMPANIES_LIST_MODEL, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            setCompaniesListPage(res.clientSummaries);

            const allBenchmarking = res.clientSummaries.items
                .filter(
                    (item: CompanySummary) => item.benchmarkingEnabled && !allInteractedBenchmarking.includes(item.id)
                )
                .map((item: CompanySummary) => item.id);
            const allUniqueSelectedBenchmarkingIds = Array.from(
                new Set([...allBenchmarking, ...allSelectedBenchmarking])
            );
            setAllSelectedBenchmarking(allUniqueSelectedBenchmarkingIds);
            const allSelected = res.clientSummaries.items
                .filter((item: CompanySummary) => item.clientEnabled && !allInteractedCompanies.includes(item.id))
                .map((item: CompanySummary) => item.id);
            const allUniqueSelectedIds = Array.from(new Set([...allSelected, ...allSelectedCompanies]));
            setAllSelectedCompanies(allUniqueSelectedIds);
        },
        onError: (err) => {
            console.log(err);
        }
    });

    useEffect(() => {
        if (loadingAllCountries || errorAllCountries) {
            return;
        }

        const countriesResponse = [...dataAllCountries.countrySummaries.items];

        countriesResponse.unshift({
            id: -1,
            name: 'All'
        });
        setAllCountries(countriesResponse);
    }, [dataAllCountries, loadingAllCountries]);

    const createSortHandler = (property: string) => {
        if(order&&order.field===property)
        {
            setOrder({field:property,isAsc:!order.isAsc});
        }
        else{
            setOrder({field:property,isAsc:true});
        }
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleToggleBenchmarkingChange = (cellID: number) => {
        if (allSelectedBenchmarking.includes(cellID)) {
            const arrWithoutCell = allSelectedBenchmarking.filter((selBench: number) => selBench !== cellID);
            setAllSelectedBenchmarking(arrWithoutCell);
        } else {
            setAllSelectedBenchmarking([...allSelectedBenchmarking, cellID]);
        }

        if (!allInteractedBenchmarking.includes(cellID)) {
            setAllInteractedBenchmarking([...allInteractedBenchmarking, cellID]);
        }
    };

    const handleToggleActivateChange = (cellID:number) => {
        if (allSelectedCompanies.includes(cellID)) {
            const arrWithoutCell = allSelectedCompanies.filter((selCoun: number) => selCoun !== cellID);
            setAllSelectedCompanies(arrWithoutCell);
        } else {
            setAllSelectedCompanies([...allSelectedCompanies, cellID]);
        }

        if (!allInteractedCompanies.includes(cellID)) {
            setAllInteractedCompanies([...allInteractedCompanies, cellID]);
        }
    };

    useEffect(() => {
        //Adding "loadingAllCompanies" to useEffect casue infinite loop, need to investigate the reason for it
        // if (loadingAllCompanies) {
        //     return;
        // }

        getCompanies({
            variables: {
                filters,
                search: searchText,
                pagination: {
                    order,
                    skip: page * perpage,
                    take: perpage
                }
            }
        });
    }, [page, filters, searchText, order]);

    useEffect(() => {
        if (!companiesListPage) {
            return;
        }
        const rows = setTableRows(
            companiesListPage,
            handleToggleBenchmarkingChange,
            handleToggleActivateChange,
            allSelectedBenchmarking,
            allSelectedCompanies
        );

        const companyDeletionValue = getLocalStorageItem('company-deletion');
        const companiesToBeDeleted = (companyDeletionValue !== undefined && companyDeletionValue !== '') ? JSON.parse(companyDeletionValue) : [];

        if (companiesToBeDeleted && companiesToBeDeleted.length) {
            const isExistInList = companiesToBeDeleted.filter((cd: number) => {
                return companiesListPage.items.map(c => c.id, []).includes(cd);
            });

            if (!isExistInList.length) {
                localStorage.removeItem('company-deletion');
            }
        }

        rows.map(r => {
            return r.tableCells.map(tc => {
                if ((tc.cellType === 'action' || tc.cellType === 'boolean') && companiesToBeDeleted.includes(tc.cellID)) {
                    tc.disabled = true;
                }
                return tc;
            });
        });

        setRow(rows);
    }, [companiesListPage, allSelectedCompanies, allSelectedBenchmarking, openSuccessModal]);

    useEffect(() => {
        getFilters(selectedCountry, allCountries, selectedStatus, selectedBenchmarking, setPage, setFilters);
    }, [selectedStatus, selectedBenchmarking, selectedCountry]);

    const headerData = getHeaderData(createSortHandler);

    const tablePagination = {
        rowsPerPage: perpage,
        currentPage: page,
        handleChangePage,
        totalCount: companiesListPage && companiesListPage.total
    };

    const companySearchCallback = (value: string) => {
        setSearchText(value);
        setPage(0);
    };

    const saveChanges = () => {
        saveHandler(
            allInteractedBenchmarking,
            allSelectedBenchmarking,
            allInteractedCompanies,
            allSelectedCompanies,
            editCompanies,
            setOpenSuccessModal,
            setAllInteractedBenchmarking,
            setAllInteractedCompanies,
            setIsModalInternal,
            companiesListPage
        );
    };

    return (
        <>
            <MainWrapper>
                <LinkWrapper />
                <NameAndCTAs>
                    <h1>Companies</h1>
                    <SaveButton
                        disabled={allInteractedBenchmarking.length === 0 && allInteractedCompanies.length === 0}
                        onClick={saveChanges}
                    >
                        <img src={iconSave} alt="add company icon" />
                        Save changes
                    </SaveButton>
                </NameAndCTAs>
                <FiltersAddAndSave>
                    <FiltersAndAdd>
                        <DropdownSingleSelection
                            labelText="Country"
                            isDisabled={false}
                            selectedValue={selectedCountry}
                            setValue={setSelectedCountry}
                            allValues={allCountries}
                            width="10em"
                        />
                        <DropdownSingleSelection
                            labelText="Benchmarking"
                            isDisabled={false}
                            selectedValue={selectedBenchmarking}
                            setValue={setBenchmarking}
                            allValues={disabledEnabledDropdownValues}
                            width="10em"
                        />
                        <DropdownSingleSelection
                            labelText="Status"
                            isDisabled={false}
                            selectedValue={selectedStatus}
                            setValue={setStatus}
                            allValues={disabledEnabledDropdownValues}
                            width="10em"
                        />
                        <AddButton disabled={false} onClick={Router.goToCompanyAdd}>
                            <img src={addIcon} alt="add company icon" />
                            Add company
                        </AddButton>
                    </FiltersAndAdd>
                    <SearchAndSave>
                        {exportSpinner ? (
                            <CircularProgress />
                        ) : (
                            <ExportButton onClick={() => exportCompaniesCSV(setExportSpinner, filters)}>
                                <img src={iconDownload} alt="download" />
                            </ExportButton>
                        )}
                        <SearchField placeholder="Search" callback={(value: string) => companySearchCallback(value)} />
                    </SearchAndSave>
                </FiltersAddAndSave>
                <TableOrSplash>
                    {loadingAllCompanies ? (
                        <CircularProgress />
                    ) : (
                        <NewTable header={headerData} tablePagination={tablePagination} rows={tableRow} />
                    )}
                </TableOrSplash>
            </MainWrapper>
            <SuccessModal
                openState={openSuccessModal}
                hideDuration={6000}
                setOpen={setOpenSuccessModal}
                message={isModalInternal ? messages.COMPANIES_SUCCESS_MESSAGE : messages.COMPANY_SUCCESS_MESSAGE}
            />
        </>
    );
};

const SaveButton = styled(RedButton)`
    margin-left: 10px !important;
    width: 180px;
    font-size: 16px !important;
    img {
        padding-right: 3px;
    }
    height: 50%;
    margin-top: 0.67em !important;
`;

const MainWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

const NameAndCTAs = styled('div')`
    display: flex;
    justify-content: space-between;
    min-height: 100px;
    padding: 5px 0;

    h2 {
        margin: 0;
    }
`;
const FiltersAddAndSave = styled('div')`
    display: flex;
    justify-content: space-between;
`;

const FiltersAndAdd = styled('div')`
    display: flex;
`;

const SearchAndSave = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

const TableOrSplash = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const AddButton = styled(RedButton)`
    width: 10em;
    height: 2.7em;
    align-self: flex-end;
    color: white !important;
    img {
        margin-right: 0.5em;
    }
    padding: 6px !important;
`;

const LinkWrapper = styled('div')`
    height: 46px;
`;

export default ManageCompanies;
