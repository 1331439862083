import React, { useState, useContext, useEffect, useRef } from 'react'
import { ConfigRoutes as RoutesEnum } from './routes.enum';
import AuditTrails from '../modules/AuditTrails/AuditTrails';
import { useAuth } from 'react-oidc-context';
import ManageCountries from '../modules/ManageCountries';
import CountryDetails from '../modules/ManageCountries/CountryDetails/CountryDetails';
import AddEditCountry from '../modules/ManageCountries/AddEditCountry/AddEditCountry';
import ManageCompanies from '../modules/ManageCompanies/ManageCompanies';
import CompanyDetails from '../modules/ManageCompanies/CompanyDetails/CompanyDetails';
import AddEditCompany from '../modules/ManageCompanies/AddEditCompany/AddEditCompany';
import ManageUsers from '../modules/ManageUsers/ManageUsers';
import UserDetails from '../modules/ManageUsers/UserDetails/UserDetails';
import AddEditExternalUser from '../modules/ManageUsers/AddEditUser/AddEditExternalUser';
import AddEditInternalUser from '../modules/ManageUsers/AddEditUser/AddEditInternalUser';
import ManageFrameworks from '../modules/ManageFrameworks/ManageFrameworks';
import FrameworkDetails from '../modules/ManageFrameworks/FrameworkDetails/FrameworkDetails';
import ManageCampaigns from '../modules/ManageCampaigns/ManageCampaigns';
import ManageSettings from '../modules/ManageSettings/ManageSettings';
import Reports from '../modules/Reports';
import NotificationHub from '../modules/NotificationHub';
import PrivateRoute from '../secure/PrivateRoute';
import { useLazyQuery } from '@apollo/client';
import { Route, Routes } from 'react-router';
import { User, Client, GlobalSettings } from '../context/reducer';
import { UserStore } from '../context/user-store';
import { USER_DATA, GLOBAL_SETTINGS } from './RouteAuthQueries';
import RouteType from './route.types';
import AuthPage from '../modules/AuthPage/AuthPage';
import LoginCallback from '../modules/AuthPage/LoginCallback';
import { getLocalStorageItem, setLocalStorageItem } from '../utils/local-storage';
import Main from '../modules/Main';
import { addJwtToWhitelist } from '../services/ApolloClient/client';
import Router from './router';
import FrameworkUpload from '../modules/ManageFrameworks/FrameworkUpload';

const RouteConfig: React.FC = () => {

    const auth = useAuth();
    if(auth?.user?.expires_in && auth.user.expires_in < 0){
        sessionStorage.clear();
        localStorage.clear();
        auth.signoutSilent();
        auth.removeUser();
    }

    const { state: { user } } = useContext(UserStore);
    const { dispatch } = useContext(UserStore);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false);
    const setCurrentUser = (user: User) =>
        dispatch({ type: 'SET_USER', value: user });
    const setCurrentClient = (client: Client) =>
        dispatch({ type: 'SET_CLIENT', value: client });
    const setCurrentGlobalSettings = (globalSettings: GlobalSettings[]) =>
        dispatch({ type: 'SET_GLOBALSETTINGS', value: globalSettings });

    const refreshTokenTime = window.__ENV__.MIN_TIME_REFRESH_TOKEN ? Number(window.__ENV__.MIN_TIME_REFRESH_TOKEN) : 600000;

    function useInterval(callback: () => void, delay: number) {
        const savedCallback = useRef(callback);

        // Remember the latest callback.
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        // Set up the interval.
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    }

    useInterval(() => {
        if(auth?.user?.expires_in && auth.user.expires_in < 60){
            sessionStorage.clear();
            localStorage.clear();
            auth.signoutSilent();
            auth.removeUser();
        }
    }, 60000);

    const whitelistJwt = async (jwtToken?: string, accessToken?: string) => {
        setLocalStorageItem('pid-token', jwtToken);
        setLocalStorageItem('pid-access-token', accessToken);
        await addJwtToWhitelist(jwtToken, accessToken)
        .then(() => {
            getGlobalSettings();
            getUser();
        }).catch((error: Error) => {
            if(error.message === 'User not found'){
                alert(
                    `You're not authorised to view this page. Please contact an administrator for access`
                );
            }
            auth.signoutSilent();
            auth.removeUser();
            localStorage.clear();
            sessionStorage.clear();
            Router.goToLogoutPage();
        });
    };
    const [getUser, { data }] = useLazyQuery(USER_DATA, {
        onError: (error: Error) => {
            if(error.message === 'User is disabled'){
                alert(
                    `You're not authorised to view this page. Please contact an administrator for access`
                );
                auth.signoutSilent();
                auth.removeUser();
                localStorage.clear();
                sessionStorage.clear();
                Router.goToLogoutPage();
            }
        }
    });


    const [getGlobalSettings] = useLazyQuery<{ globalSettings: GlobalSettings[] }>(GLOBAL_SETTINGS, {
        onCompleted: data => {
            if (data && data.globalSettings) {
                setCurrentGlobalSettings(data.globalSettings);
            }
        }
    });

    useEffect(() => {
        if (data && data.me && data.me.clients) {
            setIsUserLoggedIn(true);
            setCurrentUser(data && data.me);
            setCurrentClient(
                data && data.me && data.me.clients && data.me.clients[0]
            );
        } else {
            if(data){
                setIsUserLoggedIn(true);
                setCurrentUser(user);
                if (!!user && !!user.clients) setCurrentClient(
                    user.clients[0]
                );
            }
        }
    }, [data]);

    useEffect(() => {
        const token = getLocalStorageItem('pid-token');
        if(
            (!auth.isLoading && auth.isAuthenticated && auth?.user?.id_token && auth?.user?.access_token && !user.role && !token)
        ){
            whitelistJwt(auth?.user?.id_token, auth?.user?.access_token);
            setIsUserLoggedIn(true);
        }
        if(!auth.isLoading && auth.isAuthenticated && auth?.user?.id_token && !user.role && token){
            if(token){
                getUser();
                getGlobalSettings();
                setIsUserLoggedIn(true);
            }
        }
    }, [auth]);

    useEffect(() => {
        const interval = setInterval(() => {
            if(auth.isAuthenticated){
                auth.signinSilent();
            }
        }, refreshTokenTime);

        return () => clearInterval(interval);
        }, [auth.isAuthenticated, refreshTokenTime]);

    useEffect(() => {
            setLocalStorageItem('pid-token', auth.user?.id_token);
            whitelistJwt(auth?.user?.id_token, auth?.user?.access_token);
        }, [auth.user?.id_token]);

    const getRoutes = (route: RouteType[]) => {
        let person;
        if (!!data && !!data.me && !!data.me.clients) {
            person = data.me;
        } else {
            person = user;
        }

        return person.role === 'STANDARD' &&
            person.countryAdminCountries.length > 0
            ? route &&
            route.filter(
                item =>
                    !(
                        item.path.includes('countries') ||
                        item.path.includes('frameworks') ||
                        item.path.includes('notification-hub') ||
                        item.path.includes('manage-settings')
                    )
            )
            : route;
    };

    const subroutes = {
        [RoutesEnum.main]: [
            {
                path: RoutesEnum.framework,
                element: <PrivateRoute children={<FrameworkDetails />}/>,
            },
            {
                path: RoutesEnum.manageFrameworks,
                element: <PrivateRoute children={<ManageFrameworks />}/>,
            },
            {
                path: RoutesEnum.uploadFrameworks,
                element: <PrivateRoute children={<FrameworkUpload />}/>,
            },
            {
                path: RoutesEnum.addEditExternalUser,
                element: <PrivateRoute children={<AddEditExternalUser />}/>,
            },
            {
                path: RoutesEnum.addEditInternalUser,
                element: <PrivateRoute children={<AddEditInternalUser />}/>,
            },
            {
                path: RoutesEnum.user,
                element: <PrivateRoute children={<UserDetails />}/>,
            },
            {
                path: RoutesEnum.manageUsers,
                element: <PrivateRoute children={<ManageUsers />}/>,
            },
            {
                path: RoutesEnum.auditTrails,
                element: <PrivateRoute children={<AuditTrails />}/>,
            },
            {
                path: RoutesEnum.addCountries,
                element: <PrivateRoute children={<AddEditCountry />}/>,
            },
            {
                path: RoutesEnum.editCountries,
                element: <PrivateRoute children={<AddEditCountry />}/>,
            },
            {
                path: RoutesEnum.country,
                element: <PrivateRoute children={<CountryDetails />}/>,
            },
            {
                path: RoutesEnum.manageCountries,
                element: <PrivateRoute children={<ManageCountries />}/>,
            },
            {
                path: RoutesEnum.addCompanies,
                element: <PrivateRoute children={<AddEditCompany />}/>,
            },
            {
                path: RoutesEnum.editCompanies,
                element: <PrivateRoute children={<AddEditCompany />}/>,
            },
            {
                path: RoutesEnum.company,
                element: <PrivateRoute children={<CompanyDetails />}/>,
            },
            {
                path: RoutesEnum.manageCompanies,
                element: <PrivateRoute children={<ManageCompanies />}/>,
            },
            {
                path: RoutesEnum.manageCampaigns,
                element: <PrivateRoute children={<ManageCampaigns />}/>,
            },
            {
                path: RoutesEnum.manageSettings,
                element: <PrivateRoute children={<ManageSettings />}/>,
            },
            {
                path: RoutesEnum.notificationHub,
                element: <PrivateRoute children={<NotificationHub />}/>,
            },
            {
                path: RoutesEnum.Reports,
                element: <PrivateRoute children={<Reports />}/>,
            },
        ]
    };
    return (
        <>
         <Routes>
            <Route path={RoutesEnum.splash} element={<AuthPage/>}/>
            <Route path={RoutesEnum.loginRedirect} element={<LoginCallback/>}/>
            <Route path={RoutesEnum.logoutRedirect} element={<AuthPage/>}/>
        { !isUserLoggedIn? <Route path={RoutesEnum.loggedOutRoutes} element={<AuthPage/>}/>:

            <Route path={RoutesEnum.main} element={<PrivateRoute children={<Main/>}></PrivateRoute>}>
            {
                getRoutes(subroutes[RoutesEnum.main]).map((route: RouteType, index) => {
                    return(<Route
                        path={route.path}
                        element={route.element}
                        key={index}/>)
                    })
            }
            </Route>
        }
        </Routes>

        </>
    );
};

export default RouteConfig;


