import { styled } from '@mui/material/styles';
import CustomButton from './Button';

const TransparentButton = styled(CustomButton)<{
    disabled?: boolean
}>`
    && {
        background-color: transparent;
        cursor: ${props => props.disabled ? 'auto' : 'pointer'}
        border: 1.5px solid ${props => props.disabled ? '#D9D9D9' : '#575757'};
        box-sizing: border-box;
        border-radius: 2px;
        color: ${props => props.disabled ? '#D9D9D9' : '#575757'};

        &:hover {
            color: ${props => props.disabled ? '#D9D9D9' : '#DE3328'};
            border: 1.5px solid ${props => props.disabled ? '#D9D9D9' : '#DE3328'};
        }
    }
`;

export default TransparentButton;