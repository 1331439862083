import React, { useState, useEffect, useContext } from 'react';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';

import arrowIcon from '../../../assets/images/icon-indicator.svg';
import pwcIcon from '../../../assets/images/pwc-logo.svg';

import Router from '../../../routes/router';
import {
    isNotificationsEnabled,
    isPowerBiReportsEnable,
} from '../../../utils/global-settings';

import { removeJwtFromWhitelist } from '../../../services/ApolloClient/client';
import { UserStore } from '../../../context/user-store';

import TruncatedText from '../../../components/base/Text/TruncatedText';

import {
    allMenuOptions,
    menuOptionsToHide,
    convertUserRole,
    disableReports,
    disableNotificationHub,
} from './MenuHelpers';
import { useAuth } from 'react-oidc-context';

type MenuOption = {
    callback: Function;
    name: string;
    imgSrc: string | undefined;
    imgAlt: string;
};

const Header: React.FC = () => {
    const auth = useAuth();
    const {
        state: { user, globalSettings },
    } = useContext(UserStore);
    const [PowerBiReportsEnable, setPowerBiReportsEnable] = useState<boolean>();
    const [selected, setSelected] = useState('');
    const [menuToShow, setMenuToShow] = useState<MenuOption[]>([]);

    const route = Router.getCurrentPath();
    useEffect(() => {
        if (route.includes('/main/manage-companies')) {
            setSelected('Companies');
        } else if (route.includes('/main/manage-users')) {
            setSelected('Users');
        } else if (route.includes('/main/manage-campaigns')) {
            setSelected('Campaigns');
        } else if (route.includes('/main/manage-frameworks')) {
            setSelected('Frameworks');
        } else if (route.includes('/main/manage-settings')) {
            setSelected('Settings');
        } else if (route.includes('/main/manage-countries')) {
            setSelected('Countries');
        } else if (route.includes('/main/notification-hub')) {
            setSelected('Notification Hub');
        } else {
            setSelected('Reports');
        }
    }, [route]);

    useEffect(() => {
        let menuOptions = allMenuOptions;

        if (isPowerBiReportsEnable(globalSettings) === false) {
            menuOptions = allMenuOptions.filter((option) => {
                return !disableReports.includes(option.name);
            });
            setMenuToShow(menuOptions);
        }

        if (isNotificationsEnabled(globalSettings) === false) {
            menuOptions = allMenuOptions.filter((option) => {
                return !disableNotificationHub.includes(option.name);
            });
            setMenuToShow(menuOptions);
        }

        if (user.role !== 'ADMIN') {
            const menuFiltered = menuOptions.filter((option) => {
                return !menuOptionsToHide.includes(option.name);
            });
            setMenuToShow(menuFiltered);
        } else {
            setMenuToShow(menuOptions);
        }
    }, [user]);

    return (
        <HeaderWrapper>
            <div>
                <HeaderTitle>
                    {' '}
                    <img src={pwcIcon} alt="icon of PWC logo" />
                </HeaderTitle>
                <UserWrapper>
                    <StyledAvatar>
                        {user && user.firstName && user.firstName[0]}
                        {user && user.lastName && user.lastName[0]}
                    </StyledAvatar>
                    <div>
                        <AdminName>
                            {user.firstName} {user.lastName}
                        </AdminName>
                        <UserRole>{convertUserRole(user.role)}</UserRole>
                    </div>
                </UserWrapper>
                <MenuOptionsWrapper>
                    {menuToShow.map((option: MenuOption, index: number) => (
                        <StyledMenuOption
                            isSelected={selected === option.name}
                            key={index}
                            onClick={() => {
                                setSelected(option.name);
                                option.callback();
                            }}
                        >
                            <MenuIconAndName
                                isSelected={selected === option.name}
                            >
                                <img src={option.imgSrc} alt={option.imgAlt} />
                                {option.name}
                            </MenuIconAndName>
                            <img
                                src={arrowIcon}
                                alt="arrow icon"
                                style={{
                                    visibility:
                                        option.name === selected
                                            ? 'visible'
                                            : 'hidden',
                                    filter: 'invert(100%) sepia(0%) saturate(2%) hue-rotate(311deg) brightness(106%) contrast(101%)',
                                }}
                            />
                        </StyledMenuOption>
                    ))}
                </MenuOptionsWrapper>
            </div>
            <LogOutButton
                onClick={async () => {
                    auth.signoutSilent();
                    auth.removeUser();
                    await removeJwtFromWhitelist();
                    Router.goToLogoutPage();
                }}
            >
                Log out
            </LogOutButton>
        </HeaderWrapper>
    );
};
const StyledAvatar = styled(Avatar)`
    background-color: #de3328;
    padding-right: 1%;
    margin-right: 12px;
    font-size: 1em;
`;

const HeaderWrapper = styled('div')`
    width: 17%;
    flex: 1 1 17%;
    background-color: #2d2d2d;
    height: 100vh;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 999;
`;

const HeaderTitle = styled('div')`
    background-color: #232323;
    padding: 16px 0 16px 10%;
    font-size: 14px;
`;

const UserWrapper = styled('div')`
    display: flex;
    padding: 5px;
    padding-left: 10%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
    min-height: 100px;
    align-items: center;
`;

const MenuOptionsWrapper = styled('div')`
    padding: 5% 0 3% 0;
`;

const MenuOption = styled('div')`
    padding-left: 10%;
    min-height: 3.125em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    img {
        width: 24px;
    }
`;

const StyledMenuOption = styled('div')<{ isSelected: boolean }>`
    padding-left: 10%;
    min-height: 3.125em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: ${(props) => (props.isSelected ? '#5B5B5B' : 'initial')};
    border-left: ${(props) => (props.isSelected ? '3px solid white' : 'none')};
    padding-left: ${(props) => (props.isSelected ? 'calc(10% - 3px)' : '10%')};
`;

const MenuIconAndName = styled('div')<{
    isSelected: boolean;
}>`
    display: flex;
    align-items: center;
    color: ${(props) => (props.isSelected ? '#FFFFFF' : '#828282')};

    img {
        padding-right: 10px;
        width: 24px;
        height: auto;
        filter: ${(props) =>
            props.isSelected
                ? 'invert(100%) sepia(0%) saturate(2%) hue-rotate(311deg) brightness(106%) contrast(101%)'
                : 'invert(61%) sepia(5%) saturate(5%) hue-rotate(14deg) brightness(83%) contrast(94%)'};
    }
`;

const UserRole = styled('p')`
    font-size: 16px;
    margin: unset;
`;

const LogOutButton = styled('div')`
    border: 1px solid white;
    margin: 0 10% 20%;
    height: 48px;
    line-height: 48px;
    text-align: center;
    cursor: pointer;
`;

const AdminName = styled(TruncatedText)`
    color: white;
    width: 8.5em;
    font-size: 18px;
    margin: unset;
    font-weight: bold;
`;

export default Header;
