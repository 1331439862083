import {
    Country,
    FindByName,
    Framework,
    FrameworkSummariesByCompany,
    SortHandler,
    UserListPage,
    UserSummaries
} from '../companyTypes';
import {
    ChangeToggle
} from '../../../components/base/Toggle/types';
import { SortDirection, TableCellTypes } from '../../../components/Table/types';
import Router from '../../../routes/router';
import { AxiosError } from 'axios';
import { DropdownValueType } from '../../../components/base/Dropdowns/types';

import {convertPermissionLabels} from '../../../utils/helpers';
import { MutationFunction } from '@apollo/client';

export const TabOptions = {
    FRAMEWORKS: 'Frameworks',
    USERS: 'Users'
};

export const tabLabels = [TabOptions.FRAMEWORKS, TabOptions.USERS];

export const benchmarkingToggleLabels = {
    trueLabel: 'ON',
    falseLabel: 'OFF'
};

export const statusToggleLabels = {
    trueLabel: 'Enabled',
    falseLabel: 'Disabled'
};

export const setFrameworkTableRows = (
    allFrameworksResponse: FrameworkSummariesByCompany,
    allSelectedFrameworks: number[],
    handleFrameworksToggleChange: ChangeToggle
) => {
    return (
        allFrameworksResponse &&
        allFrameworksResponse.items &&
        allFrameworksResponse.items.map((framework: Framework) => {
            return {
                tableCells: [
                    {
                        cellName: framework.name,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: framework.countryOwner,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: framework.proposition,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        isCellSortable: false,
                        cellType: 'boolean' as TableCellTypes,
                        toggleLabels: {
                            trueLabel: 'Enabled',
                            falseLabel: 'Disabled'
                        },
                        currentState: allSelectedFrameworks.includes(
                            framework.frameworkId
                        ),
                        actionFunc: handleFrameworksToggleChange,
                        cellID: framework.frameworkId
                    }
                ]
            };
        })
    );
};

export const getHeaderFrameworksData = ({
    createSortHandler,
    isEdit = false
}: {
    createSortHandler: SortHandler;
    isEdit?: boolean;
}) => [
    {
        headerName: 'Framework name',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: () =>
            isEdit
                ? createSortHandler('frameworkName')
                : createSortHandler('name')
    },
    {
        headerName: 'Country owner',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: () => createSortHandler('countryOwner')
    },
    {
        headerName: 'Proposition',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: () => createSortHandler('proposition')
    },
    {
        headerName: 'Framework access',
        isHeaderSortable: false
    }
];

export const getUsersTableHeader = (createSortHandler: SortHandler) => [
    {
        headerName: 'User name',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: () => createSortHandler('name')
    },
    {
        headerName: 'Email address',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: () => createSortHandler('email')
    },
    {
        headerName: 'Permission',
        isHeaderSortable: false
    },
    {
        headerName: 'User access',
        isHeaderSortable: false
    }
];

export const setUsersTableRows = (
    allUsersByCompany: UserListPage,
    allSelectedUsers: number[],
    handleUsersEnabledToggleChange: ChangeToggle
) => {
    return (
        allUsersByCompany &&
        allUsersByCompany.items &&
        allUsersByCompany.items.map((user: UserSummaries) => {
            return {
                tableCells: [
                    {
                        cellName: user.name,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: user.email,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: convertPermissionLabels(user.displayRole),
                        isCellSortable: false,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        isCellSortable: false,
                        cellType: 'boolean' as TableCellTypes,
                        toggleLabels: {
                            trueLabel: 'Enabled',
                            falseLabel: 'Disabled'
                        },
                        currentState: allSelectedUsers.includes(user.id),
                        actionFunc: handleUsersEnabledToggleChange,
                        cellID: user.id,
                        hideCell: user.role === 'EXTERNAL'
                    }
                ]
            };
        })
    );
};

export const findByName = (name: string, data: FindByName[]) => {
    const obj = data.find(item => item.name === name);

    return obj && obj.id;
};

export const findById = (id: number, data: FindByName[]) => {
    const obj = data.find(item => item.id === id);

    return obj ? obj.name : '';
};

export const createTempFrameworksFomat = (
    allInteractedFrameworks: number[],
    allSelectedFrameworks: number[]
) => {
    return allInteractedFrameworks.map((frId: number) => {
        return {
            frameworkId: frId,
            frameworkEnabled: allSelectedFrameworks.includes(frId)
        };
    });
};

export const createTempUsersFomat = (
    allInteractedUsers: number[],
    allSelectedUsers: number[]
) => {
    return allInteractedUsers.map((row: number) => {
        return {
            id: row,
            userEnabled: allSelectedUsers.includes(row)
        };
    });
};

export const convertUsersToDropdownType = (
    allDropdownUsers: UserSummaries[]
) => {
    return allDropdownUsers.map((user: UserSummaries) => {
        return {
            id: user.id,
            name: user.name
        };
    });
};

export const actionHandler = (
    resultAction: MutationFunction,
    companyName: string,
    cesId: string,
    selectedCRECountry: string,
    allCountries: Country[],
    allHomeCountries: Country[],
    bencmarkingStatus: boolean,
    companyEnabled: boolean,
    selectedIndustry: string,
    allIndustries: DropdownValueType[],
    selectedRevenue: string,
    allRevenues: DropdownValueType[],
    selectedRelationshipManager: string,
    allDropdownUsers: UserSummaries[],
    selectedNoEmployee: string,
    allNoEmployees: DropdownValueType[],
    selectedCompanyType: string,
    allCompanyTypes: DropdownValueType[],
    allInteractedFrameworks: number[],
    allSelectedFrameworks: number[],
    allInteractedUsers: number[],
    allSelectedUsers: number[],
    selectedHomeCountry: string,
    selectedGCPRegion: string,
    allGCPRegions: DropdownValueType[],
    companyId?: number,
    isEdit?: boolean
) => {
    const addOptions = {
        variables: {
            data: {
                name: companyName,
                prId: cesId,
                countryId: findByName(selectedCRECountry, allCountries),
                benchmarkingEnabled: bencmarkingStatus,
                clientEnabled: companyEnabled,
                industryId: findByName(selectedIndustry, allIndustries),
                annualRevenueId: findByName(selectedRevenue, allRevenues),
                ownerId: findByName(selectedRelationshipManager, allDropdownUsers),
                employeesNumberId: findByName(selectedNoEmployee, allNoEmployees),
                clientTypeId: findByName(selectedCompanyType, allCompanyTypes),
                gcpRegionId: findByName(selectedGCPRegion, allGCPRegions),
                config: {},
                frameworks: createTempFrameworksFomat(
                    allInteractedFrameworks,
                    allSelectedFrameworks
                ),
                users: createTempUsersFomat(
                    allInteractedUsers,
                    allSelectedUsers
                ),
                homeCountry: selectedHomeCountry
            }
        }
    };

    const editOptions = {
        variables: {
            id: companyId,
            data: {
                name: companyName,
                prId: cesId,
                benchmarkingEnabled: bencmarkingStatus,
                clientEnabled: companyEnabled,
                industryId: findByName(selectedIndustry, allIndustries),
                annualRevenueId: findByName(selectedRevenue, allRevenues),
                ownerId: findByName(selectedRelationshipManager, allDropdownUsers),
                employeesNumberId: findByName(selectedNoEmployee, allNoEmployees),
                clientTypeId: findByName(selectedCompanyType, allCompanyTypes),
                config: {},
                frameworks: createTempFrameworksFomat(
                    allInteractedFrameworks,
                    allSelectedFrameworks
                ),
                users: createTempUsersFomat(
                    allInteractedUsers,
                    allSelectedUsers
                ),
                homeCountry: selectedHomeCountry
            }
        }
    };

    const options = isEdit ? editOptions : addOptions;

    resultAction(options)
        .then(() => {
            Router.goToCompaniesManagement('true');
        })
        .catch((err: AxiosError) => {
            console.log(err);
        });
};


