import React from 'react';
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';

import crossCircleIcon from '../../../assets/images/cross-circle.svg';
import { ModalProps } from './types';


const FailureModal : React.FC<ModalProps> = ({openState, hideDuration, setOpen, message}) => {

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <MessageWrapper
            open={openState}
            autoHideDuration={hideDuration}
            onClose={handleClose}
        >
            <DivWrapper>
                <IconWrapper>
                    <img src={crossCircleIcon} alt='cross-circle-icon'/>
                </IconWrapper>
                <TextWrapper>
                    {message}
                </TextWrapper>
            </DivWrapper>
        </MessageWrapper>
    )
}

const DivWrapper = styled('div')`
    display: flex;
`;

const MessageWrapper = styled(Snackbar)`
    width: auto;
    height: 50px;
    left: 20% !important;
    transform: none !important;
`;

const IconWrapper = styled('div')`
    width: 10%;
    background: #DE3328;
    height: 100%;
    padding: 10px 20px 9px 20px ;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const TextWrapper = styled('div')`
    background: #40403E;
    width: 370px;
    height: 100%;
    padding: 10px;
    color: white;
`;

export default FailureModal;
