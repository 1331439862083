import React, { useState, useEffect, memo } from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

import removeIcon from '../../../assets/images/mdi-remove-circle.svg';
import { InternalComponentTypes } from '../userTypes';

import {DropdownSingleSelection, InputTextField, Toggle} from '../../../components';
import {internalUserPermissionsValues} from './AddEditUsersHelper';
import * as messages from '../../../utils/Constant/messages';

const InternalUserDetails: React.FC<InternalComponentTypes> = ({
    allCountries,
    userIndex,
    deleteForm,
    internalFormDetails,
    onChangeString,
    onChangeBoolean,
    isRefresh,
    isLast,
    setIsRefresh,
    allJobGrades,
    isError,
    setIsError,
    extraError,
    setExtraError,
    isEdit,
    adminRole
}) => {

    const [userName, setUserName] = useState<string>(internalFormDetails.userName);
    const [userSurname, setUserSurname] = useState<string>(internalFormDetails.userSurname);
    const [userEnabled, setUserEnabled] = useState<boolean>(true);
    const [selectedJobGrade, setSelectedJobGrade] = useState<string>('');
    const [selectedEmail, setSelectedEmail] = useState<string>('');
    const [selectedPersmission, setSelectedPersmission] = useState<string>('');
    const [selectedPrimaryCountry, setSelectedPrimaryCountry] = useState<string>('');

    const [userNameError, setUserNameError] = useState<string>('');
    const [userSurnameError, setUserSurnameError] = useState<string>('');
    const [selectedJobGradeError, setSelectedJobGradeError] = useState<string>('');
    const [selectedEmailError, setSelectedEmailError] = useState<string>('');
    const [selectedPersmissionError, setSelectedPersmissionError] = useState<string>('');
    const [selectedPrimaryCountryError, setSelectedPrimaryCountryError] = useState<string>('');
    
    const statusToggleLabels = {
        trueLabel: 'Enabled',
        falseLabel: 'Disabled'
    };

    useEffect(() => {
        setSelectedJobGradeError('');
        onChangeString(selectedJobGrade, 'selectedJobGrade', userIndex);
    },[selectedJobGrade]);    

    useEffect(() => {
        setSelectedPersmissionError('');
        onChangeString(selectedPersmission, 'selectedPersmission', userIndex);
    },[selectedPersmission]);

    useEffect(() => {
        setSelectedPrimaryCountryError('');
        onChangeString(selectedPrimaryCountry, 'selectedPrimaryCountry', userIndex);
    },[selectedPrimaryCountry]);

    useEffect(() => {
        onChangeBoolean(userEnabled, 'userStatus', userIndex);
    },[userEnabled]);

    useEffect(() => {
        if(isRefresh){
            setUserName(internalFormDetails.userName);
            setUserSurname(internalFormDetails.userSurname);
            setUserEnabled(internalFormDetails.userStatus);
            setSelectedJobGrade(internalFormDetails.selectedJobGrade);
            setSelectedEmail(internalFormDetails.selectedEmail);
            setSelectedPersmission(internalFormDetails.selectedPersmission);
            setSelectedPrimaryCountry(internalFormDetails.selectedPrimaryCountry);
            if(extraError && extraError.id === selectedEmail){
                setSelectedEmailError(extraError.message);
            }
        } 
        if(isLast){
            setExtraError();
            setIsRefresh(false);
        }
    }, [isRefresh]);

    useEffect(() => {
        if(isError){
            if(!userName){
                setUserNameError('Required');
            }
            if(!userSurname){
                setUserSurnameError('Required');
            }
            if(!selectedJobGrade){
                setSelectedJobGradeError('Required');
            }
            if(!/^[A-Z0-9._%+-]+@pwc+\.com$/i.test(
                   selectedEmail
            )){
                setSelectedEmailError(messages.ERROR_USERS_INTERNAL_EMAIL_WRONG_DOMAIN);
            }
            if(!selectedEmail){
                setSelectedEmailError('Required');
            }
            if(!selectedPersmission){
                setSelectedPersmissionError('Required');
            }
            if(!selectedPrimaryCountry){
                setSelectedPrimaryCountryError('Required');
            }
        } 
        if(isLast){
            setIsError(false);
        }
    }, [isError]);

  
    return(
        <>
            <TitleAndToggles 
                index={userIndex}
            >
                <TogglesWrapper>
                    { userIndex > 0 && (
                        <RemoveButton
                            onClick={() => deleteForm(userIndex)}
                            >
                            <img src={removeIcon} />
                            Remove user entry
                        </RemoveButton>
                    )}
                    Status
                    <StatusToogle
                        labels={statusToggleLabels}
                        state={userEnabled}
                        actionFunc={() => {
                            setUserEnabled(!userEnabled)
                        }}
                        cellID="Status-toogle"
                    />
                </TogglesWrapper>
            </TitleAndToggles>
            <UserValues>
                <DetailText
                    labelText="First name"
                    value={userName}
                    isError={userNameError.length > 0}
                    errorMessage={userNameError}
                    onBlurFunc={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setUserNameError('');
                        onChangeString(e.target.value, 'userName', userIndex);
                    }}
                    onChangeFunc={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setUserName(e.target.value)
                    }
                    placeholderText="Add first name"
                    //disabled={typeof isEdit === 'boolean' && isEdit}
                />
                <DetailText
                    labelText="Last name"
                    value={userSurname}
                    isError={userSurnameError.length > 0}
                    errorMessage={userNameError}
                    onBlurFunc={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setUserSurnameError('');
                        onChangeString(
                            e.target.value,
                            'userSurname',
                            userIndex
                        );
                    }}
                    onChangeFunc={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setUserSurname(e.target.value)
                    }
                    placeholderText="Add last name"
                    //disabled={typeof isEdit === 'boolean' && isEdit}
                />
                <Detail
                    labelText="Job grade"
                    isDisabled={false}
                    selectedValue={selectedJobGrade}
                    setValue={setSelectedJobGrade}
                    placeholder="Add job grade"
                    allValues={allJobGrades}
                    width="14em"
                    error={selectedJobGradeError.length > 0}
                    errorMessage={selectedJobGradeError}
                />
                <DetailText
                    labelText="Email"
                    value={selectedEmail}
                    isError={selectedEmailError.length > 0}
                    errorMessage={selectedEmailError}
                    onBlurFunc={(e:React.ChangeEvent<HTMLInputElement>) => {
                        setSelectedEmailError('');
                        onChangeString(e.target.value, 'selectedEmail', userIndex);
                    }}
                    onChangeFunc={(e:React.ChangeEvent<HTMLInputElement>) => setSelectedEmail(e.target.value)}
                    placeholderText="Add email address"
                    //disabled={typeof isEdit === 'boolean' && isEdit}
                />
                <Detail
                    labelText="Permissions"
                    isDisabled={false}
                    selectedValue={selectedPersmission}
                    setValue={setSelectedPersmission}
                    placeholder="Select permission"
                    allValues={adminRole === 'ADMIN' ? internalUserPermissionsValues : [{id:0, name:'STANDARD'}]}
                    width="14em"
                    error={selectedPersmissionError.length > 0}
                    errorMessage={selectedPersmissionError}
                />
                <Detail
                    labelText="PwC office (country)"
                    isDisabled={false}
                    selectedValue={selectedPrimaryCountry}
                    setValue={setSelectedPrimaryCountry}
                    placeholder="Add primary country"
                    allValues={allCountries}
                    width="14em"
                    error={selectedPrimaryCountryError.length > 0}
                    errorMessage={selectedPrimaryCountryError}
                />
            </UserValues>
        </>

    )
};

const RemoveButton = styled('div')`
    display: block;
    text-decoration: underline;
    cursor: pointer;
    margin-right: 30px;
    img {
        vertical-align: middle;
        margin-right: 0.2em;
    }
`;

const StatusToogle = styled(Toggle)`
    margin-left: 0.75rem;
`;

const TitleAndToggles = styled('div')<{
    index?: number;
}>`
    display: flex;
    align-items: center;
    flex-direction: row-reverse;

    border-top: ${(props) => (props.index !== 0 ? '1px solid black !important' : 'none')};
`;

const TogglesWrapper = styled('div')`
    display: flex;
    align-items: center;
`;

const UserValues = styled('div')`
    margin-top: 25px;
`;

const Detail = styled(DropdownSingleSelection)<{
    error?: boolean;
}>`
    display: inline-block;
    margin-right: 8%;
    margin-bottom: 11px;
    overflow: hidden;
    .dropdown-child {
        margin-bottom: '0px';
    }
`;

const DetailText = styled(InputTextField)`
    display: inline-block;
    margin-right: 8%;
    margin-bottom: 11px;
    overflow: hidden;
`;

export default memo(InternalUserDetails);