import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

import iconMagnifier from '../../../assets/images/icon-magnifier.svg';

type Props = {
    placeholder: string;
    callback: Function;
};

const SearchField: React.FC<Props> = ({ placeholder, callback }) => {
    const [value, setValue] = useState<string>();
    const setSearchValue = (val: string) => {
        setValue(val);
        if(val===''){
            callback(val);
        }
    };
    return (
        <Wrapper>
            <Search
                type="search"
                placeholder={placeholder}
                onKeyPress={(e: { charCode: number; }) => {
                    if (e.charCode === 13) {
                        callback(value);
                    }
                }}
                onChange={(
                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {
                    setSearchValue(e.target.value);
                }}
            />
            <ImageWrapper onClick={() => callback(value)}>
                <img src={iconMagnifier} alt="magnifier icon" />
            </ImageWrapper>
        </Wrapper>
    );
};

const Search = styled(InputBase)`
    border: none;
    width: calc(80% - 20px);
    height: 100%;
    outline: none;
    border-radius: 50px;
    padding-left: 24px;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333232;
    &::-webkit-input-placeholder {
        color: #818181;
    }
`;

const Wrapper = styled('div')`
    height: 2.375em;
    border-radius: 30px;
    border: solid 1px #dcdcdc;
    position: relative;
    width: 17em;
`;

const ImageWrapper = styled('div')`
    height: 100%;
    background-color: #575757;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 0 20px 0 20px;
    cursor: pointer;
    user-select: none;
`;

export default SearchField;
