import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import iconSave from '../../assets/images/icon-save.svg';
import {
    RedButton,
    Toggle,
    SuccessModal
} from '../../components';
import { LIST_SETTINGS, UPDATE_GLOBAL_SETTINGS } from './settingsQueries';
import {
    statusToggleLabels
} from './settingsHelpers';
import * as messages from '../../utils/Constant/messages';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Simple function for getting the right settings from the api response array.
function findArrayElementByName(array: any, name: string) {
    return array.find((element: any) => {
        return element.name === name;
    })
}


const ManageSettings: React.FC = () => {

    // Form initial states
    const [settingsChanged, setSettingsChanged] = useState<boolean>(false); // Flag for Success Modal
    const [readOnlyEnabled, setReadOnlyEnabled] = useState<boolean>(true);
    const [readOnlyID, setReadOnlyID] = useState<number>(1);
    const [exportButtonEnabled, setExportButtonEnabled] = useState<boolean>(true);
    const [exportButtonID, setExportButtonID] = useState<number>(1);
    const [targetScoringEnabled, setTargetScoringEnabled] = useState<boolean>(true); // Placeholder target scoring.
    const [targetScoringID, setTargetScoringID] = useState<number>(2); // Placeholder target scoring.
    const [decimalPointOneEnabled, setDecimalPointOneEnabled] = useState<boolean>(true); // Placeholder decimal .1 toggle.
    const [decimalPointOneID, setDecimalPointOneID] = useState<number>(4); // Placeholder decimal .1 toggle.
    const [powerBiReportsEnabled, setPowerBiReportsEnabled] = useState<boolean>(true); // Placeholder Power BI Reports
    const [powerBiReportsID, setPowerBiReportsID] = useState<number>(5); // Placeholder  Power BI Reports
    const [chatBotEnabled, setChatBotEnabled] = useState<boolean>(true); // Placeholder Chatbot
    const [chatBotID, setChatBotID] = useState<number>(6); // Placeholder Chatbot
    const [cesSearchEnabled, setCesSearchEnabled] = useState<boolean>(true); // Placeholder CES Search
    const [cesSearchID, setCesSearchID] = useState<number>(7); // Placeholder CES Search
    const [notificationsEnabled, setNotificationsEnabled] = useState<boolean>(true); // Placeholder Notification
    const [notificationsID, setNotificationsID] = useState<number>(8); // Placeholder Notification
    const [importBenchmarkingEnabled, setImportBenchmarkingEnabled] = useState<boolean>(true); // Placeholder Import Benchmark
    const [importBenchmarkingID, setImportBenchmarkingID] = useState<number>(7); // Placeholder Import Benchmark

    const [interaction, setInteraction] = useState<boolean>(false); // Var tracking user changes on the page.
    const [openSuccessModal, setOpenSuccessModal] = useState(false);

    // All settings
    const defaultPayload = { name: "" };
    const globalSettingsNames = [
        {
            id: readOnlyID,
            name: 'Read Only',
            label: 'Read Only View',
            status: readOnlyEnabled,
            payload: defaultPayload,
            action: setReadOnlyEnabled
        },
        {
            id: exportButtonID,
            name: 'Export Button',
            label: 'Export Button',
            status: exportButtonEnabled,
            payload: defaultPayload,
            action: setExportButtonEnabled
        },
        {
            id: targetScoringID,
            name: 'Target Scoring',
            label: 'Target Scoring',
            status: targetScoringEnabled,
            payload: defaultPayload,
            action: setTargetScoringEnabled
        },
        {
            id: decimalPointOneID,
            name: 'Decimal .1',
            label: 'Decimal .1',
            status: decimalPointOneEnabled,
            payload: defaultPayload,
            action: setDecimalPointOneEnabled
        },
        {
            id: powerBiReportsID,
            name: 'Power BI Reports',
            label: 'Power BI Reports',
            status: powerBiReportsEnabled,
            payload: defaultPayload,
            action: setPowerBiReportsEnabled
        },
        {
            id: chatBotID,
            name: 'ChatBot',
            label: 'ChatBot',
            status: chatBotEnabled,
            payload: defaultPayload,
            action: setChatBotEnabled
        },
        {
            id: cesSearchID,
            name: 'CES Search',
            label: 'CES Search',
            status: cesSearchEnabled,
            payload: defaultPayload,
            action: setCesSearchEnabled
        },
        {
            id: notificationsID,
            name: 'Notifications',
            label: 'Notifications',
            status: notificationsEnabled,
            payload: defaultPayload,
            action: setNotificationsEnabled
        },
        {
            id: importBenchmarkingID,
            name: 'Import Benchmarking',
            label: 'Import Benchmarking',
            status: importBenchmarkingEnabled,
            payload: defaultPayload,
            action: setImportBenchmarkingEnabled
        },
    ];

    const [open, setOpen] = React.useState(false);


    const handleClose = () => {
        setOpen(false);
    };
    const handleDisagree = () => {
        setOpen(false);
        setSettingsChanged(false);
        getSettings();
    };
    const handleAgree = () => {
        setOpen(false);
        confirmChanges();
    };

    // Success Modal
    useEffect(() => {
        if (settingsChanged === true) {
            setOpenSuccessModal(true);
            setSettingsChanged(false);
        }
    }, [settingsChanged]);

    const [getSettings, { data }] = useLazyQuery(LIST_SETTINGS, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {

            const readOnlyArray = findArrayElementByName(res.globalSettings, "Read Only");
            const findExportButton = findArrayElementByName(res.globalSettings, "Export Button");
            const targetScoringArray = findArrayElementByName(res.globalSettings, "Target Scoring"); // Placeholder target scoring.
            const decimalPointOneArray = findArrayElementByName(res.globalSettings, "Decimal .1");
            const powerBiReportsArray = findArrayElementByName(res.globalSettings, "Power BI Reports");
            const chatBotArray = findArrayElementByName(res.globalSettings, "ChatBot");
            const cesSearchArray = findArrayElementByName(res.globalSettings, "CES Search");
            const notificationsArray = findArrayElementByName(res.globalSettings, "Notifications");
            const importBenchmarkingArray = findArrayElementByName(res.globalSettings, "Import Benchmarking");

            setReadOnlyEnabled(readOnlyArray && readOnlyArray.status);
            setReadOnlyID(readOnlyArray && readOnlyArray.id);

            setExportButtonEnabled(findExportButton && findExportButton.status);
            setExportButtonID(findExportButton && findExportButton.id);

            setTargetScoringEnabled(targetScoringArray && targetScoringArray.status);
            setTargetScoringID(targetScoringArray && targetScoringArray.id);

            // Set Decimal Point One Values
            setDecimalPointOneEnabled(decimalPointOneArray && decimalPointOneArray.status);
            setDecimalPointOneID(decimalPointOneArray && decimalPointOneArray.id);

            setPowerBiReportsEnabled(powerBiReportsArray && powerBiReportsArray.status);
            setPowerBiReportsID(powerBiReportsArray && powerBiReportsArray.id);

            setChatBotEnabled(chatBotArray && chatBotArray.status);
            setChatBotID(chatBotArray && chatBotArray.id);

            setCesSearchEnabled(cesSearchArray && cesSearchArray.status);
            setCesSearchID(cesSearchArray && cesSearchArray.id);

            setNotificationsEnabled(notificationsArray && notificationsArray.status);
            setNotificationsID(notificationsArray && notificationsArray.id);

            setImportBenchmarkingEnabled(importBenchmarkingArray && importBenchmarkingArray.status);
            setImportBenchmarkingID(importBenchmarkingArray && importBenchmarkingArray.id);

        },
        onError: (err) => {
            console.log(err);
        }
    });

    useEffect(() => {
        if (!data) {
            getSettings();
        }
    }, [data]);

    const [updateGlobalSettings] = useMutation(UPDATE_GLOBAL_SETTINGS);
    const saveChanges = () => {
        setOpen(true);
    }

    const confirmChanges = () => {

        // Flag for received errors.
        let errResponse = false;

        Object.keys(globalSettingsNames).map(function (keyName, keyIndex) {
            return (
                updateGlobalSettings({
                    variables: {
                        id: globalSettingsNames[keyIndex].id,
                        data: {
                            name: globalSettingsNames[keyIndex].name,
                            payload: globalSettingsNames[keyIndex].payload,
                            status: globalSettingsNames[keyIndex].status
                        }
                    }
                }).then(() => {
                    // Upon successful change to the API, disable the save button again.
                    setInteraction(false);
                }).catch((err: any) => {
                    console.log(err);
                    errResponse = true;
                })
            )
        });

        // Trigger the success modal if appropriate.
        errResponse ? setSettingsChanged(false) : setSettingsChanged(true);

        const timer = setTimeout(() => {
            window.location.reload();
        }, 1000);

        return () => clearTimeout(timer);


    }

    // Set the save button to disabled if no changes have been made.
    const isDisabled = interaction ? false : true;

    return (
        <>
            <MainWrapper>
                <LinkWrapper />
                <NameAndCTAs>
                    <h1>Settings</h1>
                    <SaveButton disabled={isDisabled} onClick={saveChanges}>
                        <img src={iconSave} alt="add frm icon" />
                        Save changes
                    </SaveButton>
                </NameAndCTAs>

                <SubHeading>Global Settings</SubHeading><br></br><Divider></Divider>
                {globalSettingsNames.map((gs, index) => {
                    return <TogglesWrapper key={`setting-option-${index}`}>
                        <StatusLabel>{gs.label}</StatusLabel>
                        <StatusToggle
                            labels={statusToggleLabels}
                            state={gs.status}
                            actionFunc={() => {
                                gs.action(!gs.status);
                                setInteraction(true)
                            }}
                            cellID={`${gs.label}-status-toggle`}
                        />
                    </TogglesWrapper>
                })}

            </MainWrapper>

            <SuccessModal
                openState={openSuccessModal}
                hideDuration={6000}
                setOpen={setOpenSuccessModal}
                message={messages.SETTINGS_SUCCESS_MESSAGE}
            />

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Change Global Settings?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {messages.SETTINGS_PROMPT_MESSAGE}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDisagree} color="primary">
                        Disagree
                    </Button>
                    <Button onClick={handleAgree} color="primary" autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const SaveButton = styled(RedButton)`
    margin-left: 10px !important;
    width: 180px;
    font-size: 16px !important;
    img {
        padding-right: 3px;
    }
    height: 50%;
    margin-top: 0.67em !important;
`;

const MainWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

const NameAndCTAs = styled('div')`
    display: flex;
    justify-content: space-between;
    min-height: 100px;
    padding: 5px 0;

    h2 {
        margin: 0;
    }
`;

const LinkWrapper = styled('div')`
    height: 46px;
`;

const TogglesWrapper = styled('div')`
    display: flex;
    align-items: center;
    padding: 1em;
`;

const StatusToggle = styled(Toggle)`
    margin-left: 0.75em;
`;

const StatusLabel = styled('div')`
    min-width: 8em;
`;

const SubHeading = styled('div')`
    padding: 0px;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: -6px;
`;

const Divider = styled('div')`
    border-top: 2px solid #808080;
    padding-bottom: 5px;
`;

export default ManageSettings;
