import React from 'react';
import { createRoot } from 'react-dom/client';
import './create-nonce';
import { ApolloProvider } from '@apollo/client';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';

import App from './modules/App/App';
import client from './services/ApolloClient/client';
import { UserStoreProvider } from './context/user-store';

import './index.scss';
import CustomRouter from './routes/router';
import { BrowserRouter } from './routes/browserRouter';
import { AuthProvider } from 'react-oidc-context';
import AuthConfig from './modules/AuthPage/AuthConfig';


const theme = createTheme({
    palette: {
        primary: {
            main: '#2e7bf8'
        },
        secondary: {
            // light: '#0066ff',
            main: '#f8f8f8'
            // contrastText: '#ffcc00'
        }
    },
    typography: {
        fontFamily: 'CustomHelveticaNeue'
    }
});

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
            <BrowserRouter history={CustomRouter.history}>
                <AuthProvider {...AuthConfig }>
                    <UserStoreProvider>
                        <App/>
                    </UserStoreProvider>
                </AuthProvider>
            </BrowserRouter>
        </ThemeProvider>
    </ApolloProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
