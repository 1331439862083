import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { removeJwtFromWhitelist } from '../services/ApolloClient/client';
import { UserStore } from '../context/user-store';
import { ConfigRoutes } from '../routes/routes.enum';
import { styled } from '@mui/material/styles';
import { useAuth } from 'react-oidc-context';

export default function PrivateRoute({children}) {
    const navigate = useNavigate();
    let {
        state: { user }
    } = useContext(UserStore);
    const auth = useAuth();
    const tokensExist = localStorage.getItem('pid-token');
    const [isUserLoaded, setIsUserLoaded] = useState(false);
    const removeJwt = async () => {
        auth.signoutSilent();
        auth.removeUser();
        localStorage.clear();
        sessionStorage.clear();
        await removeJwtFromWhitelist();
    };

     useEffect(() => {
        if(user.id){
            setIsUserLoaded(true);
        }
        if(isUserLoaded){
            if (( tokensExist && user && !(user.role==='ADMIN' || (user.role==='STANDARD' && user.countryAdminCountries.length > 0)))) {
                alert(
                    `You're not authorised to view this page. Please contact an administrator for access`
                );
                removeJwt();
                navigate(ConfigRoutes.logoutRedirect)
            }
        }
    }, [user,isUserLoaded]);

    return (
        !isUserLoaded?
            <></> :
        (tokensExist && user && (user.role==='ADMIN' || (user.role==='STANDARD' && user.countryAdminCountries.length > 0))) ? (
                children
            ) : navigate(ConfigRoutes.logoutRedirect)
    );
}

PrivateRoute.propTypes = {
    component: PropTypes.oneOfType([
        PropTypes.object.isRequired,
        PropTypes.func.isRequired
    ])
};

export const Spinner = styled('div')`
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.5;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
`;
