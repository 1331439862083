import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router';

import Button from '../../../components/base/Buttons/Button';
import ExportButton from '../../../components/base/Buttons/ExportButton';
import Tabs from '../../../components/Tabs/Tab';
import RedButton from '../../../components/base/Buttons/RedButton';
import SearchField from '../../../components/base/SearchField/SearchField';
import SuccessModal from '../../../components/base/SuccessModal/SuccessModal';
import DropdownSingleSelection from '../../../components/base/Dropdowns/DropdownSingleSelection';
import NewTable from '../../../components/Table/Table';
import Router from '../../../routes/router';

import iconEdit from '../../../assets/images/icon-edit.svg';
import iconSave from '../../../assets/images/icon-save.svg';
import arrowIcon from '../../../assets/images/icon-indicator.svg';

import { TableRow as TableRowInter } from '../../../components/Table/types';

import {
    exportUsersByCountryCSV,
    exportFrameworksByCountryCSV,
    disabledEnabledDropdownValues,
    adminDropdownValues
} from '../countryHelpers';

import {
    FrameworksResponse,
    FrameworkSummariesByCountry,
    Framework,
    UserListModel,
    UserSummaries,
    UserListPage,
    CountryDetailsProps,
    CountryOrder
} from '../countryTypes';

import iconDownload from '../../../assets/images/download-btn.svg';
import { EDIT_COUNTRY, FRAMEWORK_SUMMARIES_BYCOUNTRY_MODEL, USERS_BYCOUNTRY_MODEL, PROPOSITIONS } from '../countryQueries';
import {
    getFrameworksTableHeader,
    getUsersTableHeader,
    saveHandler,
    setTableRowsWithFramework,
    setTableRowsWithUser,
    tabLabels,
    TabOptions
} from './countryDetailsHelpers';
import * as messages from '../../../utils/Constant/messages';

const CountryDetails: React.FC = () => {
    const params = useParams<CountryDetailsProps>();
    const perPage = 50;
    const countryName =decodeURIComponent(params.countryName ?? '');
    const countryId = decodeURIComponent(params.countryId ?? '');
    const [selectedPermission, setPermission] = useState<string>('All');
    const [selectedFrameworkAccess, setFrameworkAccess] = useState<string>('All');
    const [userAccess, setUserAccess] = useState<string>('All');
    const [frameworkPage, setFrameworkPage] = useState<number>(0);
    const [userPage, setUserPage] = useState<number>(0);
    const [frameworkTableRows, setFrameworkTableRows] = useState<TableRowInter[]>();
    const [userTableRows, setUserTableRows] = useState<TableRowInter[]>();
    const [frameworkSummaries, setFrameworkSummaries] = useState<FrameworkSummariesByCountry>();
    const [userSummaries, setUserSummaries] = useState<UserListPage>();
    const [searchFrameworksText, setSearchFrameworksText] = useState<string>('');
    const [searchUserText, setSearchUsersText] = useState<string>('');
    const [currentTab, setCurrentTab] = useState<string>(TabOptions.FRAMEWORKS);
    const [allSelectedFrameworks, setAllSelectedFrameworks] = useState<number[]>([]);
    const [allSelectedAdminUsers, setAllSelectedAdminUsers] = useState<number[]>([]);
    const [allSelectedUsers, setAllSelectedUsers] = useState<number[]>([]);
    const [allSelectedCreateCampaigns, setAllSelectedCreateCampaigns] = useState<number[]>([]);
    const [allInteractedFrameworks, setAllInteractedFrameworks] = useState<number[]>([]);
    const [allInteractedAdminUsers, setAllInteractedAdminUsers] = useState<number[]>([]);
    const [allInteractedUsers, setAllInteractedUsers] = useState<number[]>([]);
    const [allInteractedCreateCampaigns, setAllInteractedCreateCampaigns] = useState<number[]>([]);
    const [order, setOrder] = useState<CountryOrder|undefined>({field:'name',isAsc:true});
    const [openSuccessModal, setOpenSuccessModal] = useState(false);

    const [editCountry] = useMutation(EDIT_COUNTRY);
    const [exportSpinner, setExportSpinner] = useState<boolean>(false);
    const [selectedProposition, setSelectedProposition] = useState<string>(
        'All'
    );

    const { data: dataPropositions } = useQuery(PROPOSITIONS, {
        fetchPolicy: 'cache-first'
    });

    const [getFrameworkSummaries,{loading:frameworkLoading}] = useLazyQuery<FrameworksResponse>(FRAMEWORK_SUMMARIES_BYCOUNTRY_MODEL, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            setFrameworkSummaries(res.frameworkSummariesByCountry);

            const allEnabledFrameworks = res.frameworkSummariesByCountry.items
                .filter(
                    (framework: Framework) =>
                        framework.frameworkEnabled && !allInteractedFrameworks.includes(framework.frameworkId)
                )
                .map((framework: Framework) => framework.frameworkId);
            const allUniqueSelectedIds = Array.from(new Set([...allEnabledFrameworks, ...allSelectedFrameworks]));
            setAllSelectedFrameworks(allUniqueSelectedIds);
        },
        onError: (err) => {
            console.log(err);
        }
    });
    const [getUserSummaries,{loading:userLoading}] = useLazyQuery<UserListModel>(USERS_BYCOUNTRY_MODEL, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            setUserSummaries(res.usersByCountry);

            const allAdmins = res.usersByCountry.items
                .filter((users: UserSummaries) => users.countryAdmin && !allInteractedAdminUsers.includes(users.id))
                .map((users: UserSummaries) => users.id);
            const allUniqueAdminIds = Array.from(new Set([...allAdmins, ...allSelectedAdminUsers]));
            setAllSelectedAdminUsers(allUniqueAdminIds);

            const allSelected = res.usersByCountry.items
                .filter((users: UserSummaries) => users.countryUserEnabled && !allInteractedUsers.includes(users.id))
                .map((users: UserSummaries) => users.id);
            const allUniqueUserIds = Array.from(new Set([...allSelected, ...allSelectedUsers]));
            setAllSelectedUsers(allUniqueUserIds);

            const allCreateCampaigns = res.usersByCountry.items
            .filter((users: UserSummaries) => users.campaignCreateEnabled && !allInteractedCreateCampaigns.includes(users.id))
            .map((users: UserSummaries) => users.id);
            const allUniqueIds = Array.from(new Set([...allCreateCampaigns, ...allSelectedCreateCampaigns]));
            setAllSelectedCreateCampaigns(allUniqueIds);
        },
        onError: (err) => {
            console.log(err);
        }
    });

    useEffect(() => {
        if (currentTab === TabOptions.FRAMEWORKS) {
            getFrameworkSummaries({
                variables: {
                    countryId: parseInt(countryId),
                    pagination: {
                        order,
                        skip: frameworkPage * perPage,
                        take: perPage
                    },
                    search: searchFrameworksText,
                    filters: {
                        frameworkEnabled:
                            selectedFrameworkAccess === 'Enabled'
                                ? true
                                : selectedFrameworkAccess === 'Disabled'
                                ? false
                                : null,
                                proposition:selectedProposition === 'All' ? undefined : selectedProposition
                    }
                }
            });
        }
    }, [frameworkSummaries, currentTab, frameworkPage, selectedFrameworkAccess, searchFrameworksText,order,selectedProposition]);

    useEffect(() => {
        if (currentTab === TabOptions.USERS) {
            getUserSummaries({
                variables: {
                    countryId: parseInt(countryId),
                    pagination: {
                        order,
                        skip: userPage * perPage,
                        take: perPage
                    },
                    search: searchUserText,
                    filters: {
                        role: selectedPermission === 'All' ? null : selectedPermission,
                        countryUserEnabled:
                            userAccess === 'Enabled' ? true : userAccess === 'Disabled' ? false : undefined
                    }
                }
            });
        }
    }, [userSummaries, currentTab, userPage, selectedPermission, userAccess, searchUserText,order]);

    useEffect(() => {
        if (!frameworkSummaries || currentTab !== TabOptions.FRAMEWORKS) {
            return;
        }

        const rows = setTableRowsWithFramework(allSelectedFrameworks, handleFrameworksToggleChange, frameworkSummaries);

        setFrameworkTableRows(rows);
    }, [frameworkSummaries, allSelectedFrameworks, openSuccessModal]);

    useEffect(() => {
        if (!userSummaries || currentTab !== TabOptions.USERS) {
            return;
        }

        const rows = setTableRowsWithUser(
            allSelectedAdminUsers,
            handleUsersAdminToggleChange,
            allSelectedUsers,
            handleUsersEnabledToggleChange,
            allSelectedCreateCampaigns,
            handleUsersCreateCampaignsToggle,
            userSummaries
        );

        setUserTableRows(rows);
    }, [userSummaries, allSelectedAdminUsers, allSelectedUsers, openSuccessModal,allSelectedCreateCampaigns]);

    useEffect(() => {
        setOrder({field:'name',isAsc:true})
     }, [currentTab]);

    const editHandler = () => {
        Router.goToCountryEdit({ countryId });
    };

    const saveChanges = () => {
        saveHandler(
            allInteractedFrameworks,
            allInteractedAdminUsers,
            allInteractedUsers,
            editCountry,
            setOpenSuccessModal,
            countryId,
            countryName,
            allSelectedFrameworks,
            allSelectedUsers,
            allSelectedAdminUsers,
            setAllInteractedFrameworks,
            setAllInteractedAdminUsers,
            setAllInteractedUsers,
            allSelectedCreateCampaigns,
            allInteractedCreateCampaigns,
            setAllInteractedCreateCampaigns,
            userSummaries
        );
    };

    const createSortHandler = (property: string) => {
            if(order&&order.field===property)
            {
                setOrder({field:property,isAsc:!order.isAsc});
            }
            else{
                setOrder({field:property,isAsc:true});
            }

    };

    const handleChangeFrameworkPage = (event: unknown, newPage: number) => {
        setFrameworkPage(newPage);
    };

    const handleChangeUsersPage = (event: unknown, newPage: number) => {
        setUserPage(newPage);
    };

    const handleChangeTabs = (newTab: string) => {
        setCurrentTab(newTab);
    };

    const handleFrameworksToggleChange = (cellID: number) => {
        if (allSelectedFrameworks.includes(cellID)) {
            const arrWithoutCell = allSelectedFrameworks.filter((selFr: number) => {
                return selFr !== cellID;
            });
            setAllSelectedFrameworks(arrWithoutCell);
        } else {
            setAllSelectedFrameworks([...allSelectedFrameworks, cellID]);
        }

        if (!allInteractedFrameworks.includes(cellID)) {
            setAllInteractedFrameworks([...allInteractedFrameworks, cellID]);
        }
    };

    const handleUsersAdminToggleChange = (cellID: number) => {
        if (allSelectedAdminUsers.includes(cellID)) {
            const arrWithoutCell = allSelectedAdminUsers.filter((selFr: number) => {
                return selFr !== cellID;
            });
            setAllSelectedAdminUsers(arrWithoutCell);
        } else {
            setAllSelectedAdminUsers([...allSelectedAdminUsers, cellID]);
        }
        if (!allInteractedAdminUsers.includes(cellID)) {
            setAllInteractedAdminUsers([...allInteractedAdminUsers, cellID]);
        }
    };

    const handleUsersEnabledToggleChange = (cellID: number) => {
        if (allSelectedUsers.includes(cellID)) {
            const arrWithoutCell = allSelectedUsers.filter((selFr: number) => {
                return selFr !== cellID;
            });
            setAllSelectedUsers(arrWithoutCell);
        } else {
            setAllSelectedUsers([...allSelectedUsers, cellID]);
        }
        if (!allInteractedUsers.includes(cellID)) {
            setAllInteractedUsers([...allInteractedUsers, cellID]);
        }
    };

    const handleUsersCreateCampaignsToggle = (cellID: number) => {
        if (allSelectedCreateCampaigns.includes(cellID)) {
            const arrWithoutCell = allSelectedCreateCampaigns.filter((selFr: number) => {
                return selFr !== cellID;
            });
            setAllSelectedCreateCampaigns(arrWithoutCell);
        } else {
            setAllSelectedCreateCampaigns([...allSelectedCreateCampaigns, cellID]);
        }
        if (!allInteractedCreateCampaigns.includes(cellID)) {
            setAllInteractedCreateCampaigns([...allInteractedCreateCampaigns, cellID]);
        }
    };

    const frameworksTableHeader = getFrameworksTableHeader(createSortHandler);
    const usersTableHeader = getUsersTableHeader(createSortHandler);

    const userTablePagination = {
        rowsPerPage: perPage,
        currentPage: userPage,
        handleChangePage: handleChangeUsersPage,
        totalCount:
            currentTab === TabOptions.FRAMEWORKS
                ? frameworkSummaries && frameworkSummaries.total
                : userSummaries && userSummaries.total
    };

    const frameworkTablePagination = {
        rowsPerPage: perPage,
        currentPage: frameworkPage,
        handleChangePage: handleChangeFrameworkPage,
        totalCount:
            currentTab === TabOptions.FRAMEWORKS
                ? frameworkSummaries && frameworkSummaries.total
                : userSummaries && userSummaries.total
    };

    const frameworkExport = () => {
        const frameworkEnabled =
            selectedFrameworkAccess === 'Enabled' ? true : selectedFrameworkAccess === 'Disabled' ? false : null;
        exportFrameworksByCountryCSV(setExportSpinner, { frameworkEnabled, countryId });
    };

    const exportCountrySummariesByUser = () => {
        const role = selectedPermission === 'All' ? null : selectedPermission;
        const userEnabled = userAccess === 'Enabled' ? true : userAccess === 'Disabled' ? false : undefined;
        exportUsersByCountryCSV(setExportSpinner, { countryId, userEnabled, role });
    };

    const userSearchCallback = (value: string) => {
        setSearchUsersText(value);
        setUserPage(0);
    };

    const frameworkSearchCallback = (value: string) => {
        setSearchFrameworksText(value);
        setFrameworkPage(0);
    };

    const isDisabled =
        allInteractedFrameworks.length === 0 &&
        allInteractedAdminUsers.length === 0 &&
        allInteractedUsers.length === 0 &&
        allInteractedCreateCampaigns.length===0;

    return (
        <>
            <MainWrapper>
                <div>
                    <BackLink
                        onClick={() => Router.goToCountryManagement()}
                    >
                        <img src={arrowIcon} alt="back icon" />
                        Back to countries
                    </BackLink>
                    <TitleAndButtons>
                        <CountryText>
                            <h1>{countryName}</h1>
                        </CountryText>
                        <ButtonsWrapper>
                            <EditButton disabled={false} onClick={editHandler}>
                                <img src={iconEdit} alt="edit country icon" />
                                Edit country
                            </EditButton>
                            <SaveButton
                                disabled={isDisabled}
                                onClick={saveChanges}
                            >
                                <img src={iconSave} alt="add country icon" />
                                Save changes
                            </SaveButton>
                        </ButtonsWrapper>
                    </TitleAndButtons>
                    <Tabs tabLabels={tabLabels} activeTab={currentTab} action={handleChangeTabs} />
                    {currentTab === TabOptions.FRAMEWORKS && (
                        <>
                            <TableFiltersAndSearch>
                                <TableFilters>
                                <DropdownSingleSelection
                                  labelText={'Proposition'}
                                  isDisabled={false}
                                  selectedValue={selectedProposition}
                                  setValue={setSelectedProposition}
                                  allValues={
                                dataPropositions &&
                                dataPropositions.frameworkPropositions && [
                                    { id: 0, name: 'All' },
                                    ...dataPropositions.frameworkPropositions.slice().sort().map(
                                        (item: string, i: number) => ({
                                            id: i + 1,
                                            name: item
                                        })
                                    )
                                ]
                            }
                            width="10em"
                        />
                                    <DropdownSingleSelection
                                        labelText="Framework access"
                                        isDisabled={false}
                                        selectedValue={selectedFrameworkAccess}
                                        setValue={setFrameworkAccess}
                                        allValues={disabledEnabledDropdownValues}
                                        width="10em"
                                    />
                                </TableFilters>
                                <SearchAndSave>
                                    {exportSpinner ? (
                                        <CircularProgress />
                                    ) : (
                                        <ExportButton onClick={frameworkExport}>
                                            <img src={iconDownload} alt="download" />
                                        </ExportButton>
                                    )}
                                    <SearchField
                                        placeholder="Search"
                                        callback={(value: string) => frameworkSearchCallback(value)}
                                    />
                                </SearchAndSave>
                            </TableFiltersAndSearch>
                            {frameworkLoading?(
                                        <CircularProgress />
                                    ):
                            <CustomTable
                                header={frameworksTableHeader}
                                tablePagination={frameworkTablePagination}
                                rows={frameworkTableRows}
                            />}
                        </>
                    )}
                    {currentTab === TabOptions.USERS && (
                        <>
                            <TableFiltersAndSearch>
                                <TableFilters>
                                    <DropdownSingleSelection
                                        labelText="Permission"
                                        isDisabled={false}
                                        selectedValue={selectedPermission}
                                        setValue={setPermission}
                                        allValues={adminDropdownValues}
                                        width="10em"
                                    />
                                    <DropdownSingleSelection
                                        labelText="User access"
                                        isDisabled={false}
                                        selectedValue={userAccess}
                                        setValue={setUserAccess}
                                        allValues={disabledEnabledDropdownValues}
                                        width="10em"
                                    />
                                </TableFilters>
                                <SearchAndSave>
                                    {exportSpinner ? (
                                        <CircularProgress />
                                    ) : (
                                        <ExportButton onClick={exportCountrySummariesByUser}>
                                            <img src={iconDownload} alt="download" />
                                        </ExportButton>
                                    )}
                                    <SearchField
                                        placeholder="Search"
                                        callback={(value: string) => userSearchCallback(value)}
                                    />
                                </SearchAndSave>
                            </TableFiltersAndSearch>
                            {userLoading?(
                                        <CircularProgress />
                                    ):
                            <CustomTable
                                header={usersTableHeader}
                                tablePagination={userTablePagination}
                                rows={userTableRows}
                            />}
                        </>
                    )}
                </div>
            </MainWrapper>
            <SuccessModal
                openState={openSuccessModal}
                hideDuration={6000}
                setOpen={setOpenSuccessModal}
                message={messages.COUNTRY_SUCCESS_MESSAGE}
            />
        </>
    );
};

const CustomTable = styled(NewTable)`
    && {
        && {
            &&.MuiTableContainer-root {
                max-height: 150px;
            }
        }
    }
`;

const MainWrapper = styled('div')`
    display: flex;
    flex-direction: column;
`;

const EditButton = styled(Button)`
    width: 180px;
    font-size: 16px !important;
    border: 1.5px solid #2d2d2d !important;
    box-sizing: border-box;
    background-color: #ffffff !important;
`;

const SaveButton = styled(RedButton)`
    margin-left: 10px !important;
    width: 180px;
    font-size: 16px !important;
    img {
        padding-right: 3px;
    }
`;

const ButtonsWrapper = styled('div')`
    display: flex;
    height: 50%;
`;

const TitleAndButtons = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 2em;
`;

const TableFiltersAndSearch = styled('div')`
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
`;

const TableFilters = styled('div')`
    display: flex;
`;

const SearchAndSave = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

const CountryText = styled('div')`
    font-style: normal;
    font-weight: bold;
    padding: 5px 0;
    h1 {
        margin: 0;
    }
`;

const BackLink = styled('div')`
    cursor: pointer;
    display: flex;
    width: fit-content;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 2em;
    text-decoration: underline;
    line-height: 1.5em;
    img {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }
`;

export default CountryDetails;
