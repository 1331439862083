import React from 'react';
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';

import checkCircleIcon from '../../../assets/images/check-circle.svg';

import {
    ModalProps
} from './types';

const SuccessModal : React.FC<ModalProps> = ({openState, hideDuration, setOpen, message}) => {

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <MessageWrapper
            open={openState}
            autoHideDuration={hideDuration}
            onClose={handleClose}
        >
            <DivWrapper>
                <IconWrapper>
                    <img src={checkCircleIcon} alt='check-circle-icon'/>
                </IconWrapper>
                <TextWrapper>
                    {message}
                </TextWrapper>
            </DivWrapper>
        </MessageWrapper>
    )
}


const MessageWrapper = styled(Snackbar)`
    width: auto;
    height: 20px;
    left: 20% !important;
    transform: none !important;
`;

const DivWrapper = styled('div')`
    display: flex;
`;

const IconWrapper = styled('div')`
    width: 10%;
    background: #219653;
    height: 100%;
    display: flex;
    padding: 10px 20px 9px 20px ;
    justify-content: center;
    align-items: center;
`;

const TextWrapper = styled('div')`
    background: #40403E;
    width: 370px;
    height: 100%;
    padding: 10px;
    color: white;
`;

export default SuccessModal;
