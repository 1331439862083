import React, { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Menu from '../Layout/Menu/Menu';

const Main: React.FC = () => {
    return (
        <>
            <Fragment>
                <Menu />
                {/*Commented Tutorial not to pass all steps on rerendering*/}
                {/* <Tutorial /> */}
                <SubroutesWrapper>
                    <Outlet />
                </SubroutesWrapper>
            </Fragment>
        </>
    );
};

const SubroutesWrapper = styled('div')`
    width: 83%;
    padding: 0 5% 0 5%;
    box-sizing: border-box;
    flex: 7 7 83%;
    height: 100vh;
    overflow-y: scroll;
`;

export default Main;
