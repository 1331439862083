import { gql } from 'apollo-boost';

// Return all settings entries form the API.
export const LIST_SETTINGS = gql`
    query globalSettings {
        globalSettings {
            id
            name
            payload {
                name
            }
            status
            updatedAt
            createdAt
        }
    }
`;

// Get one settings entry by id.
export const GET_SETTINGS_BY_ID = gql`
    query getGlobalSettingsById($id: Int!) {
        globalSettings {
            id
            name
            payload {
                name
            }
            status
            updatedAt
            createdAt
        }
    }
`;

// Update one settings entry by id.
export const UPDATE_GLOBAL_SETTINGS = gql`
    mutation updateGlobalSettings(
        $id: Int!
        $data: CreateGlobalSettingsInput!
    ) {
        updateGlobalSettings(id: $id, data: $data) {
            id
            name
            payload {
                name
            }
            status
        }
    }
`;

// Update one settings status only by id.
export const UPDATE_GLOBAL_SETTINGS_STATUS = gql`
    mutation updateGlobalSettingsStatus(
        $id: Int!
        $status: CreateGlobalSettingsInput!
    ) {
        updateGlobalSettings(id: $id, status: $status) {
            id
            status
        }
    }
`;

// Create Global Settings.
export const CREATE_GLOBAL_SETTINGS = gql`
    mutation createGlobalSettings(
        $data: CreateGlobalSettingsInput!
    ) {
        createGlobalSettings(id: $id, data: $data) {
            name
            payload {
                name
            }
            status
        }
    }
`;
