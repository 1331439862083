import { styled } from '@mui/material/styles';
import React, { useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { RedButton, TransparentButtonInverted } from '../../components/base/Buttons';
import iconSave from '../../assets/images/icon-save.svg';
import { InputTextField } from '../../components';
import { Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Tooltip } from '@mui/material';
import { gql } from 'apollo-boost';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import _, { debounce } from 'lodash';
import closeIcon from '../../assets/images/icon-close-black.svg';
import { Text } from '../../components';
import { noFrameworkPresetMessage } from '../../utils/constants';
import axios from 'axios';
import InfoIcon from '@mui/icons-material/Info';
import DropzoneUpload from './Dropzone';
import { JsonEditor } from 'jsoneditor-react18';
import { CREATE_FRAMEWORK, CREATE_FRAMEWORK_VERSION } from './frameworkQueries';
import { CreateFrameworkInput, CreateFrameworkVersionInput, frameworkSchema } from './frameworkTypes';
import FailureModal from '../../components/base/FailureModal';
import * as messages from '../../utils/Constant/messages';
import { versionChanges } from './frameworkHelpers';
import Router from '../../routes/router';
import SpinnerWithOverlay from '../../components/base/SpinnerWithOverlay/SpinnerWithOverlay';

const CHECK_FRAMEWORK_NAME = gql`
    query frameworkByTitle($name: String!) {
        frameworkByTitle(name: $name) {
            id,
            version,
            framework_id,
            definition{
                name
            }
        }
    }
`;

export const benchmarkingToggleLabels = {
    trueLabel: 'ON',
    falseLabel: 'OFF'
};

const FrameworkUpload: React.FC = () => {
    const [showResult, setShowResult] = useState<any>([]);
    const [showName, setShowName] = useState<string>('');
    const [showVersion, setShowVersion] = useState<string>('NA');
    const [frameworkId, setFrameworkId] = useState<number>(0);
    const [isFrameworkLoading, setIsFrameworkLoading] = useState<boolean>(false);
    const [isFrameworkNotPresent, setIsFrameworkNotPresent] = useState<boolean>(false);
    const [showFrameworkUploadCheckbox, setShowFrameworkUploadCheckbox] = useState<boolean>(false);
    const [frameworkReadyToUpload, setFrameworkReadyToUpload] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<any | null>(null);
    const [jsonData, setJsonData] = useState<any>();
    const [showJson, setShowJson] = useState<boolean>();
    const [createNewFramework] = useMutation(CREATE_FRAMEWORK);
    const [createFrameworkVersion] = useMutation(CREATE_FRAMEWORK_VERSION);
    const [FrameworkNameError, setFrameworkNameError] = useState<string>('');
    const [frameworkUploadingLoader, setFrameworkUploadingLoader] = useState<boolean>(false);
    const [frameworkBoxLoader, setFrameworkBoxLoader] = useState<boolean>(false);
    const [openDialogBox, setOpenDialogBox] = useState<boolean>(false);
    const [openFailureModal, setOpenFailureModal] = useState(false);
    const [failureModalMessage, setFailureModalMessage] = useState(messages.FRAMEWORKS_FAILURE_MESSAGE);


    const { data } = useQuery(CHECK_FRAMEWORK_NAME, {
        variables: {
            name: showName
        },
        fetchPolicy: 'no-cache'
    });

    useEffect(()=>{
        if(data && showName.length !== 0 && !showFrameworkUploadCheckbox){
            if (data.frameworkByTitle.length === 0)
                setIsFrameworkNotPresent(true);
                setShowVersion('NA');
            setShowResult(data.frameworkByTitle)
        }
        setIsFrameworkLoading(false);
    },[data])

    const handleNameChange = (event: any) => {
        setSelectedFile(false);
        setShowJson(false);
        setFrameworkReadyToUpload(false);
        setFrameworkNameError('');
        const valueEntered = event.target.value
        setShowName(valueEntered);
        setIsFrameworkLoading(true);
        setIsFrameworkNotPresent(false);
        setShowFrameworkUploadCheckbox(false);
    }
    useEffect(() => {
        if (!showName) {
            setIsFrameworkNotPresent(false);
            setShowFrameworkUploadCheckbox(false);
            setFrameworkReadyToUpload(false);
            setShowVersion('NA');
            setSelectedFile(false);
        }
    }, [showName]);

    const handleClear = () => {
        setShowResult([]);
        setIsFrameworkLoading(false);
        setShowName('');
        setIsFrameworkNotPresent(false);
        setShowFrameworkUploadCheckbox(false);
        setShowVersion('NA');
        setFrameworkReadyToUpload(false);
        setSelectedFile(false);
    };

    useEffect(()=>{
        if(noFrameworkPresetMessage){
            setSelectedFile(null)
        }
    },[isFrameworkNotPresent])

    const handleReupload = (isMajorVersion: boolean) => {
        const newVersion = versionChanges(showVersion);
        const constructFrameworkVersionInput: CreateFrameworkVersionInput = {
            frameworkId: frameworkId,
            version: newVersion,
            definition: {
                name: jsonData.name,
                shortName: jsonData.shortName,
                scores: jsonData.scores,
                domains: jsonData.domains,
                metadata: jsonData.metadata,
                supportsThreatAndRisk: jsonData.supportsThreatAndRisk,
                proposition: jsonData.proposition,
                countryOwner: jsonData.countryOwner,
                hasObjectiveComponents: jsonData.hasObjectiveComponents
            },
            ownerId: jsonData.ownerId
        };
        createFrameworkVersion({
            variables: {
                data: constructFrameworkVersionInput,
                majorVersion: isMajorVersion ? true : false
             },

        })
            .then((response: any) => {
                setFrameworkUploadingLoader(false);
                Router.goToFrameworksManagement('true');
            })
            .catch(handleError);
    }
    const handleUpload = () => {
        if (!showName) {
            setFrameworkNameError('Required');
        }
        if (jsonData !== null && jsonData !== undefined) {
            setFrameworkUploadingLoader(true);
            if (isFrameworkNotPresent) {
                const constructFrameworkInput: CreateFrameworkInput = {
                    definition: {
                        name: jsonData.name,
                        shortName: jsonData.shortName,
                        scores: jsonData.scores,
                        domains: jsonData.domains,
                        metadata: jsonData.metadata,
                        supportsThreatAndRisk: jsonData.supportsThreatAndRisk,
                        proposition: jsonData.proposition,
                        countryOwner: jsonData.countryOwner,
                        hasObjectiveComponents: jsonData.hasObjectiveComponents
                    },
                    ownerId: jsonData.ownerId
                };
                createNewFramework({
                    variables: {
                        data: constructFrameworkInput,
                        majorVersion: false
                     }
                })
                    .then((response: any) => {
                        setFrameworkUploadingLoader(false);
                        Router.goToFrameworksManagement('true');
                    })
                    .catch(handleError);
            }
            else {
               handleReupload(false);
          }
        }

      };

    const errorValidation = () =>{
        setOpenDialogBox(false);
        setOpenFailureModal(true);
        setFrameworkUploadingLoader(false);
    }
    const handleError = (error: any) => {
        if (error.networkError && error.networkError.result && error.networkError.result.errors) {
            const errorMessage = error.networkError.result.errors[0].message;
            if (errorMessage.includes("String cannot represent a non string value")) {
                errorValidation();
                setFailureModalMessage(messages.ERROR_FRAMEWORK_SHORTNAME);
            }
        }
        else if (error.message.includes("Existing duplicated domainShortNames with")) {
            errorValidation();
            setFailureModalMessage(messages.ERROR_DUPLICATE_SHORTNAME);
        }
        else if (error.message.includes('You are trying to apply minor explicit version to major changes')) {
            setFrameworkUploadingLoader(false);
            setOpenDialogBox(true);
        }
        else {
            setOpenFailureModal(true);
            setFrameworkUploadingLoader(false);
        }
    }
    const handJsonEditor = (json: any) => {
        setJsonData(json);
    }

    const cancelHandler = () => {
        Router.goToManageFrameworks();
    };

    const handlePreview = () => {
        setFrameworkBoxLoader(true);
        if (selectedFile && !selectedFile[0].name.toLowerCase().includes(showName.toLowerCase())) {
            setFrameworkBoxLoader(false)
            setFailureModalMessage("Please upload valid file.");
            setOpenFailureModal(true);
            setSelectedFile(false);
            return;
        }

        if (selectedFile) {
            const tkn = localStorage.getItem('pid-token');
            const clientLocal = localStorage.getItem('client');
            let clientUUID = null;
            if (clientLocal) {
                const clientParsed = JSON.parse(clientLocal);
                clientUUID = clientParsed.id;

            }
            const bodyFormData = new FormData();
            bodyFormData.append('frameworkData', selectedFile[0]);

            // calling upload API
            axios.post(window.__ENV__.REACT_APP_APP_URL + '/framework/upload', bodyFormData, {
                headers: {
                    'pearl-client-uuid': clientUUID,
                    'Authorization': `Bearer ${tkn}`,
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    const receivedJsonData = response.data.data;
                    setJsonData(JSON.parse(receivedJsonData)[0]);
                    setFrameworkBoxLoader(false);
                    setShowJson(true);
                })
                .catch((error) => {
                    setFrameworkBoxLoader(false)
                });
        }

    };
    return (
        <>
            <MainWrapper>
                <HeaderWrapper>
                    <Header>
                        <h1>Framework Upload</h1>
                        <ButtonsWrapper>
                            <CancelButton disabled={false} onClick={cancelHandler}>
                                Cancel
                            </CancelButton>
                            <UploadButton disabled={false} onClick={handleUpload}>
                                <img src={iconSave} alt="save" />
                                Upload
                            </UploadButton>
                        </ButtonsWrapper>
                    </Header>
                </HeaderWrapper>
                <div>
                    <TitleAndToggles>
                        <SearchDropdown>
                            <CustomField>
                                <TextField
                                    id="standard-basic"
                                    label="Name"
                                    value={showName}
                                    style={{
                                        width: '100%',
                                        paddingRight: '40px'
                                    }}
                                    autoComplete="off"
                                    variant='standard'
                                    onChange={handleNameChange}
                                    helperText={
                                        <Text style={{ color: 'red' }}>{FrameworkNameError}</Text>
                                    }
                                />
                                {showResult && showResult.length > 0 &&
                                    <ClearBtn onClick={(e) => {
                                        setShowResult([]);
                                    }}>
                                        <img
                                            src={closeIcon} alt="close icon"
                                            />
                                    </ClearBtn>
                                }
                            </CustomField>
                            {(isFrameworkLoading) &&
                                <BoxLoader top={'15px'}><CircularProgress/></BoxLoader>
                            }
                            {isFrameworkNotPresent &&
                                <CustomErrorMessage>{noFrameworkPresetMessage}</CustomErrorMessage>
                            }
                            {showResult && showResult.length > 0 &&
                                <CustomDropDown>
                                    {
                                        showResult.map((item: any, index: number) => (
                                            <Item key={`key-${item.definition.name}-${item.version}-Index-${index}`}
                                                onClick={() => {
                                                    setShowName(item.definition.name);
                                                    setShowVersion(item.version);
                                                    setFrameworkId(item.framework_id);
                                                    setShowFrameworkUploadCheckbox(true);
                                                    setShowResult([]);
                                                }}>
                                                <Text>{item.definition.name} - {item.version}</Text>
                                            </Item>
                                        ))
                                    }
                                </CustomDropDown>
                            }

                        </SearchDropdown>

                        {/* <TemplateButton disabled={false} >
                            <img src={templateIcon} alt="template icon" aria-hidden={true} className="template-icon" />
                            Template
                            <img src={dropdownArrow} alt="dropdown icon" aria-hidden={true} className="dropdown-icon" />
                        </TemplateButton> */}
                    </TitleAndToggles>


                    <FrameworkDetails >
                        <Wrappers>
                            Current Version :
                        </Wrappers>
                        <CustomTextField
                            labelText=""
                            disabled={true}
                            placeholderText="" value={showVersion}
                            isError={false}
                            onChangeFunc={() => {}}
                            />
                    </FrameworkDetails>

                    {(showFrameworkUploadCheckbox || isFrameworkNotPresent) &&
                        <>
                            <Checkbox
                                checked={frameworkReadyToUpload}
                                onChange={() => {
                                    setFrameworkReadyToUpload(!frameworkReadyToUpload)
                                }}
                            />
                            <Text>Proceed to {isFrameworkNotPresent ? <b>upload new</b> : <b>re-upload</b>} framework</Text>
                            <Tooltip
                                title={
                                    <ul>{'While uploading / reuploading a framework'}
                                        <li>{'Ensure file format is xlxs'}</li>
                                        <li>{'File name matches framework name'}</li>
                                        <li>{'Preview file contents carefully before upload'}</li>
                                    </ul>
                                }
                                 placement="right">
                                <IconButton style={{padding: 0, marginLeft: '4px', marginBottom: '2px'}} aria-label='info tooltip'>
                                <InfoIcon style={{fontSize: '14px'}}/>
                                </IconButton>
                            </Tooltip>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            border: 1,
                                            flexGrow: 1,
                                            width: '80%',
                                            maxWidth: '400px'
                                        }}
                                    >
                                        <DropzoneUpload
                                            files={selectedFile}
                                            selectedFile={setSelectedFile}
                                            subTitle={'Only *.xlsx file will be accepted'}
                                            extraText={'1 file is the maximum number of files you can drop here'}
                                            frameworkReadyToUpload={frameworkReadyToUpload}
                                        />
                                        <Box sx={{ marginTop: 'auto' }} >
                                            <FrameworkBoxButton>
                                                <ButtonContainer>
                                                    <RedButton
                                                        variant="contained"
                                                        disabled={!frameworkReadyToUpload}
                                                        onClick={() => handleClear()}
                                                    >
                                                        Clear all
                                                    </RedButton>
                                                    &nbsp;&nbsp;
                                                    <RedButton
                                                        variant="contained"
                                                        disabled={!frameworkReadyToUpload}
                                                        onClick={() => handlePreview()}
                                                    >
                                                        Preview
                                                    </RedButton>
                                                </ButtonContainer>
                                            </FrameworkBoxButton>
                                        </Box>
                                    </Box>
                                </Grid>
                                {frameworkBoxLoader ?
                                <BoxLoader top={'-60px'}><CircularProgress/></BoxLoader>
                                     :
                                    showJson && selectedFile && jsonData &&
                                    <Grid item xs={6}>
                                        <StyledBox >
                                                <StyleJsonEditor
                                                    onChange={handJsonEditor}
                                                    statusBar={true}
                                                    value={jsonData}
                                                    schema={frameworkSchema}
                                                />
                                                <style>{
                                                    `.jsoneditor-header {
                                                        color: white;
                                                        padding: 10px;
                                                    }
                                                    .jsoneditor-menu{
                                                        background-color: #575757;
                                                        border-color: #575757;
                                                    }
                                                    .jsoneditor{
                                                        border: none;
                                                    }`
                                                    }</style>
                                        </StyledBox>
                                    </Grid>
                                }
                            </Grid>
                            <FailureModal
                                openState={openFailureModal}
                                hideDuration={6000}
                                setOpen={setOpenFailureModal}
                                message={failureModalMessage}
                            />

                        </>
                    }

                </div>
            <Dialog
                open={openDialogBox}
                onClose={() => {}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {'Major Version upload !'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Warning: This framework contains significant changes and is considered as Major version.
                        Are you sure you want to upload this as a major version?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialogBox(false)} color="primary">
                        Disagree
                    </Button>
                    <Button onClick={() => handleReupload(true)} color="primary" autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
                {frameworkUploadingLoader && <SpinnerWithOverlay/> }
            </MainWrapper>
        </>

    )
}

const ButtonContainer = styled('div')`
  margin-right: 5.9em;
  display: flex;
  flex-direction: row;
  margin-top: 8%;
  padding-bottom: 7px;
  `;

  const StyledBox = styled('div')`
    position: relative;
    top: -120px;
    height: 400px;
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid black;
  `;

  const StyleJsonEditor = styled(JsonEditor)`
   .jsoneditor{
        border: none;
        height: 100%;
    }
  `;


const BoxLoader = styled('div')<{
    top?:string;
}>`
    width: 22px;
    height: 22px;
    position: absolute;
    margin: auto;
    top: ${props => props.top};
    bottom: 0px;
    right: 600px;
  `;
const Item = styled('li')`
    display: block;
    font-size: 14px;
    padding: 12px;
    transition: all 300ms ease-in-out;

    &:hover {
        background-color: #eee;
    }
`;
const Wrappers = styled('div')`
    display: flex;
    align-items: center;
    padding-right: 10px;
`;

const ClearBtn = styled('span')`
    width: 20px;
    height: 20px;
    position: absolute;
    margin: auto;
    top: 15px;
    bottom: 0px;
    right: 15px;
    font-size: 20px;
    cursor: pointer;
`;
const CustomDropDown = styled('ul')`
    box-shadow: 0px 0px 8px -2px #9d9d9d;
    border-radius: 5px;
    background: white;
    position: absolute;
    top: calc(100% + 15px);
    margin: auto;
    left: 0;
    right: 0;
    width: auto;
    max-height: 500px;
    overflow: auto;
    z-index: 999;
    padding: 0px;
    cursor: pointer;
`;
const CustomTextField = styled(InputTextField)`
   .MuiTextField-root{
        textalign: center !important;
        width: 5em !important;
        margin-right: 0.9em !important;
   }
`;
const FrameworkDetails = styled('div')`
    margin-top: 25px;
    display: flex;

`;
const FrameworkBoxButton = styled('div')`
    display: flex;
    justify-content: flex-end;
    left: 90px;
    position: relative;
`;

const MainWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

const HeaderWrapper = styled('div')`
    height: 165px;
`;
const CancelButton = styled(TransparentButtonInverted)`
    width: 180px;
    font-size: 18px !important;
`;

const TemplateButton = styled(RedButton)`
    width: 180px;
    font-size: 18px !important;
    height: 40px;
    padding: 10px 50px 10px 50px;
    img.dropdown-icon{
        margin-left: 10px;
    }
    img.template-icon {
        margin-right: 10px;
      }

`;

const UploadButton = styled(RedButton)`
    margin-left: 10px !important;
    width: 180px;
    font-size: 18px !important;
    img {
        padding-right: 3px;
    }
`;
const DraftButton = styled(RedButton)`
    margin-left: 10px !important;
    width: 180px;
    font-size: 18px !important;
    img {
        padding-right: 3px;
    }
`;

const ButtonsWrapper = styled('div')`
    display: flex;
    padding-right: 56px;
    height: 50%;
`;

const Header = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #151515;
    height: 107px;
    color: white;
    position: absolute;
    width: 78%;
    left: 0;
    padding-left: 22%;
    z-index: 10;
`;

const TitleAndToggles = styled('div')`
    display: flex;
    align-items: center;
`;

const SearchDropdown = styled('div')`
    position: relative;
    width: 100%;
`;

const CustomField = styled('div')`
    display: flex;
    position: relative;
    margin-right: 600px;
    text-align: center;
`;

const CustomErrorMessage = styled(Text)`
    font-size: 0.75em;
    color: #de3328;
    text-align: left;
`;

export default FrameworkUpload;
