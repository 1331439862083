
export const notificationTypesList = [
    {
        id: 0,
        name: 'INFO',
    },
    {
        id: 1,
        name: 'WARNING',
    },
    {
        id: 2,
        name: 'ERROR',
    },
    {
        id: 3,
        name: 'SUCCESS',
    },
];

export const notificationRangeList = [
    {
        id: 0,
        name: 'ALL',
    },
    {
        id: 1,
        name: 'SPECIFIC COUNTRY',
    },
    {
        id: 2,
        name: 'SPECIFIC COMPANY',
    },
];

export const TabOptions = {
    OPENCAMPAIGNS: 'Trigger Open Campaigns Notification',
    CUSTOMNOTIFICATIONS: 'Create a Custom Notification'
};

export const tabLabels = [TabOptions.OPENCAMPAIGNS, TabOptions.CUSTOMNOTIFICATIONS];
