import { gql } from 'apollo-boost';

export const LIST_COUNTRIES = gql`
    query countrySummaries(
        $pagination: PaginationInput
        $search: String
        $filters: CountryFilters
    ) {
        countrySummaries(
            pagination: $pagination
            search: $search
            filters: $filters
        ) {
            items {
                id
                name
                isoCode
            }
            take
            skip
            total
            count
        }
    }
`;

export const COMPANIES_LIST_MODEL = gql`
    query clientSummaries(
        $pagination: PaginationInput
        $search: String
        $filters: ClientFilters
    ) {
        clientSummaries(
            pagination: $pagination
            search: $search
            filters: $filters
        ) {
            items {
                id
                name
                prId
                industryName
                countryIsoCode
                clientEnabled
                benchmarkingEnabled
            }
            take
            skip
            total
            count
        }
    }
`;

export const FRAMEWORK_SUMMARIES_BY_COMPANY_MODEL = gql`
    query frameworkSummariesByCompany(
        $clientId: ID!
        $pagination: PaginationInput
        $search: String
        $filters: ClientFrameworkFilters
    ) {
        frameworkSummariesByCompany(
            clientId: $clientId
            pagination: $pagination
            search: $search
            filters: $filters
        ) {
            items {
                frameworkId
                name
                countryOwner
                proposition
                frameworkEnabled
            }
            take
            skip
            total
            count
        }
    }
`;

export const USERS_BY_COMPANY_MODEL = gql`
    query usersByClientPage(
        $clientId: ID!
        $pagination: PaginationInput
        $search: String
        $filters: ClientUserFilters
    ) {
        usersByClientPage(
            clientId: $clientId
            pagination: $pagination
            search: $search
            filters: $filters
        ) {
            items {
                id
                clientId
                name
                email
                role
                primaryCountry
                createCampaign
                displayRole
                userEnabled
            }
            take
            skip
            total
            count
        }
    }
`;

export const EDIT_COMPANY = gql`
    mutation updateClient($id: Int!, $data: UpdateClientInput!) {
        updateClient(id: $id, data: $data) {
            id
            name
            prId
            country
            clientEnabled
            countryId
            gcpRegionId
            updatedAt
            createdAt
        }
    }
`;

export const DELETE_COMPANY = gql`
    mutation deleteClient($id: Int!) {
        deleteClient(id: $id) {
            deleted
        }
    }
`;

export const EDIT_COMPANIES = gql`
    mutation updateClients($data: [UpdateClientsInput!]!) {
        updateClients(data: $data)
    }
`;

export const ADD_COMPANY = gql`
    mutation createClient($data: CreateClientInput!) {
        createClient(data: $data) {
            id
            name
            prId
            countryId
            benchmarkingEnabled
            gcpRegionId
            updatedAt
            createdAt
        }
    }
`;

export const ALL_USERS = gql`
    query usersByCountry(
        $countryId: ID!,
        $pagination: PaginationInput,
        $search: String,
        $enabledClientUserIds: [Int!],
        $filters: CountryUserFilters)
    {
        usersByCountry(
            countryId: $countryId,
            pagination: $pagination,
            search: $search,
            enabledClientUserIds: $enabledClientUserIds,
            filters: $filters)
        {
            items {
                id
                name
                role
                displayRole
                email
            }
            take
            skip
            total
            count
        }
    }
`;

export const ALL_FRAMEWORKS = gql`
    query frameworkSummariesByCountry(
        $countryId: Int!,
        $pagination: PaginationInput,
        $search: String,
        $enabledClientFrameworkIds: [Int!],
        $filters: CountryFrameworkFilters)
    {
        frameworkSummariesByCountry(
            countryId: $countryId,
            pagination: $pagination,
            search: $search,
            enabledClientFrameworkIds: $enabledClientFrameworkIds,
            filters: $filters)
        {
            items {
                frameworkId,
                name,
                countryOwner,
                proposition
                frameworkEnabled
            }
            take
            skip
            total
            count
        }
    }
`;

export const COMPANY_DETAILS = gql`
    query client($id: Int!) {
        client(id: $id) {
            id
            name
            country
            countryId
            homeCountry
            benchmarkingEnabled
            clientEnabled
            prId
            gcpRegionId
            industry {
                id
                name
            }
            annualRevenue {
                id
                value
            }
            employeesNumber {
                id
                value
            }
            type {
                id
                name
            }
            owner {
                id
                firstName
                lastName
                email
                phone
                jobTitle
            }
        }
    }
`;

export const COMPANY_DETAILS_MIN = gql`
    query client($id: Int!) {
        client(id: $id) {
            id
            country
            countryId
            clientEnabled
        }
    }
`;

export const DROPDOWN_VALUES = gql`
    query dropdown_values(
        $pagination: PaginationInput
        $search: String
        $filters: CountryFilters
    ) {
        annualRevenues {
            id
            value
        }
        industries {
            id
            name
        }
        employeesNumbers {
            id
            value
        }
        clientTypes {
            id
            name
        }
        countrySummaries(
            pagination: $pagination
            search: $search
            filters: $filters
        ) {
            items {
                id
                name
                gcpRegionId
            }
            take
            skip
            total
            count
        }
        gcpRegions {
            id
            name
        }
        homeCountries {
            country
        }
    }
`;

export const PROPOSITIONS = gql`
    query frameworkPropositions {
        frameworkPropositions
    }
`;


export const CREATE_NOTIFICATION = gql`
    mutation createNotification($input: CreateNotificationInput!) {
        createNotification(input: $input) {
            userId
            notificationType
            notificationCategory
            referenceID
            notificationMessage
            wasRead
        }
    }
`;
