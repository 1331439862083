import { SortDirection, TableCellTypes } from '../../../components/Table/types';
import {
    Framework,
    FrameworkSummariesByCountry,
    SortHandler,
    UserListPage,
    UserSummaries
} from '../countryTypes';
import {
    ChangeToggle
} from '../../../components/base/Toggle/types';
import Router from '../../../routes/router';
import { AxiosError } from 'axios';
import { DropdownValue, DropdownValueType } from '../../../components/base/Dropdowns/types';
import {convertPermissionLabels} from '../../../utils/helpers';
import * as messages from '../../../utils/Constant/messages';
import { MutationFunction } from '@apollo/client';

export const TabOptions = {
    FRAMEWORKS: 'Frameworks',
    USERS: 'Users'
};

export const tabLabels = [TabOptions.FRAMEWORKS, TabOptions.USERS];

export const benchmarkingToggleLabels = {
    trueLabel: 'ON',
    falseLabel: 'OFF'
};

export const statusToggleLabels = {
    trueLabel: 'Enabled',
    falseLabel: 'Disabled'
};

export const getHeaderFrameworksData = (
    createSortHandler: SortHandler
) => [
    {
        headerName: 'Framework name',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: ()=>createSortHandler('name')
    },
    {
        headerName: 'Country owner',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: ()=>createSortHandler('countryOwner')
    },
    {
        headerName: 'Proposition',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: ()=>createSortHandler('proposition')
    },
    {
        headerName: 'Framework access',
        isHeaderSortable: false
    }
];

export const setFrameworkTableRows = (
    allFrameworksResponse: FrameworkSummariesByCountry,
    allSelectedFrameworks: number[],
    handleFrameworksToggleChange: ChangeToggle
) => {
    return (
        allFrameworksResponse &&
        allFrameworksResponse.items &&
        allFrameworksResponse.items.map((framework: Framework) => {
            return {
                tableCells: [
                    {
                        cellName: framework.name,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: framework.countryOwner,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: framework.proposition,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        isCellSortable: false,
                        cellType: 'boolean' as TableCellTypes,
                        toggleLabels: {
                            trueLabel: 'Enabled',
                            falseLabel: 'Disabled'
                        },
                        currentState: allSelectedFrameworks.includes(framework.frameworkId),
                        actionFunc: handleFrameworksToggleChange,
                        cellID: framework.frameworkId
                    }
                ]
            };
        })
    );
};

export const getHeaderUsersData = (
    createSortHandler: SortHandler
) => [
    {
        headerName: 'User name',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: ()=>createSortHandler('name')
    },
    {
        headerName: 'Email address',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: ()=>createSortHandler('email')
    },
    {
        headerName: 'Permission',
        isHeaderSortable: false,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: ()=>createSortHandler('role')
    },
    {
        headerName: 'Create campaigns',
        isHeaderSortable: false
    },
    {
        headerName: 'Country admin',
        isHeaderSortable: false
    },
    {
        headerName: 'User access',
        isHeaderSortable: false
    }
];

export const setUsersTableRows = (
    allUsersByCountry: UserListPage,
    allSelectedAdminUsers: number[],
    allSelectedUsers: number[],
    handleUsersEnabledToggleChange: ChangeToggle,
    handleUsersAdminToggleChange: ChangeToggle,
    allSelectedCreateCampaigns:number[],
    handleUsersCreateCampaignsToggle:Function
) => {
    return (
        allUsersByCountry &&
        allUsersByCountry.items &&
        allUsersByCountry.items.map((user: UserSummaries) => {
            return {
                tableCells: [
                    {
                        cellName: user.name,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: user.email,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: convertPermissionLabels(user.displayRole),
                        isCellSortable: false,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        isCellSortable: false,
                        cellType: 'boolean' as TableCellTypes,
                        toggleLabels: {
                            trueLabel: 'Enabled',
                            falseLabel: 'Disabled'
                        },
                        currentState: allSelectedCreateCampaigns.includes(user.id),
                        actionFunc: () => handleUsersCreateCampaignsToggle(user.id),
                        cellID: user.id,
                        hideCell: user.role === 'EXTERNAL'
                    },
                    {
                        isCellSortable: false,
                        cellType: 'boolean' as TableCellTypes,
                        toggleLabels: {
                            trueLabel: 'Enabled',
                            falseLabel: 'Disabled'
                        },
                        currentState: allSelectedAdminUsers.includes(user.id),
                        actionFunc: handleUsersAdminToggleChange,
                        cellID: user.id,
                        hideCell: user.role === 'EXTERNAL'
                    },
                    {
                        isCellSortable: false,
                        cellType: 'boolean' as TableCellTypes,
                        toggleLabels: {
                            trueLabel: 'Enabled',
                            falseLabel: 'Disabled'
                        },
                        currentState: allSelectedUsers.includes(user.id),
                        actionFunc: handleUsersEnabledToggleChange,
                        cellID: user.id,
                        hideCell: user.role === 'EXTERNAL'
                    }
                ]
            };
        })
    );
};

export const createTempFrameworksFomat = (
    allInteractedFrameworks: number[],
    allSelectedFrameworks: number[]
) => {
    return allInteractedFrameworks.map((frId: number) => {
        return {
            id: frId,
            frameworkEnabled: allSelectedFrameworks.includes(frId)
        };
    });
};

export const createTempUsersFomat = (
    allInteractedAdminUsers: number[],
    allInteractedUsers: number[],
    allSelectedUsers: number[],
    allUsersByCountry: UserListPage|undefined,
    allSelectedAdminUsers: number[],
    allSelectedCreateCampaigns:number[],
    allInteractedCreateCampaigns:number[]
) => {
    const mergedArray = [...allInteractedAdminUsers, ...allInteractedUsers,...allInteractedCreateCampaigns];
    const allUniqueInteractedRows = Array.from(new Set(mergedArray));

    return allUniqueInteractedRows.map((row: number) => {
        let isCountryAdmin = false;
        let userEnabled = null;
        let campaignCreateEnabled =false;

        if (allInteractedUsers.includes(row)) {
            userEnabled = allSelectedUsers.includes(row);
        } else if (!!allUsersByCountry) {
            const userEnabledObj = allUsersByCountry.items.find(
                (userSummary: UserSummaries) => userSummary.id === row
            );

            if (userEnabledObj && userEnabledObj.countryUserEnabled !== undefined) {
                userEnabled = userEnabledObj.countryUserEnabled;
            }
        }

        if (allInteractedAdminUsers.includes(row)) {
            isCountryAdmin = allSelectedAdminUsers.includes(row);
        } else if (!!allUsersByCountry) {
            const userAdminObj = allUsersByCountry.items.find(
                (userSummary: UserSummaries) => userSummary.id === row
            );

            if (userAdminObj && userAdminObj.countryAdmin !== undefined) {
                isCountryAdmin = userAdminObj.countryAdmin;
            }
        }

        if (allInteractedCreateCampaigns.includes(row)) {
            campaignCreateEnabled = allSelectedCreateCampaigns.includes(row);
        } else if (!!allUsersByCountry) {
            const userAdminObj = allUsersByCountry.items.find(
                (userSummary: UserSummaries) => userSummary.id === row
            );

            if (userAdminObj && userAdminObj.campaignCreateEnabled !== undefined) {
                campaignCreateEnabled = userAdminObj.campaignCreateEnabled;
            }
        }

        return {
            id: row,
            userEnabled,
            countryAdmin: isCountryAdmin,
            campaignCreateEnabled
        };
    });
};

const findGCPRegionId = (
    allGCPRegions: DropdownValueType[],
    selectedGCPRegion: string
) => {
    const gcpregionObject = allGCPRegions.find((gcpReg) => selectedGCPRegion === gcpReg.name);
    return gcpregionObject && gcpregionObject.id;
};

export const actionHandler = (
    resultAction: MutationFunction,
    countryName: string,
    isoCode: string,
    selectedTerritory: DropdownValue,
    countryEnabled: boolean,
    bencmarkingStatus: boolean,
    setIsoCodeError: (state: string) => void,
    setCountryNameError: (state: string) => void,
    allInteractedFrameworks: number[],
    allSelectedFrameworks: number[],
    allGCPRegions: DropdownValueType[],
    selectedGCPRegion: string,
    allInteractedAdminUsers: number[],
    allInteractedUsers: number[],
    allSelectedUsers: number[],
    allUsersByCountry: UserListPage|undefined,
    allSelectedAdminUsers: number[],
    allSelectedCreateCampaigns:number[],
    allInteractedCreateCampaigns:number[],
    countryId?: number,
    isEdit?: boolean
) => {
    const addOptions = {
        variables: {
            data: {
                name: countryName,
                isoCode,
                territory: selectedTerritory.child,
                gcpRegionId: findGCPRegionId(allGCPRegions, selectedGCPRegion),
                countryEnabled,
                benchmarkingEnabled: bencmarkingStatus,
                frameworks: createTempFrameworksFomat(allInteractedFrameworks, allSelectedFrameworks),
                users: createTempUsersFomat(
                    allInteractedAdminUsers,
                    allInteractedUsers,
                    allSelectedUsers,
                    allUsersByCountry,
                    allSelectedAdminUsers,
                    allSelectedCreateCampaigns,
                    allInteractedCreateCampaigns

                )
            }
        }
    };

    const editOptions = {
        variables: {
            data: {
                id: countryId,
                name: countryName,
                isoCode,
                territory: selectedTerritory.child,
                gcpRegionId: findGCPRegionId(allGCPRegions, selectedGCPRegion),
                countryEnabled,
                benchmarkingEnabled: bencmarkingStatus,
                frameworks: createTempFrameworksFomat(allInteractedFrameworks, allSelectedFrameworks),
                users: createTempUsersFomat(
                    allInteractedAdminUsers,
                    allInteractedUsers,
                    allSelectedUsers,
                    allUsersByCountry,
                    allSelectedAdminUsers,
                    allSelectedCreateCampaigns,
                    allInteractedCreateCampaigns
                )
            }
        }
    };

    const options = isEdit ? editOptions : addOptions;

    resultAction(options)
        .then(() => {
            Router.goToCountryManagement('true');
        })
        .catch((err: AxiosError) => {
            if (
                err
                    .toString()
                    .search(
                        'Error: GraphQL error: Error creating country. Details: duplicate key value violates unique constraint'
                    ) !== -1 &&
                err.toString().search('(iso_code)') !== -1 &&
                err.toString().search('already exists.') !== -1
            ) {
                setIsoCodeError(messages.ERROR_COUNTRIES_ISO_DUPLICATE);
            }
            if (
                err
                    .toString()
                    .search(
                        'Error creating country. Details: duplicate key value violates unique constraint'
                    ) !== -1 &&
                err.toString().search('(name)') !== -1 &&
                err.toString().search('already exists.') !== -1
            ) {
                setCountryNameError(messages.ERROR_COUNTRIES_NAME_DUPLICATE);
            }
            console.log(err);
        });
};
