import React from 'react';
import { IdleTimerProvider } from 'react-idle-timer';
import { version } from '../../version';
import { removeJwtFromWhitelist } from '../../services/ApolloClient/client';
import RouteConfig from '../../routes/RouteConfig';


const App: React.FC = () => {
    console.log('version', version);

    return (
        <>
            <IdleTimerProvider
                onIdle={async () => {
                    await removeJwtFromWhitelist();
                }}
                timeout={1000 * 60 * 15}
            />
            <RouteConfig/>
        </>
    );
};

export default App;
