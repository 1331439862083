export type State = {
    user: User;
    campaign: Campaign;
    client: Client;
    globalSettings: GlobalSettings[];
};

export type Client = {
    id?: number;
    name?: string;
};

export type Campaign = {
    id?: string;
    title: string;
    description?: string;
    progress?: number;
    client?: {
        id?: number;
        name?: string;
    };
    stakeholder?: {
        id?: string;
        firstName?: string;
        lastName?: string;
        email?: string;
    };
    manager?: {
        id?: number;
        firstName?: string;
        lastName?: string;
        email?: string;
    };
    members?: {
        id?: number;
        firstName?: string;
        lastName?: string;
        email?: string;
    };
    status?: {
        id?: number;
        name?: string;
    };
    createdAt?: number;
    updatedAt?: number;
};

export type User = {
    id?: number;
    firstName: string;
    lastName: string;
    jobTitle?: string;
    email?: string;
    role?: string;
    clients?: Client[];
    campaigns?: Campaign[];
    updatedAt?: number;
    createdAt?: number;
    countryAdminCountries?: CountryAdminCountries[];
};

export type CountryAdminCountries = {
    id?: number;
    countryId: number;
    name: string;
};

export type GlobalSettings = {
    id?: number;
    name: string;
    payload: GlobalSettingsPayload;
    status: boolean;
    updatedAt: Date;
    createdAt: Date;
}

export type GlobalSettingsPayload = {
    name?: string;
}

type SetUser = {
    type: 'SET_USER';
    value: User;
};

type SetCampaign = {
    type: 'SET_CAMPAIGN';
    value: Campaign;
};

type SetClient = {
    type: 'SET_CLIENT';
    value: Client;
};

type SetGlobalSettings = {
    type: 'SET_GLOBALSETTINGS';
    value: GlobalSettings[];
};

type Actions = SetUser | SetCampaign | SetClient | SetGlobalSettings;

export const initialState: State = {
    user: {
        firstName: '',
        lastName: ''
    },
    campaign: {
        title: ''
    },
    client: {
        name: ''
    },
    globalSettings: [{
        id: undefined,
        name: '',
        status: false,
        payload: {},
        updatedAt: new Date(),
        createdAt: new Date()
    }]
};

export const reducer = (state: State = initialState, action: Actions) => {
    switch (action.type) {
        case 'SET_USER':
            return { ...state, user: action.value };
        case 'SET_CLIENT':
            return { ...state, client: action.value };
        case 'SET_CAMPAIGN':
            return { ...state, campaign: action.value };
        case 'SET_GLOBALSETTINGS':
            return { ...state, globalSettings: action.value };
    }
};
