import { styled } from '@mui/material/styles';

const TruncatedText = styled('div')`
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #2d2d2d;
`;

export default TruncatedText;