import { gql } from 'apollo-boost';

export const USERS_LIST_MODEL = gql`
    query userSummaries(
        $pagination: PaginationInput
        $search: String
        $filters: UserFilters
    ) {
        userSummaries(
            pagination: $pagination
            search: $search
            filters: $filters
        ) {
            items {
                id
                name
                email
                countryIsoCode
                userEnabled
                role
                displayRole
                primaryCountry
            }
            take
            skip
            total
            count
        }
    }
`;
export const COUNTRIES_BYUSER_MODEL = gql`
    query countrySummariesByUser(
        $countryUserId: Int!
        $pagination: PaginationInput
        $search: String
        $filters: CountryFilters
    ) {
        countrySummariesByUser(
            countryUserId: $countryUserId
            pagination: $pagination
            search: $search
            filters: $filters
        ) {
            items {
                id
                name
                isoCode
                countryEnabled
                countryUserEnabled
                campaignCreateEnabled
                countryAdmin
            }
            take
            skip
            total
            count
        }
    }
`;
export const CLIENTS_BYUSER_MODEL = gql`
    query clientSummariesByUser(
        $userId: Int!
        $countryUserOverrides: [CountryUsersOverrides!]
        $pagination: PaginationInput
        $search: String
        $filters: ClientFilters
    ) {
        clientSummariesByUser(
            userId: $userId
            countryUserOverrides: $countryUserOverrides
            pagination: $pagination
            search: $search
            filters: $filters
        ) {
            items {
                clientId
                clientName
                countryIsoCode
                clientUserEnabled
            }
            take
            skip
            total
            count
        }
    }
`;

export const LIST_COUNTRIES = gql`
    query countrySummaries(
        $pagination: PaginationInput
        $search: String
        $filters: CountryFilters

    ) {
        countrySummaries(
            pagination: $pagination
            search: $search
            filters: $filters
        ) {
            items {
                id
                name
                isoCode
                territory
                gcpRegionId
                countryEnabled
                benchmarkingEnabled
                usersCount,
                clientsCount
            }
            take
            skip
            total
            count
        }
    }
`;

export const INTERNAL_DROPDOWN_VALUES = gql`
    query internal_dropdown_values(
        $pagination: PaginationInput
        $search: String
        $filters: CountryFilters
    ) {
        countrySummaries(
            pagination: $pagination
            search: $search
            filters: $filters
        ) {
            items {
                id
                name
                isoCode
                territory
                gcpRegionId
                countryEnabled
                benchmarkingEnabled
                usersCount,
                clientsCount
            }
            take
            skip
            total
            count
        }
        jobGrades
    }
`;

export const LIST_COMPANIES = gql`
    query clientSummaries($pagination: PaginationInput, $search: String, $filters: ClientFilters) {
        clientSummaries(pagination: $pagination, search: $search, filters: $filters) {
            items {
                id
                name
                countryIsoCode
            }
            take
            skip
            total
            count
        }
    }
`;

export const EXTERNAL_DROPDOWN_VALUES = gql`
query dropdown_values($pagination: PaginationInput, $search: String, $filters: CountryFilters) {
    countrySummaries(
        pagination: $pagination
        search: $search
        filters: $filters
    ) {
        items {
            id
            name
        }
    }
}`;

export const UPDATE_USERS = gql`
    mutation admin_updateUsers($data: [UpdateUserEnabledInput!]!) {
        admin_updateUsers(data: $data)
    }
`;

export const UPDATE_USER = gql`
mutation admin_updateUser($data: UpdateUserDataInput!) {
    admin_updateUser(data: $data) {
        id
        firstName
        lastName
        jobTitle
        phone
        role
        isOnboarded
        updatedAt
        createdAt
    }
  }
`;

export const DELETE_USER = gql`
mutation admin_deleteUser($data: DeleteUserInput!) {
    admin_deleteUser(data: $data) {
        message
    }
  }
`;


export const CREATE_USER = gql`
    mutation admin_createUsers($data: [CreateUserInput!]!) {
        admin_createUsers(data: $data){
            id
            email
            firstName
            lastName
            userEnabled
            jobTitle
            role
            isOnboarded
            countryUsers {
                id
                userEnabled
                campaignCreateEnabled
            }
            clientUsers {
                clientId
                userEnabled
            }
        }
    }
`;

export const GET_EXTERNAL_USER = gql`
    query external_admin_user ($query: SearchUserInput!) {
        admin_user (query: $query) {
            id
            firstName
            lastName
            jobTitle
            phone
            email
            userEnabled
            clients {
                id
                name
                country
            }
        }
    }
`;

export const GET_INTERNAL_USER = gql`
    query internal_admin_user ($query: SearchUserInput!) {
        admin_user (query: $query) {
            id
            firstName
            lastName
            jobTitle
            email
            role
            userEnabled
            primaryCountryId
        }
    }
`;
