import {
    Framework,
    FrameworkSummariesByCountry,
    SortHandler,
    UserListPage,
    UserSummaries
} from '../countryTypes';
import { SortDirection, TableCellTypes } from '../../../components/Table/types';
import {convertPermissionLabels} from '../../../utils/helpers';
import { MutationFunction } from '@apollo/client';

export const TabOptions = {
    FRAMEWORKS: 'Frameworks',
    USERS: 'Users'
};

export const tabLabels = [TabOptions.FRAMEWORKS, TabOptions.USERS];

export const createTempFrameworksFomat = (
    allInteractedFrameworks: number[],
    allSelectedFrameworks: number[]
) => {
    return allInteractedFrameworks.map((frId: number) => {
        return {
            id: frId,
            frameworkEnabled: allSelectedFrameworks.includes(frId)
        };
    });
};

export const createTempUsersFomat = (
    allInteractedAdminUsers: number[],
    allInteractedUsers: number[],
    allSelectedUsers: number[],
    allSelectedAdminUsers: number[],
    allSelectedCreateCampaigns:number[],
    allInteractedCreateCampaigns:number[],
    userSummaries?: UserListPage
) => {
    const mergedArray = [...allInteractedAdminUsers, ...allInteractedUsers,...allInteractedCreateCampaigns];
    const allUniqueInteractedRows = Array.from(new Set(mergedArray));

    return allUniqueInteractedRows.map((row: number) => {
        let isCountryAdmin, userEnabled,campaignCreateEnabled;
        if (allInteractedUsers.includes(row)) {
            userEnabled = allSelectedUsers.includes(row);
        } else {
            if (!!userSummaries) {
                const userEnabledObj = userSummaries.items.find(
                    (userSummary: UserSummaries) => userSummary.id === row
                );
                userEnabled = userEnabledObj ? userEnabledObj.countryUserEnabled : null;
            }
        }
        if (allInteractedAdminUsers.includes(row)) {
            isCountryAdmin = allSelectedAdminUsers.includes(row);
        } else {
            if (!!userSummaries) {
                const userAdminObj = userSummaries.items.find(
                    (userSummary: UserSummaries) => userSummary.id === row
                );
                isCountryAdmin = userAdminObj ? userAdminObj.countryAdmin : null;
            }
        }

        if (allInteractedCreateCampaigns.includes(row)) {
            campaignCreateEnabled = allSelectedCreateCampaigns.includes(row);
        } else {
            if (!!userSummaries) {
                const userAdminObj = userSummaries.items.find(
                    (userSummary: UserSummaries) => userSummary.id === row
                );
                campaignCreateEnabled = userAdminObj ? userAdminObj.campaignCreateEnabled : null;
            }
        }

        return {
            id: row,
            userEnabled,
            countryAdmin: isCountryAdmin,
            campaignCreateEnabled
        };
    });
};

export const saveHandler = (
    allInteractedFrameworks: number[],
    allInteractedAdminUsers: number[],
    allInteractedUsers: number[],
    editCountry: MutationFunction,
    setOpenSuccessModal: Function,
    countryId: string,
    countryName: string,
    allSelectedFrameworks: number[],
    allSelectedUsers: number[],
    allSelectedAdminUsers: number[],
    setAllInteractedFrameworks: (frameworkIds: number[]) => void,
    setAllInteractedAdminUsers: (adminUserIds: number[]) => void,
    setAllInteractedUsers:(userIds: number[]) => void,
    allSelectedCreateCampaigns:number[],
    allInteractedCreateCampaigns:number[],
    setAllInteractedCreateCampaigns:(userIds: number[]) => void,
    userSummaries?: UserListPage
) => {
    if (
        allInteractedFrameworks.length === 0 &&
        allInteractedAdminUsers.length === 0 &&
        allInteractedUsers.length === 0 &&
        allInteractedCreateCampaigns.length === 0
    ) {
        return;
    }

    editCountry({
        variables: {
            data: {
                id: countryId,
                name: countryName,
                frameworks: createTempFrameworksFomat(allInteractedFrameworks, allSelectedFrameworks),
                users: createTempUsersFomat(
                    allInteractedAdminUsers,
                    allInteractedUsers,
                    allSelectedUsers,
                    allSelectedAdminUsers,
                    allSelectedCreateCampaigns,
                    allInteractedCreateCampaigns,
                    userSummaries
                )
            }
        }
    })
        .then(() => {
            setAllInteractedFrameworks([]);
            setAllInteractedAdminUsers([]);
            setAllInteractedUsers([]);
            setAllInteractedCreateCampaigns([]);
            setOpenSuccessModal(true);
        })
        .catch((err) => {
            console.log(err);
        });
};

export const setTableRowsWithFramework = (
    allSelectedFrameworks: number[],
    handleFrameworksToggleChange: (cellID: number) => void,
    frameworkSummaries?: FrameworkSummariesByCountry,
) => {
    return (
        frameworkSummaries &&
        frameworkSummaries.items.map((item: Framework) => {
            return {
                tableCells: [
                    {
                        cellName: item.name,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: item.countryOwner,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: item.proposition,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        isCellSortable: false,
                        cellType: 'boolean' as TableCellTypes,
                        toggleLabels: {
                            trueLabel: 'Enabled',
                            falseLabel: 'Disabled'
                        },
                        currentState: allSelectedFrameworks.includes(item.frameworkId),
                        actionFunc: () => handleFrameworksToggleChange(item.frameworkId),
                        cellID: item.frameworkId
                    }
                ]
            };
        })
    );
};

export const setTableRowsWithUser = (
    allSelectedAdminUsers: number[],
    handleUsersAdminToggleChange: (cellID: number) => void,
    allSelectedUsers: number[],
    handleUsersEnabledToggleChange: (cellID: number) => void,
    allSelectedCreateCamapigns:number[],
    handleUsersCreateCampaignsToggle:(cellID: number) => void,
    userSummaries?: UserListPage
) => {
    return (
        userSummaries &&
        userSummaries.items.map((item: UserSummaries) => {
            return {
                tableCells: [
                    {
                        cellName: item.name,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: item.email,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: convertPermissionLabels(item.displayRole),
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        isCellSortable: false,
                        cellType: 'boolean' as TableCellTypes,
                        toggleLabels: {
                            trueLabel: 'Enabled',
                            falseLabel: 'Disabled'
                        },
                        currentState: allSelectedCreateCamapigns.includes(item.id),
                        actionFunc: () => handleUsersCreateCampaignsToggle(item.id),
                        cellID: item.id,
                        //hideCell: item.role === 'EXTERNAL'
                    },
                    {
                        isCellSortable: false,
                        cellType: 'boolean' as TableCellTypes,
                        toggleLabels: {
                            trueLabel: 'Enabled',
                            falseLabel: 'Disabled'
                        },
                        currentState: allSelectedAdminUsers.includes(item.id),
                        actionFunc: () => handleUsersAdminToggleChange(item.id),
                        cellID: item.id,
                        hideCell: item.role === 'EXTERNAL'
                    },
                    {
                        isCellSortable: false,
                        cellType: 'boolean' as TableCellTypes,
                        toggleLabels: {
                            trueLabel: 'Enabled',
                            falseLabel: 'Disabled'
                        },
                        currentState: allSelectedUsers.includes(item.id),
                        actionFunc: () => handleUsersEnabledToggleChange(item.id),
                        cellID: item.id,
                        hideCell: item.role === 'EXTERNAL'
                    }
                ]
            };
        })
    );
};

export const getFrameworksTableHeader = (
    createSortHandler: SortHandler
) => [
    {
        headerName: 'Framework name',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: ()=>createSortHandler('name')
    },
    {
        headerName: 'Country owner',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: ()=>createSortHandler('countryOwner')
    },
    {
        headerName: 'Proposition',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: ()=>createSortHandler('proposition')
    },
    {
        headerName: 'Framework access',
        isHeaderSortable: false
    }
];

export const getUsersTableHeader = (
    createSortHandler: SortHandler
) => [
    {
        headerName: 'User name',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: ()=>createSortHandler('name')
    },
    {
        headerName: 'Email address',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: ()=>createSortHandler('email')
    },
    {
        headerName: 'Permission',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: ()=>createSortHandler('role')
    },
    {
        headerName: 'Create campaigns',
        isHeaderSortable: false
    },
    {
        headerName: 'Country admin',
        isHeaderSortable: false
    },
    {
        headerName: 'User access',
        isHeaderSortable: false
    }
];
