/* istanbul ignore file */
import { AuthProviderProps } from 'react-oidc-context';

const AuthConfig = {
    client_id: window.__ENV__.REACT_APP_AUTH_CLIENT_ID,
    authority: window.__ENV__.REACT_APP_AUTH_AUTHORITY,
    redirect_uri: window.__ENV__.REACT_APP_AUTH_ADMIN_REDIRECT_URI,
    post_logout_redirect_uri: window.__ENV__.REACT_APP_AUTH_ADMIN_POST_LOGOUT_URL,
    includeIdTokenInSilentSignout: true,
    includeIdTokenInSilentRenew: true,
    revokeTokensOnSignout: true,
    response_type: 'code',
    scope: 'openid profile email',
    onSigninCallback: () => {
        window.history.replaceState(
            {},
            document.title,
            window.location.pathname
        );
    },

} as AuthProviderProps;

export default AuthConfig;
