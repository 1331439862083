import React, { useReducer } from 'react';

import { initialState, State, reducer } from './reducer';

type ContextProps = {
    state: State;
    dispatch: (type: any) => void;
};

export const UserStore = React.createContext({} as any as ContextProps);

export function UserStoreProvider(props: any) {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <UserStore.Provider value={{ state, dispatch }}>
            {props.children}
        </UserStore.Provider>
    );
}
