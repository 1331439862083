import React, {memo} from 'react';
import { styled } from '@mui/material/styles';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import { MenuItem } from '@mui/material';
import CustomSelect from './CustomSelect';
import checkIcon from '../../../assets/images/check.svg';

import { SingleSelectionProps, DropdownValueType } from './types';
import { convertPermissionLabels } from '../../../utils/helpers';

const DropdownSingleSelection: React.FC<SingleSelectionProps> = ({
    labelText,
    isDisabled,
    selectedValue,
    setValue,
    placeholder,
    allValues,
    errorMessage,
    width,
    className
}) => {

    return (
        <div className={className}>
            <DropdownLabel>{labelText}</DropdownLabel>
            <Dropdown
                className={'dropdown-child'}
                disabled={isDisabled}
                native={false}
                variant="outlined"
                displayEmpty
                value={selectedValue}
                onChange={(e: any) => {
                    typeof e.target.value === 'string' && setValue(e.target.value);
                }}
                width={width}
            >
                {placeholder && (
                    <DropdownMenuItem key={0} value={''} disabled>
                        {placeholder}
                        <img src={checkIcon} alt="icon for dropdown checkmark" />
                    </DropdownMenuItem>
                )}
                {allValues && allValues.map((item: DropdownValueType, index: number) => {
                    return (
                        <DropdownMenuItem key={index} value={item.value?item.value:item.name}>
                            {convertPermissionLabels(item.name)}
                            <img src={checkIcon} alt="icon for dropdown checkmark" />
                        </DropdownMenuItem>
                    );
                })}
            </Dropdown>
            {!!errorMessage && 
                <FormHelperText style={{color: 'red'}} error={errorMessage !== '' ? true : false}>
                    {errorMessage}
                </FormHelperText>
            }
        </div>
    );
    
};

const DropdownLabel = styled(InputLabel)`
    margin-bottom: 0.5em;
    margin-left: 0.3em;
    font-size: 14px !important;
`;

const Dropdown = styled(CustomSelect)<{
    width?: string;
}>`
    width: ${(props) => (props.width ? props.width : '14em')};
    margin-right: 1em;
    img {
        display: none;
    }
`;

const DropdownMenuItem = styled(MenuItem)`
    && {
        font-family: 'CustomHelveticaNeue';
        &&.MuiListItem-root {
            max-width: 500px;
        }
        &&.MuiListItem-root.Mui-selected {
            overflow-wrap: break-word;
            background-color: #f2f3f3;
            img {
                display: block;
            }
        }
        img {
            display: none;
        }
        display: flex;
        justify-content: space-between;
    }
`;

export default memo(DropdownSingleSelection);
