
export const getLocalStorageItem = (name: string): string | undefined => {
    const result = localStorage.getItem(name);
    if (result === null) {
        return undefined;
    }
    return result;
};

export const setLocalStorageItem = (name: string, value: string | undefined) => {
    if (value !== undefined) {
        localStorage.setItem(name, value);
    }
};

export const parseJwt = (token: string | null) => {
    if (!token) {
        return null;
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

    return JSON.parse(jsonPayload);
};
