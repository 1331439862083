import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';

const CustomSelect = styled(Select)`
    height: 2.375em;
    .MuiOutlinedInput-input {
        padding: 10px;
    }
`;

export default CustomSelect;
