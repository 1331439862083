import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import notificationIcon from '../../assets/images/notificationsIcon.svg';
import RedButton from '../../components/base/Buttons/RedButton';
import SuccessModal from '../../components/base/SuccessModal/SuccessModal';
import DropdownSingleSelection from '../../components/base/Dropdowns/DropdownSingleSelection';
import Tabs from '../../components/Tabs/Tab';
import { Grid, TextField, Typography } from '@mui/material';
import { Alert } from '@mui/material';
import {
    TabOptions,
    notificationRangeList,
    notificationTypesList,
    tabLabels,
} from './notificationHelpers';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as messages from '../../utils/Constant/messages';
import {
    CREATE_NOTIFICATION,
    GET_CLIENTS,
    GET_COUNTRIES,
    GET_ALL_USERS,
    GET_USERS_BY_CLIENT,
    GET_USERS_BY_COUNTRY,
    TRIGGER_OPEN_CAMPAIGNS_NOTIFICATION,
} from './notificationQueries';

const NotificationHub: React.FC = () => {
    const [currentTab, setCurrentTab] = useState<string>(
        TabOptions.OPENCAMPAIGNS
    );
    const getCountries = useQuery<any>(GET_COUNTRIES);
    const getClients = useQuery<any>(GET_CLIENTS);
    const getAllUsers = useQuery<any>(GET_ALL_USERS);
    const [usersByCompany, setUsersByCompany] = useState<any>();
    const [usersByCountry, setUsersByCountry] = useState<any>();
    const [getUsersByClient] = useLazyQuery(GET_USERS_BY_CLIENT, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            setUsersByCompany(res.usersByClient);
        },
        onError: (err) => {
            console.log(err);
        },
    });
    const [getUsersByCountry] = useLazyQuery(GET_USERS_BY_COUNTRY, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            setUsersByCountry(res.usersByCountry);
        },
        onError: (err) => {
            console.log(err);
        },
    });
    const [createNotification] = useMutation(CREATE_NOTIFICATION);
    const [triggerOpenCampaignsNotification] = useMutation(
        TRIGGER_OPEN_CAMPAIGNS_NOTIFICATION
    );
    const [open, setOpen] = React.useState(false);
    const [notificationType, setNotificationType] = useState<any>('INFO');
    const [notificationRange, setNotificationRange] = useState<string>('ALL');
    const [notificationMessage, setNotificationMessage] = useState<string>('');
    const [notificationCountry, setNotificationCountry] = useState<any>();
    const [notificationCompany, setNotificationCompany] = useState<any>();
    const [interaction, setInteraction] = useState<boolean>(true); // Var tracking user changes on the page.
    const [openSuccessModal, setOpenSuccessModal] = useState(false);

    const handleChangeTabs = (newTab: string) => {
        if (newTab === TabOptions.CUSTOMNOTIFICATIONS) {
            setInteraction(false);
        } else {
            setInteraction(true);
        }
        setCurrentTab(newTab);
    };

    const triggerNotification = async () => {
        if (currentTab === TabOptions.CUSTOMNOTIFICATIONS) {
            if (notificationRange === 'SPECIFIC COMPANY') {
                const selectedCompany =
                    getClients.data.clientSummaries.items.find(
                        (client: any) => client.name === notificationCompany
                    );

                await getUsersByClient({
                    variables: {
                        clientId: selectedCompany.id,
                    },
                });
            } else if (notificationRange === 'SPECIFIC COUNTRY') {
                const selectedCountry = getCountries.data.countries.find(
                    (country: any) => country.name === notificationCountry
                );
                await getUsersByCountry({
                    variables: {
                        countryId: selectedCountry.id,
                    },
                });
            }
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDisagree = () => {
        setOpen(false);
    };

    const handleAgree = () => {
        setOpen(false);
        if (currentTab === TabOptions.CUSTOMNOTIFICATIONS) {
            confirmCustomTriggerNotification();
        }
        if (currentTab === TabOptions.OPENCAMPAIGNS) {
            confirmOpenCampaignsTriggerNotification();
        }
    };

    const confirmCustomTriggerNotification = async () => {
        try {
            if (notificationRange === 'SPECIFIC COMPANY') {
                usersByCompany.map((user: any) => {
                    createNotification({
                        variables: {
                            input: {
                                userId: user.id,
                                notificationType: notificationType,
                                notificationCategory: 'DEFAULT',
                                notificationMessage: notificationMessage,
                                wasRead: false,
                            },
                        },
                    });
                });
            } else if (notificationRange === 'SPECIFIC COUNTRY') {
                usersByCountry.items.map((user: any) => {
                    createNotification({
                        variables: {
                            input: {
                                userId: user.id,
                                notificationType: notificationType,
                                notificationCategory: 'DEFAULT',
                                notificationMessage: notificationMessage,
                                wasRead: false,
                            },
                        },
                    });
                });
            } else {
                getAllUsers.data.users.map((user: any) => {
                    createNotification({
                        variables: {
                            input: {
                                userId: user.id,
                                notificationType: notificationType,
                                notificationCategory: 'DEFAULT',
                                notificationMessage: notificationMessage,
                                wasRead: false,
                            },
                        },
                    });
                });
            }
            setOpenSuccessModal(true);
            setNotificationMessage('');
            setNotificationRange('ALL');
            setNotificationType('INFO');
        } catch (error) {
            console.log(error);
        }
    };

    const confirmOpenCampaignsTriggerNotification = async () => {
        try {
            triggerOpenCampaignsNotification();
            setOpenSuccessModal(true);
        } catch (error) {
            console.log(error);
        }
    };

    // Set the save button to disabled if no changes have been made.
    const isDisabled = interaction ? false : true;

    return (
        <>
            <MainWrapper>
                <LinkWrapper />
                <NameAndCTAs>
                    <h1>Notification Hub</h1>
                </NameAndCTAs>
                <Tabs
                    tabLabels={tabLabels}
                    activeTab={currentTab}
                    action={handleChangeTabs}
                />
                {currentTab === TabOptions.OPENCAMPAIGNS && (
                    <>
                        <Grid container style={{ paddingTop: '50px' }}>
                            <Grid item xs={9}>
                                <Typography>
                                    This notification will be triggered for all
                                    users with campaigns open for more than 60
                                    days.
                                </Typography>
                            </Grid>

                            <Grid item xs={2}>
                                <SaveButton
                                    disabled={isDisabled}
                                    onClick={triggerNotification}
                                    style={{ width: '100%', height: '100%' }}
                                >
                                    <img
                                        src={notificationIcon}
                                        alt="add frm icon"
                                    />
                                    Trigger Notification
                                </SaveButton>
                            </Grid>

                            <Grid item xs={4} style={{ paddingTop: '20px' }}>
                                <Typography
                                    style={{
                                        whiteSpace: 'pre-wrap',
                                        paddingBottom: '10px',
                                    }}
                                >
                                    Notification Sample
                                </Typography>
                                <Alert
                                    style={{
                                        width: '350px',
                                    }}
                                    severity="warning"
                                    onClose={(event: any) => {
                                        event.stopPropagation();
                                    }}
                                >
                                    <Typography
                                        style={{
                                            width: '100%',
                                            whiteSpace: 'pre-wrap',
                                        }}
                                    >
                                        Campaign CAMPAIGN_TITLE has been open
                                        for X days, please take action to
                                        complete it.
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                        style={{
                                            display: 'block',
                                            textAlign: 'right',
                                            width: '100%',
                                            marginTop: '5px',
                                        }}
                                    >
                                        {new Date(Date.now()).toLocaleString(
                                            'en-GB',
                                            {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit',
                                            }
                                        )}
                                    </Typography>
                                </Alert>
                            </Grid>
                        </Grid>
                    </>
                )}
                {currentTab === TabOptions.CUSTOMNOTIFICATIONS && (
                    <>
                        <Grid container style={{ paddingTop: '50px' }}>
                            <Grid item xs={2}>
                                <DropdownSingleSelection
                                    labelText="Type"
                                    isDisabled={false}
                                    selectedValue={notificationType}
                                    setValue={setNotificationType}
                                    allValues={notificationTypesList}
                                    width="95%"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <DropdownSingleSelection
                                    labelText="Notification Range"
                                    isDisabled={false}
                                    selectedValue={notificationRange}
                                    setValue={setNotificationRange}
                                    allValues={notificationRangeList}
                                    width="95%"
                                />
                            </Grid>

                            <Grid item xs={2}>
                                {notificationRange === 'SPECIFIC COUNTRY' && (
                                    <DropdownSingleSelection
                                        labelText="Country List"
                                        isDisabled={false}
                                        selectedValue={notificationCountry}
                                        setValue={setNotificationCountry}
                                        allValues={getCountries.data.countries}
                                        width="95%"
                                    />
                                )}
                                {notificationRange === 'SPECIFIC COMPANY' && (
                                    <DropdownSingleSelection
                                        labelText="Company List"
                                        isDisabled={false}
                                        selectedValue={notificationCompany}
                                        setValue={setNotificationCompany}
                                        allValues={
                                            getClients.data.clientSummaries
                                                .items
                                        }
                                        width="95%"
                                    />
                                )}
                            </Grid>
                            <Grid item xs={3}>
                                {notificationRange === 'SPECIFIC COUNTRY' && (
                                    <Typography style={{ paddingTop: '20px' }}>
                                        *Only members of that specific COUNTRY
                                        will receive this notification.
                                    </Typography>
                                )}
                                {notificationRange === 'SPECIFIC COMPANY' && (
                                    <Typography style={{ paddingTop: '20px' }}>
                                        *Only members of that specific COMPANY
                                        will receive this notification.
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item xs={2}>
                                <SaveButton
                                    disabled={isDisabled}
                                    onClick={triggerNotification}
                                    style={{ width: '100%', height: '100%' }}
                                >
                                    <img
                                        src={notificationIcon}
                                        alt="add frm icon"
                                    />
                                    Trigger Notification
                                </SaveButton>
                            </Grid>
                            <Grid item xs={4} style={{ paddingTop: '20px' }}>
                                <TextField
                                    label="Message"
                                    variant="outlined"
                                    rows={4}
                                    value={notificationMessage}
                                    onChange={(event: any) => {
                                        setNotificationMessage(
                                            event.target.value
                                        );
                                        if (event.target.value.length > 10) {
                                            setInteraction(true);
                                        } else {
                                            setInteraction(false);
                                        }
                                    }}
                                    multiline
                                    style={{ width: '97%' }}
                                />
                            </Grid>

                            <Grid item xs={4} style={{ paddingTop: '20px' }}>
                                <Typography
                                    style={{
                                        whiteSpace: 'pre-wrap',
                                        paddingBottom: '10px',
                                    }}
                                >
                                    Notification Sample
                                </Typography>
                                <Alert
                                    style={{
                                        width: '350px',
                                    }}
                                    severity={notificationType.toLowerCase()}
                                    onClose={(event: any) => {
                                        event.stopPropagation();
                                    }}
                                >
                                    <Typography
                                        style={{
                                            width: '100%',
                                            whiteSpace: 'pre-wrap',
                                        }}
                                    >
                                        {notificationMessage}
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                        style={{
                                            display: 'block',
                                            textAlign: 'right',
                                            width: '100%',
                                            marginTop: '5px',
                                        }}
                                    >
                                        {new Date(Date.now()).toLocaleString(
                                            'en-GB',
                                            {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit',
                                            }
                                        )}
                                    </Typography>
                                </Alert>
                            </Grid>
                        </Grid>
                    </>
                )}
            </MainWrapper>

            <SuccessModal
                openState={openSuccessModal}
                hideDuration={6000}
                setOpen={setOpenSuccessModal}
                message={messages.NOTIFICATION_SUCCESS_MESSAGE}
            />

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {'Trigger Notification?'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to trigger this notification? This
                        action cannot be reverted.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDisagree} color="primary">
                        Disagree
                    </Button>
                    <Button onClick={handleAgree} color="primary" autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const SaveButton = styled(RedButton)`
    font-size: 16px !important;
    img {
        padding-right: 10px;
    }
`;
const MainWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

const NameAndCTAs = styled('div')`
    display: flex;
    justify-content: space-between;
    min-height: 100px;
    padding: 5px 0;

    h2 {
        margin: 0;
    }
`;

const LinkWrapper = styled('div')`
    height: 46px;
`;

const SubHeading = styled('div')`
    padding: 0px;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: -6px;
`;

const Divider = styled('div')`
    border-top: 2px solid #808080;
    padding-bottom: 5px;
`;

export default NotificationHub;
