import { styled } from '@mui/material/styles';

const BoldText = styled('div')`
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2d2d2d;
`;

export default BoldText;
