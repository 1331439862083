import React from 'react';
import { styled } from '@mui/material/styles';

import { Props } from './types';
import Button from '@mui/material/Button';

const Toggle: React.FC<Props> = ({
    labels,
    state,
    actionFunc,
    cellID,
    className,
    disabled
}) => {
    return (
        <ToggleWrapper className={className} role="switch">
            <ToggleButtonTrue
                disabled={disabled}
                enabled={state}
                onClick={(e: any) => {
                    !state && actionFunc(e, true, cellID);
                }}
            >
                {labels.trueLabel}
            </ToggleButtonTrue>
            <ToggleButtonFalse
                disabled={disabled}
                enabled={!state}
                onClick={(e: any) => {
                    !!state && actionFunc(e, false, cellID);
                }}
            >
                {labels.falseLabel}
            </ToggleButtonFalse>
        </ToggleWrapper>
    );
};

const ToggleWrapper = styled('div')`
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 48px;
    display: flex;
    min-height: 32px;
    width: fit-content;
    padding: 2px;
`;

type ToggleButtonProps = {
    disabled?: boolean;
    enabled?: boolean;
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const ToggleButtonTrue: React.FC<ToggleButtonProps> = (props) => (
    <Button
        disabled={props.disabled}
        variant="contained"
        disableElevation
        sx={{
            borderRadius: '87px',
            backgroundColor: props.enabled ? '#219653' : 'transparent',
            color: props.enabled ? '#ffffff' : '#AFAFAF',
            cursor: props.enabled ? 'default' : 'pointer',
            padding: '2px 11px',
            fontSize: '13px',
            fontWeight: 500,
            lineHeight: '1.6',
            border: 'none',
            '&:hover': {
                backgroundColor: props.enabled ? '#219653' : 'transparent',
            },
            '&:disabled': {
                backgroundColor: props.enabled ? 'rgba(0, 0, 0, 0.12)' : 'transparent'
            }
        }}
        onClick={props.onClick}
        children={props.children}
    />
);

const ToggleButtonFalse: React.FC<ToggleButtonProps> = (props) => (
    <Button
        disabled={props.disabled}
        variant="contained"
        disableElevation
        sx={{
            borderRadius: '87px',
            backgroundColor: props.enabled ? '#818181' : '#ffffff00',
            color: props.enabled ? '#ffffff' : '#AFAFAF',
            cursor: props.enabled ? 'default' : 'pointer',
            padding: '2px 11px',
            fontSize: '13px',
            fontWeight: '500',
            lineHeight: '1.6',
            border: 'none',
            '&:disabled': {
                backgroundColor: props.enabled ? 'rgba(0, 0, 0, 0.12)' : 'transparent'
            }
        }}
        onClick={props.onClick}
        children={props.children}
    />
);

export default Toggle;
