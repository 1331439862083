import { CountryListPage, CountrycsvParams, ClientCSVParams  } from '../userTypes';
import axios from 'axios';
import {createTempClients, createTempCountries} from '../userHelpers';
import { downloadFileFromData } from '../../../utils/helpers';
import { MutationFunction } from '@apollo/client';

export const saveHandler = (
    allInteractedIsAdmin: number[],
    allInteractedCreateCampaigns: number[],
    allInteractedCountryUserEnabled: number[],
    allInteractedClientUserEnabled: number[],
    editUser: MutationFunction,
    userId: string,
    allSelectedIsAdmin: number[],
    allSelectedCreateCampaigns: number[],
    allSelectedCountryUserEnabled: number[],
    allSelectedClientUserEnabled: number[],
    setOpenSuccessModal:Function,
    setAllInteractedIsAdmin:(adminIds: number[]) => void,
    setAllInteractedCreateCampaigns:(createCampIds: number[]) => void,
    setAllInteractedCountryUserEnabled:(userEnabledIds: number[]) => void,
    setAllInteractedClientUserEnabled: (clientIds: number[]) => void,
    countrySummaries?: CountryListPage
) => {
    if (
        allInteractedIsAdmin.length === 0 &&
        allInteractedCreateCampaigns.length === 0 &&
        allInteractedCountryUserEnabled.length === 0 &&
        allInteractedClientUserEnabled.length === 0
    ) {
        return;
    }

    editUser({
        variables: {
            data: {
                id: parseInt(userId),
                clientUsers: createTempClients(allInteractedClientUserEnabled, allSelectedClientUserEnabled),
                countryUsers: createTempCountries(
                    allInteractedIsAdmin,
                    allInteractedCreateCampaigns,
                    allInteractedCountryUserEnabled,
                    allSelectedIsAdmin,
                    allSelectedCreateCampaigns,
                    allSelectedCountryUserEnabled,
                    countrySummaries
                )
            }
        }
    })
        .then(() => {
            setAllInteractedIsAdmin([]);
            setAllInteractedCreateCampaigns([]);
            setAllInteractedCountryUserEnabled([]);
            setAllInteractedClientUserEnabled([]);
            setOpenSuccessModal(true);
        })
        .catch((err) => {
            console.log(err);
        });
};

export const exportCountrySummariesByUserCSV = (setSpinner: Function, params: CountrycsvParams) => {
    setSpinner(true);
    const tkn = localStorage.getItem('pid-token');
    const clientLocal = localStorage.getItem('client');
    let clientUUID = null;
    if (clientLocal) {
        const clientParsed = JSON.parse(clientLocal);
        clientUUID = clientParsed.id;
    }
    axios
        .post(
            window.__ENV__.REACT_APP_APP_URL + '/admin/user/'+params.userId+'/country/summaries/csv',
            { countryEnabled: params.countryEnabled, usercountryUserEnabledEnabled: params.countryUserEnabled },
            {
                headers: {
                    'pearl-client-uuid': clientUUID,
                    Authorization: `Bearer ${tkn}`
                }
            }
        )
        .then((response) => {
            setSpinner(false);
            downloadFileFromData(`data:text/csv;charset=utf-8,${response.data}`, 'CountriesByUser Report.csv');
        })
        .catch((error) => {
            setSpinner(false);
            console.log(error);
        });
};

export const exportCompanySummariesByUserCSV = (setSpinner: Function, params: ClientCSVParams) => {
    setSpinner(true);
    const tkn = localStorage.getItem('pid-token');
    const clientLocal = localStorage.getItem('client');
    let clientUUID = null;
    if (clientLocal) {
        const clientParsed = JSON.parse(clientLocal);
        clientUUID = clientParsed.id;
    }
    axios
        .post(
            window.__ENV__.REACT_APP_APP_URL + '/admin/user/'+params.userId+'/client/summaries/csv',
            { userEnabled: params.userEnabled },
            {
                headers: {
                    'pearl-client-uuid': clientUUID,
                    Authorization: `Bearer ${tkn}`
                }
            }
        )
        .then((response) => {
            setSpinner(false);
            downloadFileFromData(`data:text/csv;charset=utf-8,${response.data}`, 'CompaniesByUser Report.csv');
        })
        .catch((error) => {
            setSpinner(false);
            console.log(error);
        });
};
