import { styled } from '@mui/material/styles';

const Text = styled('span')`
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2d2d2d;
`;

export default Text;
