import { DropdownValue } from './types';
import { Popover } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    Divider
} from '@mui/material';
import Text from '../../../components/base/Text/Text';
export const groupByParent = (dataSet: DropdownValue[] = []) => {
    const dataMap = dataSet.reduce(
        (acc: { [key in string]: string[] }, { child, parent }) => {
            if (acc[parent]) {
                acc[parent].push(child);
            } else {
                acc[parent] = [child];
            }
            return acc;
        },
        {}
    );
    return Object.entries(dataMap).map(([key, name]) => ({
        parent: key,
        children: name
    }));
};

const PREFIX = 'CustomDropdown';

export const classes = {
    root: `${PREFIX}-root`,
    listSection: `${PREFIX}-listSection`,
    listItem: `${PREFIX}-listItem`,
    wrapper: `${PREFIX}-wrapper`
}

export const DropdownPopover = styled(Popover)`
    max-height: '422px';
`;

export const MenuButtonContainer = styled('button')<{ background?: string }>`
    margin-right: 1em;
    width: 12em;
    cursor: pointer;
    display:flex;
    justify-content: space-between;
    background-color:#ffffff;
    border:0.3px solid threedface;
    border-radius: 5px;
    padding 5px;
    &:focus {
        border: 1px solid #2d2d2d;
        outline-color: #2d2d2d;
    }
    &:hover {
        border: 1px solid #2d2d2d;
        background: ${props => (props.background ? props.background : 'none')};
    }
`;
export const TextContainer = styled(Text)<{ color?: string }>`
    padding:4px;
`;
export const ShowAllButton = styled('span')`
    font-size: 14px;
    color: #575757;
    cursor: pointer;

    img {
        filter: invert(32%) sepia(0%) saturate(1952%) hue-rotate(134deg) brightness(93%) contrast(80%);
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        margin-bottom: -8px;
    }
`;
export const ShowLessButton = styled('span')`
    font-size: 14px;
    color: #575757;
    cursor: pointer;

    img {
        filter: invert(32%) sepia(0%) saturate(1952%) hue-rotate(134deg) brightness(93%) contrast(80%);
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        margin-bottom: -7px;
    }
`;
export const FormControlUppercaseLabelWrapper = styled('span')`
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: normal;
    color: #2d2d2d;
`;
export const ImgContainer = styled('img')<{
    padding?: string;
}>`
    margin-right:1px;
    padding: ${props => (props.padding ? props.padding : '6px')};
`;

export const CustomDivider = styled(Divider)`
    margin:0 !important;
`;
