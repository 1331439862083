export const NODATASCREENTITLE='No Result Found';
export const NODATASCREENDESCRIPTION='Please check filters or that all search words are spelled correctly.';
//----------------------------------------------------------POPUP SUCCESS/ERRORS(Action completion messages)----------------------------------------------------------------
export const FRAMEWORK_SUCCESS_MESSAGE='Framework has been saved successfully';
export const FRAMEWORKS_SUCCESS_MESSAGE='Frameworks have been saved successfully';
export const FRAMEWORKS_FAILURE_MESSAGE='Failed to save Framework, please verify data and retry.';
export const FRAMEWORKS_DELETE_FAILURE_MESSAGE='Framework has been deleted successfully'
export const USER_SUCCESS_MESSAGE='User has been saved successfully';
export const USER_PASSWORD_RESET_SUCCESS_MESSAGE='Your password reset was requested. Please check your email.';
export const USERS_SUCCESS_MESSAGE='Users have been saved successfully';
export const USERS_DELETED_SUCCESS_MESSAGE='Users have been deleted successfully';
export const USERS_FAILURE_MESSAGE='User cannot be disabled as they are a relationship manager for a company.';
export const COMPANY_SUCCESS_MESSAGE='Company has been saved successfully';
export const COMPANY_DELETION_MESSAGE='Company has been deleted successfully';
export const COMPANIES_SUCCESS_MESSAGE='Companies have been saved successfully';
export const COUNTRY_SUCCESS_MESSAGE='Country has been saved successfully';
export const COUNTRIES_SUCCESS_MESSAGE='Countries have been saved successfully';
export const SETTINGS_SUCCESS_MESSAGE='Settings have been saved successfully';
export const SETTINGS_PROMPT_MESSAGE ='Making changes to Global Settings will effect site wide changes that could affect user access.';
export const FRAMEWORK_SETTINGS_PROMPT_MESSAGE ='Making changes to Framework Settings will effect campaigns using this framework, and could affect the use access.';
export const COMPANY_PERMANET_DELETION_PROMPT_MESSAGE ='Deleting company permanently, can not reactive company afterwards. It will delete the company permanently from the system.';
export const COUNTRY_DELETE_SUCCESS ='Country has been deleted successfully';
export const NOTIFICATION_SUCCESS_MESSAGE='Notification triggered successfully';
export const ADMIN_USER_DETAILS_CHANGE_ERROR_MESSAGE='As an admin user, you are not able to modify your own access, to ensure a segregation of duties. Please contact another admin to manage your access if required.';
//----------------------------------------------------------FORM ERRORS----------------------------------------------------------------
export const ERROR_USERS_EMAIL_DUPLICATE = 'User with this email already exists';
export const ERROR_USERS_EMAIL_WRONG_DOMAIN = 'PWC user can not have role external';
export const ERROR_USERS_INTERNAL_EMAIL_WRONG_DOMAIN = 'Wrong email format (must be pwc email)';
export const ERROR_USERS_EMAIL_INVALID = 'Invalid email address';
export const ERROR_USERS_PASSWORD_SPECIAL_CHARACTERS = 'Password must have all of the following: upper case, lower case, numbers and symbols';
export const ERROR_USERS_PASSWORD_NUMBER_CHARACTERS = 'Password must be at least 8 characters';
export const ERROR_COMPANIES_PRID_NUMBERL_CHARACTERS = 'PR ID should has 20 characters or less';
export const ERROR_COMPANIES_PRID_SPECIAL_CHARACTERS = 'PR ID should contain only numbers';
export const ERROR_COUNTRIES_ISO_DUPLICATE = 'This ISO code already exist';
export const ERROR_COUNTRIES_NAME_DUPLICATE = 'Country with this name already exist';
export const ERROR_USER_RM_DISABLED = 'User cannot be disabled if he is the owner of the client';
export const ERROR_COMPANY_ACCESS_NOT_AVAILABLE = 'The user does not have access to this company'
export const ERROR_FRAMEWORK_SHORTNAME = 'Invalid short names';
export const ERROR_DUPLICATE_SHORTNAME = 'Duplicate shortnames for Domain/Campability';

