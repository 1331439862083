import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';
import { debounce as _debounce } from 'lodash';
import { useParams } from 'react-router';
import { styled } from '@mui/material/styles';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import TextField from '@mui/material/TextField';
import { CircularProgress } from '@mui/material';
import closeIcon from '../../../assets/images/icon-close-black.svg';

import RedButton from '../../../components/base/Buttons/RedButton';
import SearchField from '../../../components/base/SearchField/SearchField';
import TransparentButtonInverted from '../../../components/base/Buttons/TransparentButtonInverted';
import Toggle from '../../../components/base/Toggle/Toggle';
import InputTextField from '../../../components/base/Input/TextField';
import Tabs from '../../../components/Tabs/Tab';
import DropdownSingleSelection from '../../../components/base/Dropdowns/DropdownSingleSelection';

import NewTable from '../../../components/Table/Table';
import { UserStore } from '../../../context/user-store';

import iconSave from '../../../assets/images/icon-save.svg';

import { TableRow } from '../../../components/Table/types';
import { DropdownValueType } from '../../../components/base/Dropdowns/types';

import {
    Country,
    FrameworkFilters,
    FrameworkSummariesByCompany,
    Framework,
    UserListPage,
    UserSummaries,
    UsersFilters,
    AddEditCompanyProps,
    CompanyOrder
} from '../companyTypes';
import { ChangeToggle } from '../../../components/base/Toggle/types';

import {
    disabledEnabledDropdownValues,
    adminDropdownValues,
    adminAddDropdownValues,
    searchCompanyInCES
} from '../companyHelpers';
import Router from '../../../routes/router';
import {
    ADD_COMPANY,
    ALL_FRAMEWORKS,
    ALL_USERS,
    COMPANY_DETAILS,
    DROPDOWN_VALUES,
    EDIT_COMPANY,
    FRAMEWORK_SUMMARIES_BY_COMPANY_MODEL,
    USERS_BY_COMPANY_MODEL,
    PROPOSITIONS,
    CREATE_NOTIFICATION
} from '../companyQueries';
import {
    benchmarkingToggleLabels,
    statusToggleLabels,
    tabLabels,
    convertUsersToDropdownType,
    findByName,
    findById,
    getHeaderFrameworksData,
    getUsersTableHeader,
    setFrameworkTableRows,
    setUsersTableRows,
    TabOptions,
    actionHandler
} from './addEditCompanyHelpers';

import {
    sortAlphabetically
} from '../../../utils/helpers';
import * as messages from '../../../utils/Constant/messages';
import { isCesSearchEnable } from '../../../utils/global-settings';
import { Text } from '../../../components';

const AddEditCompany: React.FC = () => {
    const params = useParams<AddEditCompanyProps>();
    const perPage = 50;
    const { state: { user, globalSettings } } = useContext(UserStore);
    const [companyId, setCompanyId] = useState<number | undefined>();
    const [isEdit, setIsEdit] = useState<boolean | undefined>(undefined);
    const [currentTab, setCurrentTab] = useState<string>(TabOptions.FRAMEWORKS);
    const [addCompany] = useMutation(ADD_COMPANY);
    const [editCompany] = useMutation(EDIT_COMPANY);
    const [createNotification] = useMutation(CREATE_NOTIFICATION);


    // Framework tab states
    const [frameworksPage, setFrameworksPage] = useState<number>(0);
    const [frameworkSearchText, setFrameworkSearchText] = useState<string>('');
    const [frameworkFilters, setFrameworkFilters] = useState<FrameworkFilters>({
        frameworkEnabled: 'All',
        proposition:'All'
    });
    const [selectedFrameworkAccess, setFrameworkAccess] = useState<string>('All');
    const [allSelectedFrameworks, setAllSelectedFrameworks] = useState<number[]>([]);
    const [frameworkRows, setFrameworkRows] = useState<TableRow[]>([
        {
            tableCells: []
        }
    ]);
    const [allInteractedFrameworks, setAllInteractedFrameworks] = useState<number[]>([]);

    const [allCRECountries, setAllCRECountries] = useState<Country[]>([
        {
            id: 0,
            name: '',
            gcpRegionId: 0
        }
    ]);
    const [allHomeCountries, setAllHomeCountries] = useState<Country[]>([
        {
            id: 0,
            name: '',
            gcpRegionId: 0
        }
    ]);
    const [allIndustries, setAllIndustries] = useState<DropdownValueType[]>([
        {
            id: 0,
            name: ''
        }
    ]);
    const [allRevenues, setAllRevenues] = useState<DropdownValueType[]>([
        {
            id: 0,
            name: ''
        }
    ]);
    const [allNoEmployees, setAllNoEmployees] = useState<DropdownValueType[]>([
        {
            id: 0,
            name: ''
        }
    ]);
    const [allCompanyTypes, setAllCompanyTypes] = useState<DropdownValueType[]>([
        {
            id: 0,
            name: ''
        }
    ]);
    const [allFrameworksResponse, setAllFrameworksResponse] = useState<FrameworkSummariesByCompany>({
        count: 0,
        items: [],
        skip: 0,
        take: perPage,
        total: 0
    });
    const [allGCPRegions, setAllGCPRegions] = useState<DropdownValueType[]>([
        {
            id: 0,
            name: ''
        }
    ]);
    const [selectedProposition, setSelectedProposition] = useState<string>(
        'All'
    );



    // Form states
    const [companyName, setCompanyName] = useState<string>('');
    const [companyNameError, setCompanyNameError] = useState<string>('');
    const [bencmarkingStatus, setBencmarkingStatus] = useState<boolean>(true);
    const [companyEnabled, setCompanyEnabled] = useState<boolean>(true);
    const [cesId, setCesId] = useState<string>('');
    const [cesIdError, setCesIdError] = useState<string>('');
    const [selectedHomeCountry, setSelectedHomeCountry] = useState<string>('');
    const [selectedCRECountry, setSelectedCRECountry] = useState<string>('');
    const [selectedIndustry, setSelectedIndustry] = useState<string>('');
    const [selectedRevenue, setSelectedRevenue] = useState<string>('');
    const [selectedNoEmployee, setSelectedNoEmployee] = useState<string>('');
    const [selectedCompanyType, setSelectedCompanyType] = useState<string>('');
    const [selectedRelationshipManager, setSelectedRelationshipManager] = useState<string>('');
    const [selectedGCPRegion, setSelectedGCPRegion] = useState<string>('');
    const [errorSaving, setErrorSaving] = useState<boolean>(false);
    const [isCES, setIsCES] = useState<boolean>(false);

    const { data: dataPropositions } = useQuery(PROPOSITIONS, {
        fetchPolicy: 'cache-first'
    });

    const { loading: loadingDropdownValues, error: errorDropdownValues, data: dataDropdownValues } = useQuery(
        DROPDOWN_VALUES,
        {
            fetchPolicy: 'network-only',
            variables: {
                pagination: {
                    order: {
                        field: 'name',
                        isAsc: true
                    }
                },
                filters: {
                    countryEnabled: typeof isEdit === 'boolean' && !isEdit ? true : undefined
                }
            }
        }
    );

    const [order, setOrder] = useState<CompanyOrder|undefined>();

    const [getFrameworksByCompany] = useLazyQuery(FRAMEWORK_SUMMARIES_BY_COMPANY_MODEL, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            setAllFrameworksResponse(res.frameworkSummariesByCompany);

            const enabledFrameworksArr = res.frameworkSummariesByCompany.items
                .filter(
                    (item: Framework) => item.frameworkEnabled && !allInteractedFrameworks.includes(item.frameworkId)
                )
                .map((item: Framework) => item.frameworkId);

            const allUniqueSelectedIds = Array.from(new Set([...enabledFrameworksArr, ...allSelectedFrameworks]));
            setAllSelectedFrameworks(allUniqueSelectedIds);
        },
        onError: (err) => {
            console.log(err);
        }
    });

    const [getAllFrameworksByCountry] = useLazyQuery(ALL_FRAMEWORKS, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            setAllFrameworksResponse(res.frameworkSummariesByCountry);
        },
        onError: (err) => {
            console.log(err);
        }
    });

    useEffect(() => {
        if (params && params.companyId) {
            setCompanyId(parseInt(decodeURIComponent(params.companyId)));
            setIsEdit(true);
        } else {
            setIsEdit(false);
        }
    }, [params]);

    useEffect(() => {
        if (loadingDropdownValues || errorDropdownValues) {
            return;
        }

        setAllCRECountries(dataDropdownValues.countrySummaries.items);
        sortAlphabetically(dataDropdownValues.homeCountries, 'country');

        const restructuredHomeCountries = dataDropdownValues.homeCountries.map((country: {country: string}, index: number) => {
            return {
                id: index,
                name: country.country
            };
        });

        setAllHomeCountries(restructuredHomeCountries);
        const sortertedAllIndustries =  sortAlphabetically(dataDropdownValues.industries, 'name');
        setAllIndustries(sortertedAllIndustries);

        const restructuredRevObj = dataDropdownValues.annualRevenues.map((revenue: {id: number, value: string}) => {
            return {
                id: revenue.id,
                name: revenue.value
            };
        });

        setAllRevenues(restructuredRevObj);

        const restructuredNoEmplObj = dataDropdownValues.employeesNumbers.map((employeeNo: {id: number, value: string}) => {
            return {
                id: employeeNo.id,
                name: employeeNo.value
            };
        });

        setAllNoEmployees(restructuredNoEmplObj);
        const sortertedCompanyTypes =  sortAlphabetically(dataDropdownValues.clientTypes, 'name');
        setAllCompanyTypes(sortertedCompanyTypes);
        // Locking the GCP Region
        // previous : setAllGCPRegions(dataDropdownValues.gcpRegions);
        setAllGCPRegions((typeof isEdit === 'boolean' && isEdit) ?
            dataDropdownValues.gcpRegions :
            dataDropdownValues.gcpRegions.filter((gcpRegion: DropdownValueType) => (gcpRegion.name === (window.__ENV__.GCP_REGION_SUPPORTED ?? 'europe-west2'))));
    }, [loadingDropdownValues, errorDropdownValues, dataDropdownValues]);

    useEffect(() => {
        setFrameworksPage(0);
        setFrameworkFilters({
            frameworkEnabled: selectedFrameworkAccess,
            proposition:selectedProposition
        });
    }, [selectedFrameworkAccess,selectedProposition]);

    useEffect(() => {
        if (!allFrameworksResponse) {
            return;
        }
        const rows = setFrameworkTableRows(allFrameworksResponse, allSelectedFrameworks, handleFrameworksToggleChange);
        rows && setFrameworkRows(rows);
    }, [allFrameworksResponse, allSelectedFrameworks]);

    const createSortHandler = (property: string) => {
        if(order && order.field===property)
        {
            setOrder({field:property,isAsc:!order.isAsc});
        }
        else{
            setOrder({field:property,isAsc:true});
        }

    };

    useEffect(() => {
        setOrder(undefined);
     }, [currentTab]);


    useEffect(() => {
        // if (loading) {
        //     return;
        // }
        if (currentTab === TabOptions.FRAMEWORKS && !!selectedCRECountry) {
            if (typeof isEdit === 'boolean' && !isEdit) {
                getAllFrameworksByCountry({
                    variables: {
                        countryId: findByName(selectedCRECountry, allCRECountries),
                        filters: {
                            frameworkEnabled: true,
                            clientFrameworkEnabled: frameworkFilters.frameworkEnabled === 'Enabled'
                                                        ? true
                                                        : frameworkFilters.frameworkEnabled === 'Disabled'
                                                        ? false
                                                        : null,
                            proposition:frameworkFilters.proposition==='All'?undefined:frameworkFilters.proposition
                        },
                        search: frameworkSearchText,
                        pagination: {
                            order: !!order ? order : {field: 'name', isAsc: true},
                            skip: frameworksPage * perPage,
                            take: perPage
                        },
                        enabledClientFrameworkIds: allSelectedFrameworks
                    }
                });
            } else if (typeof isEdit === 'boolean' && isEdit && !!selectedCRECountry) {
                getFrameworksByCompany({
                    variables: {
                        clientId: companyId,
                        filters: {
                            frameworkEnabled:
                                frameworkFilters.frameworkEnabled === 'Enabled'
                                    ? true
                                    : frameworkFilters.frameworkEnabled === 'Disabled'
                                    ? false
                                    : null,
                                    proposition:frameworkFilters.proposition==='All'?undefined:frameworkFilters.proposition
                        },
                        search: frameworkSearchText,
                        pagination: {
                            order: !!order ? order : {field: 'frameworkName', isAsc: true},
                            skip: frameworksPage * perPage,
                            take: perPage
                        }
                    }
                });
            }
        }

    }, [frameworksPage, frameworkFilters, frameworkSearchText, currentTab, isEdit, order, selectedCRECountry]);

    const handleFrameworkChangePage = (event: unknown, newPage: number) => {
        setFrameworksPage(newPage);
    };

    const frameworkTablePagination = {
        rowsPerPage: perPage,
        currentPage: frameworksPage,
        handleChangePage: handleFrameworkChangePage,
        totalCount: allFrameworksResponse && allFrameworksResponse.total
    };
    const headerFrameworksData = getHeaderFrameworksData({createSortHandler,isEdit});

    const handleFrameworksToggleChange: ChangeToggle = (event, isActive, cellID) => {
        if (allSelectedFrameworks.includes(cellID)) {
            const arrWithoutCell = allSelectedFrameworks.filter((selFr: number) => selFr !== cellID);
            setAllSelectedFrameworks(arrWithoutCell);
        } else {
            setAllSelectedFrameworks([...allSelectedFrameworks, cellID]);
        }

        if (!allInteractedFrameworks.includes(cellID)) {
            setAllInteractedFrameworks([...allInteractedFrameworks, cellID]);
        }
    };

    // User tab states
    const [usersPage, setUsersPage] = useState<number>(0);
    const [userSearchText, setUserSearchText] = useState<string>('');
    const [usersFilters, setUsersFilters] = useState<UsersFilters>({
        permission: 'All',
        userEnabled: 'All'
    });
    const [selectedUsersAccess, setUsersAccess] = useState<string>('All');
    const [selectedPermission, setSelectedPermission] = useState<string>('All');
    const [allSelectedUsers, setAllSelectedUsers] = useState<number[]>([]);
    const [allInteractedUsers, setAllInteractedUsers] = useState<number[]>([]);
    const [usersRows, setUsersRows] = useState<TableRow[]>([
        {
            tableCells: []
        }
    ]);

    useEffect(() => {
        setUsersPage(0);
        setUsersFilters({
            permission: selectedPermission,
            userEnabled: selectedUsersAccess
        });
    }, [selectedUsersAccess, selectedPermission]);

    const [allUsersByCompany, setAllUsersByCompany] = useState<UserListPage>({
        count: 0,
        items: [],
        skip: 0,
        take: perPage,
        total: 0
    });
    const [allDropdownUsers, setAllDropdownUsers] = useState<UserSummaries[]>([]);

    const [getUsersByCompany] = useLazyQuery(USERS_BY_COMPANY_MODEL, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {

            setAllUsersByCompany(res.usersByClientPage);

            const allSelected = res.usersByClientPage.items
                .filter((users: UserSummaries) => users.userEnabled && !allInteractedUsers.includes(users.id))
                .map((users: UserSummaries) => users.id);
            const allUniqueUserIds = Array.from(new Set([...allSelected, ...allSelectedUsers]));

            setAllSelectedUsers(allUniqueUserIds);
        },
        onError: (err) => {
            console.log(err);
        }
    });

    const [getAllUsersByCountry] = useLazyQuery(ALL_USERS, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            if(typeof isEdit === 'boolean'
                && !isEdit){
                setAllUsersByCompany(res.usersByCountry);
            }

        },
        onError: (err) => {
            console.log(err);
        }
    });

    const [getAllUsersForManager] = useLazyQuery(ALL_USERS, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            setAllDropdownUsers(res.usersByCountry.items);
        },
        onError: (err) => {
            console.log(err);
        }
    });

    const handleUsersChangePage = (event: unknown, newPage: number) => {
        setUsersPage(newPage);
    };

    const userTablePagination = {
        rowsPerPage: perPage,
        currentPage: usersPage,
        handleChangePage: handleUsersChangePage,
        totalCount: allUsersByCompany && allUsersByCompany.total
    };

    const headerUsersData = getUsersTableHeader(createSortHandler);

    useEffect(() => {
        if (!allUsersByCompany) {
            return;
        }
        const rows = setUsersTableRows(allUsersByCompany, allSelectedUsers, handleUsersEnabledToggleChange);
        rows && setUsersRows(rows);
    }, [allUsersByCompany, allSelectedUsers]);

    const flushDataOnCountryChange = () => {
        setUsersFilters({
            permission: 'All',
            userEnabled: 'All'
        });
        setUserSearchText('');
        setSelectedPermission('All');
        setUsersAccess('All');
        setAllSelectedUsers([]);
        setAllInteractedUsers([]);
        setSelectedRelationshipManager('');
        setFrameworkFilters({
            frameworkEnabled: 'All',
            proposition:'All'
        });
        setFrameworkSearchText('');
        setFrameworkAccess('All');
        setAllSelectedFrameworks([]);
        setAllInteractedFrameworks([]);
        setOrder(undefined);
    };

    useEffect(() => {
        flushDataOnCountryChange();
        if(!!selectedCRECountry) {
            const selCountry = allCRECountries.find(country => selectedCRECountry === country.name);
            // Locking the GCP Region
            // previous : const gspName = allGCPRegions.find(reg => {
            //     return selCountry && selCountry.gcpRegionId === reg.id;
            // });
            const gspName = (typeof isEdit === 'boolean' && isEdit) ? allGCPRegions.find(reg => {
                return selCountry && selCountry.gcpRegionId === reg.id;
            }) : allGCPRegions.find(reg => {
                return reg.name === (window.__ENV__.GCP_REGION_SUPPORTED ?? 'europe-west2');
            });
            gspName && setSelectedGCPRegion(gspName.name);
        }
    }, [selectedCRECountry]);

    useEffect(() => {
        if (typeof isEdit === 'boolean'
            && !isEdit
            && !!selectedCRECountry) {
            getAllUsersByCountry({
                variables: {
                    countryId: findByName(selectedCRECountry, allCRECountries),
                    filters: {
                        countryUserEnabled: true,
                        role: usersFilters.permission === 'All' ? ['ADMIN','STANDARD','EXTERNAL'] : usersFilters.permission,
                        clientUserEnabled: usersFilters.userEnabled === 'Enabled'
                                            ? true
                                            : usersFilters.userEnabled === 'Disabled'
                                            ? false
                                            : undefined
                    },
                    search: userSearchText,
                    pagination: {
                        order: !!order ? order : {field: 'name', isAsc: true},
                        skip: usersPage * perPage,
                        take: perPage
                    },
                    enabledClientUserIds: allSelectedUsers
                }
            });
            if(usersFilters.permission === 'All' &&
                usersFilters.userEnabled === 'All' &&
                !userSearchText &&
                !order){
                    getAllUsersForManager({
                        variables: {
                            countryId: findByName(selectedCRECountry, allCRECountries),
                            filters: {
                                countryUserEnabled: true,
                                role: ['ADMIN','STANDARD']
                            },
                            pagination: {
                                order: {field: 'name', isAsc: true}
                            }
                        }
                    });
                }
        } else if (typeof isEdit === 'boolean'
                && isEdit
                && !!selectedCRECountry) {
            getAllUsersForManager({
                variables: {
                    countryId: findByName(selectedCRECountry, allCRECountries),
                    filters: {
                        countryUserEnabled: true,
                        role: ['ADMIN','STANDARD']
                    },
                    pagination: {
                        order: {field: 'name', isAsc: true}
                    }
                }
            });
            getUsersByCompany({
                variables: {
                    clientId: companyId,
                    filters: {
                        userEnabled:
                            usersFilters.userEnabled === 'Enabled'
                                ? true
                                : usersFilters.userEnabled === 'Disabled'
                                ? false
                                : undefined,
                        role: usersFilters.permission === 'All' ? null : usersFilters.permission
                    },
                    search: userSearchText,
                    pagination: {
                        order: !!order ? order : {field: 'name', isAsc: true},
                        skip: usersPage * perPage,
                        take: perPage
                    }
                }
            });
        }
    }, [usersPage, usersFilters, userSearchText, isEdit, selectedCRECountry,order]);

    const handleUsersEnabledToggleChange: ChangeToggle = (event, isActive, cellID) => {
        if (allSelectedUsers.includes(cellID)) {
            const arrWithoutCell = allSelectedUsers.filter((selFr: number) => selFr !== cellID);
            setAllSelectedUsers(arrWithoutCell);
        } else {
            setAllSelectedUsers([...allSelectedUsers, cellID]);
        }
        if (!allInteractedUsers.includes(cellID)) {
            setAllInteractedUsers([...allInteractedUsers, cellID]);
        }
    };

    const cancelHandler = () => {
        Router.goToCompaniesManagement();
    };

    const saveChanges = () => {
        if (!cesId) {
            setCesIdError('Required');
        }

        if (!companyName.trim()) {
            setCompanyNameError('Required');
        }

        if (
            !cesId ||
            !selectedHomeCountry ||
            !selectedCRECountry ||
            !selectedIndustry ||
            !selectedRevenue ||
            !selectedNoEmployee ||
            !selectedCompanyType ||
            !selectedRelationshipManager ||
            !companyName.trim()
        ) {
            setErrorSaving(true);
            return;
        }

        let resultAction: any;
        if (typeof isEdit === 'boolean' && isEdit) {
            resultAction = editCompany;
        } else {
            resultAction = addCompany;
        }

        actionHandler(
            resultAction,
            companyName,
            cesId,
            selectedCRECountry,
            allCRECountries,
            allHomeCountries,
            bencmarkingStatus,
            companyEnabled,
            selectedIndustry,
            allIndustries,
            selectedRevenue,
            allRevenues,
            selectedRelationshipManager,
            allDropdownUsers,
            selectedNoEmployee,
            allNoEmployees,
            selectedCompanyType,
            allCompanyTypes,
            allInteractedFrameworks,
            allSelectedFrameworks,
            allInteractedUsers,
            allSelectedUsers,
            selectedHomeCountry,
            selectedGCPRegion,
            allGCPRegions,
            companyId,
            isEdit
        );
    };

    const handleChangeTabs = (newTab: string) => {
        setCurrentTab(newTab);
    };

    // EditCompany query
    const [titleLabel, setTitleLabel] = useState<string>('Add company');
    const [getCompany] = useLazyQuery(COMPANY_DETAILS, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            setCompanyName(res.client.name);
            setBencmarkingStatus(res.client.benchmarkingEnabled);
            setCompanyEnabled(res.client.clientEnabled);
            setCesId(res.client.prId);
            setSelectedCRECountry(findById(res.client.countryId, allCRECountries));
            setSelectedHomeCountry(res.client.homeCountry);
            setSelectedNoEmployee(res.client.employeesNumber.value);
            setSelectedIndustry(res.client.industry.name);
            setSelectedRevenue(res.client.annualRevenue.value);
            setSelectedCompanyType(res.client.type.name);
            setSelectedRelationshipManager(findById(res.client.owner.id, allDropdownUsers));
            // Locking the GCP Region
            // previous : const currRegion = allGCPRegions.find((region: DropdownValueType) => region.id === res.client.gcpRegionId);
            const currRegion = (typeof isEdit === 'boolean' && isEdit) ?
                allGCPRegions.find((region: DropdownValueType) => region.id === res.client.gcpRegionId) :
                allGCPRegions.find((region: DropdownValueType) => region.name === (window.__ENV__.GCP_REGION_SUPPORTED ?? 'europe-west2'));
            currRegion && setSelectedGCPRegion(currRegion.name);
        },
        onError: (err) => {
            console.log(err);
        }
    });

    useEffect(() => {
        if (typeof isEdit === 'boolean' && isEdit && !loadingDropdownValues) {
            setTitleLabel('Edit company');
            getCompany({
                variables: {
                    id: companyId
                }
            });
        }
    }, [isEdit, allCRECountries, allDropdownUsers, allHomeCountries]);

    const allUsersForRelManager = convertUsersToDropdownType(allDropdownUsers);

    const [showResult, setShowResult] = useState<boolean>(false);
    const [isManually, setIsManually] = useState<boolean>(false);
    const [isCESLoading, setIsCESLoading] = useState<boolean>(false);
    const [result, setResult] = useState<[]>([]);
    const [searchError, setSearchError] = useState<string>('');
    const debounceFn = useCallback(_debounce(searchCompanyInCES, 1000), []);
    const ref = useRef<HTMLUListElement>(null);


    useEffect(() => {
        const handleClickOutside=(event:any)=> {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsManually(true);
            }
        };
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsManually(false);
        setShowResult(false);
        setCompanyNameError('');
        setCompanyName(event.target.value);
        if (isCesSearchEnable(globalSettings)) {
            debounceFn(event.target.value, setResult, setSearchError, setIsCESLoading);
        }
    };

    useEffect(() => {
        if (result.length > 0) {
            setShowResult(true);
        }
    }, [result]);

    const itemSelectHandler = (selectedItem: any) => {
        setShowResult(false);
        setSearchError('');
        setIsCES(true);
        setCompanyName(selectedItem.name);
        setCesId(selectedItem.prid);
        setSelectedHomeCountry(selectedItem.homeCountry);
        setSelectedIndustry(selectedItem.industry);
        setSelectedRevenue(selectedItem.revenue);
        setSelectedNoEmployee(selectedItem.noEmployees);
        setSelectedCompanyType(selectedItem.organisationType);
    };

    const handlePrIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length > 20) {
            setCesIdError(messages.ERROR_COMPANIES_PRID_NUMBERL_CHARACTERS);
        } else if (!!event.target.value && !/^\d*[1-9]\d*$/.test(event.target.value)) {
            setCesIdError(messages.ERROR_COMPANIES_PRID_SPECIAL_CHARACTERS);
        } else {
            setCesId(event.target.value);
            setCesIdError('');
        }
    };

    const isGCPDropdown = user.role !== 'ADMIN' ||
                            !selectedCRECountry ||
                            (typeof isEdit === 'boolean' && isEdit);

    return (
        <MainWrapper>
            <HeaderWrapper>
                <Header>
                    <h1>{titleLabel}</h1>
                    <ButtonsWrapper>
                        <CancelButton disabled={false} onClick={cancelHandler}>
                            Cancel
                        </CancelButton>
                        <SaveButton disabled={false} onClick={saveChanges}>
                            <img src={iconSave} alt="add company icon" />
                            Save
                        </SaveButton>
                    </ButtonsWrapper>
                </Header>
            </HeaderWrapper>
            <div>
                <TitleAndToggles>
                    <SearchDropdown>
                        <CustomField>
                            <TextField
                                id="standard-basic"
                                label="Name"
                                onChange={handleNameChange}
                                value={companyName}
                                error={companyNameError.length > 0}
                                helperText={
                                    <Text style={{color: 'red'}}>{companyNameError || searchError}</Text>
                                }
                                onFocus={() => {
                                    if (result.length > 0) {
                                        setShowResult(true);
                                    }
                                }}
                                style={{
                                    width: '100%',
                                    paddingRight: '40px'
                                }}
                                autoComplete="off"
                                variant='standard'
                            />
                            {
                                isCesSearchEnable(globalSettings) &&
                                (companyName.length > 2 && showResult) ?
                                    <ClearBtn onClick={() => {
                                        setCompanyNameError('');
                                        setCompanyName('');
                                        setResult([]);
                                    }}>
                                        <img style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover'
                                        }} src={closeIcon} alt="close icon"/>
                                    </ClearBtn> : ''
                            }
                            {
                                isCesSearchEnable(globalSettings) &&
                                (isCESLoading) ?
                                    <CircularProgress style={{
                                        width: '25px',
                                        height: '25px',
                                        position: 'absolute',
                                        margin: 'auto',
                                        top: '15px',
                                        bottom: '0px',
                                        right: '15px'
                                    }} /> : ''
                            }
                        </CustomField>
                        {
                           !isManually && showResult && result && result.length > 0 ?
                                <CustomDropDown ref={ref}>
                                    {
                                        result && result.map((res: any, index: number) => {
                                            return (
                                                <Item
                                                    key={`search-index-item-${index}`}
                                                    onClick={() => {
                                                        itemSelectHandler(res);
                                                    }}
                                                >
                                                    {
                                                        res && res.prid ? (
                                                            <Text><b>{res.prid}</b> | </Text>
                                                        ) : null
                                                    }
                                                    {
                                                        res && res.name ? (
                                                            <Text>{res.name} | </Text>
                                                        ) : null
                                                    }
                                                    {
                                                        res && res.organisationType ? (
                                                            <Text>{res.organisationType} | </Text>
                                                        ) : null
                                                    }
                                                    {
                                                        res && res.homeCountry ? (
                                                            <Text><b>{res.homeCountry}</b></Text>
                                                        ) : null
                                                    }
                                                </Item>
                                            );
                                        }, [])
                                    }
                                </CustomDropDown> : null
                        }
                    </SearchDropdown>
                    <TogglesWrapper>
                        Benchmarking
                        <BenchmarkingToggle
                            labels={benchmarkingToggleLabels}
                            state={bencmarkingStatus}
                            actionFunc={() => setBencmarkingStatus(!bencmarkingStatus)}
                            cellID="Benchmarking-toogle"
                        />
                        Status
                        <StatusToogle
                            labels={statusToggleLabels}
                            state={companyEnabled}
                            actionFunc={() => setCompanyEnabled(!companyEnabled)}
                            cellID="Status-toogle"
                        />
                    </TogglesWrapper>
                </TitleAndToggles>
                <CompanyDetails>
                    <DetailText
                        labelText="PR ID"
                        value={cesId}
                        onChangeFunc={handlePrIdChange}
                        isError={cesIdError.length > 0}
                        errorMessage={cesIdError}
                        placeholderText="Add PR ID"
                    />
                    <Detail
                        labelText="Company home country"
                        isDisabled={false}
                        selectedValue={selectedHomeCountry}
                        setValue={setSelectedHomeCountry}
                        placeholder="Select home country"
                        allValues={allHomeCountries}
                        width="14em"
                        error={!selectedHomeCountry && errorSaving}
                        errorMessage={!selectedHomeCountry && errorSaving ? 'Please select home country' : ''}
                    />
                    <Detail
                        labelText="CRE enabled country"
                        isDisabled={typeof isEdit === 'boolean' && isEdit}
                        selectedValue={selectedCRECountry}
                        setValue={setSelectedCRECountry}
                        placeholder="Add a country"
                        allValues={allCRECountries}
                        width="14em"
                        error={!selectedCRECountry && errorSaving}
                        errorMessage={!selectedCRECountry && errorSaving ? 'Please select CRE country' : ''}
                    />
                    <Detail
                        labelText="Industry"
                        isDisabled={false}
                        selectedValue={selectedIndustry}
                        setValue={setSelectedIndustry}
                        placeholder="Select Industry"
                        allValues={allIndustries}
                        width="14em"
                        error={!selectedIndustry && errorSaving}
                        errorMessage={!selectedIndustry && errorSaving ? 'Please select industry' : ''}
                    />
                    <Detail
                        labelText="Revenue"
                        isDisabled={false}
                        selectedValue={selectedRevenue}
                        setValue={setSelectedRevenue}
                        placeholder="Select revenue"
                        allValues={allRevenues}
                        width="14em"
                        error={!selectedRevenue && errorSaving}
                        errorMessage={!selectedRevenue && errorSaving ? 'Please select revenue' : ''}
                    />
                    <Detail
                        labelText="No. of employees"
                        isDisabled={false}
                        selectedValue={selectedNoEmployee}
                        setValue={setSelectedNoEmployee}
                        placeholder="Select number of employees"
                        allValues={allNoEmployees}
                        width="14em"
                        error={!selectedNoEmployee && errorSaving}
                        errorMessage={!selectedNoEmployee && errorSaving ? 'Please select number of employees' : ''}
                    />
                    <Detail
                        labelText="Company Type"
                        isDisabled={false}
                        selectedValue={selectedCompanyType}
                        setValue={setSelectedCompanyType}
                        placeholder="Select company type"
                        allValues={allCompanyTypes}
                        width="14em"
                        error={!selectedCompanyType && errorSaving}
                        errorMessage={!selectedCompanyType && errorSaving ? 'Please select company type' : ''}
                    />
                    <Detail
                        labelText={'Relationship manager'}
                        isDisabled={!selectedCRECountry}
                        selectedValue={selectedRelationshipManager}
                        setValue={setSelectedRelationshipManager}
                        placeholder={'Add relationship manager'}
                        allValues={allUsersForRelManager}
                        width={'14em'}
                        error={!selectedRelationshipManager && errorSaving}
                        errorMessage={
                            !selectedRelationshipManager && errorSaving ? 'Please select relationship manager' : ''
                        }
                    />
                    <Detail
                        labelText={'GCP Location'}
                        isDisabled={isGCPDropdown || (typeof isEdit === 'boolean' && isEdit)}
                        selectedValue={selectedGCPRegion}
                        setValue={setSelectedGCPRegion}
                        placeholder={'Select GCP location'}
                        allValues={allGCPRegions}
                        width={'14em'}
                        error={!selectedGCPRegion && errorSaving}
                        errorMessage={
                            !selectedGCPRegion && errorSaving ? 'Please select GCP region' : ''
                        }
                    />
                </CompanyDetails>
                <Tabs tabLabels={tabLabels} activeTab={currentTab} action={handleChangeTabs} />
                {currentTab === TabOptions.FRAMEWORKS && (
                    <>
                        <TableFiltersAndSearch>
                            <TableFilters>
                                <DropdownSingleSelection
                                    labelText={'Proposition'}
                                    isDisabled={false}
                                    selectedValue={selectedProposition}
                                    setValue={setSelectedProposition}
                                    allValues={
                                        dataPropositions &&
                                        dataPropositions.frameworkPropositions && [
                                            { id: 0, name: 'All' },
                                            ...dataPropositions.frameworkPropositions.slice().sort().map(
                                                (item: string, i: number) => ({
                                                    id: i + 1,
                                                    name: item
                                                })
                                            )
                                        ]
                                    }
                                    width="10em"
                                />
                                <DropdownSingleSelection
                                    labelText="Framework access"
                                    isDisabled={false}
                                    selectedValue={selectedFrameworkAccess}
                                    setValue={setFrameworkAccess}
                                    allValues={disabledEnabledDropdownValues}
                                    width="14em"
                                />
                            </TableFilters>
                            <SearchAndSave>
                                <SearchField
                                    placeholder="Search"
                                    callback={(value: string) => {
                                        setFrameworksPage(0);
                                        setFrameworkSearchText(value);
                                    }}
                                />
                            </SearchAndSave>
                        </TableFiltersAndSearch>
                        <NewTable
                            header={headerFrameworksData}
                            tablePagination={frameworkTablePagination}
                            rows={frameworkRows}
                        />
                    </>
                )}
                {currentTab === TabOptions.USERS && (
                    <>
                        <TableFiltersAndSearch>
                            <TableFilters>
                                <DropdownSingleSelection
                                    labelText="Permission"
                                    isDisabled={false}
                                    selectedValue={selectedPermission}
                                    setValue={setSelectedPermission}
                                    allValues={typeof isEdit === 'boolean' && isEdit ? adminDropdownValues : adminAddDropdownValues}
                                    width="14em"
                                />
                                <DropdownSingleSelection
                                    labelText="User access"
                                    isDisabled={false}
                                    selectedValue={selectedUsersAccess}
                                    setValue={setUsersAccess}
                                    allValues={disabledEnabledDropdownValues}
                                    width="14em"
                                />
                            </TableFilters>
                            <SearchAndSave>
                                <SearchField
                                    placeholder="Search"
                                    callback={(value: string) => {
                                        setUsersPage(0);
                                        setUserSearchText(value);
                                    }}
                                />
                            </SearchAndSave>
                        </TableFiltersAndSearch>
                        <NewTable header={headerUsersData} tablePagination={userTablePagination} rows={usersRows} />
                    </>
                )}
            </div>
        </MainWrapper>
    );
};

const MainWrapper = styled('div')`
    display: flex;
    flex-direction: column;
`;

const HeaderWrapper = styled('div')`
    height: 165px;
`;

const Header = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #151515;
    height: 107px;
    color: white;
    position: absolute;
    width: 78%;
    left: 0;
    padding-left: 22%;
    z-index: 10;
`;

const CancelButton = styled(TransparentButtonInverted)`
    width: 180px;
    font-size: 18px !important;
`;

const SaveButton = styled(RedButton)`
    margin-left: 10px !important;
    width: 180px;
    font-size: 18px !important;
    img {
        padding-right: 3px;
    }
`;

const ButtonsWrapper = styled('div')`
    display: flex;
    padding-right: 56px;
    height: 50%;
`;

const TitleAndToggles = styled('div')`
    display: flex;
    align-items: center;
`;

const TogglesWrapper = styled('div')`
    display: flex;
    align-items: center;
`;

const StatusToogle = styled(Toggle)`
    margin-left: 0.75rem;
`;

const BenchmarkingToggle = styled(Toggle)`
    margin-left: 0.75rem;
    margin-right: 3.125rem;
`;

const CompanyDetails = styled('div')`
    margin-top: 25px;
`;

const DetailText = styled(InputTextField)`
    display: inline-block;
    margin-right: 8%;
    margin-bottom: 11px;
    overflow: hidden;
`;

const Detail = styled(DropdownSingleSelection)<{
    error?: boolean;
}>`
    display: inline-block;
    margin-right: 8%;
    margin-bottom: 11px;
    overflow: hidden;
    .dropdown-child {
        margin-bottom: '0px';
    }
`;

const TableFiltersAndSearch = styled('div')`
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
`;

const TableFilters = styled('div')`
    display: flex;
`;

const SearchAndSave = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

const SearchDropdown = styled('div')`
    position: relative;
    width: 100%;
`;

const CustomDropDown = styled('ul')`
    box-shadow: 0px 0px 8px -2px #9d9d9d;
    border-radius: 5px;
    background: white;
    position: absolute;
    top: calc(100% + 15px);
    margin: auto;
    left: 0;
    right: 0;
    width: auto;
    max-height: 500px;
    overflow: auto;
    z-index: 999;
    padding: 0px;
    cursor: pointer;
`;

const Item = styled('li')`
    display: block;
    font-size: 14px;
    padding: 12px;
    transition: all 300ms ease-in-out;

    &:hover {
        background-color: #eee;
    }
`;

const ErrorText = styled('span')`
    font-size: 12px;
    color: red;
`;

const CustomField = styled('div')`
    display: flex;
    position: relative;
    margin-right: 40px;
`;

const ClearBtn = styled('span')`
    width: 20px;
    height: 20px;
    position: absolute;
    margin: auto;
    top: 15px;
    bottom: 0px;
    right: 15px;
    font-size: 20px;
    cursor: pointer;
`;

export default AddEditCompany;
