export enum Routes {
    splash = '/',
    loginRedirect = '/login/redirect',
    logoutRedirect = '/logout/redirect',
    main = '/main',
    auditTrails = '/main/audit-trails',
    manageUsers = '/main/manage-users/:isUserCreated?',
    user = '/main/manage-users/:userName/:userId/:userRole',
    addEditExternalUser = '/main/manage-users/external/:addEdit/:userId',
    addEditInternalUser = '/main/manage-users/internal/:addEdit/:userId',
    manageCountries = '/main/manage-countries/:isCountryCreated?',
    country = '/main/manage-countries/:countryName/:countryId',
    addCountries = '/main/manage-countries/add',
    editCountries = '/main/manage-countries/edit/:countryId',
    manageCompanies = '/main/manage-companies/:isCompanyCreated?',
    company = '/main/manage-companies/:companyName/:companyId',
    addCompanies = '/main/manage-companies/add',
    editCompanies = '/main/manage-companies/edit/:companyId',
    manageFrameworks = '/main/manage-frameworks/:isFrameworkCreated?',
    uploadFrameworks = '/main/manage-frameworks/upload',
    framework = '/main/manage-frameworks/:frameworkName/:frameworkId',
    manageCampaigns = '/main/manage-campaigns',
    manageSettings = '/main/manage-settings',
    notificationHub = '/main/notification-hub',
    Reports = '/main/Reports',
    loggedOutRoutes = '/*',
}

export enum ConfigRoutes {
    splash = '/',
    loginRedirect = '/login/redirect',
    logoutRedirect = '/logout/redirect',
    main = '/main/*',
    auditTrails = 'audit-trails',
    manageUsers = 'manage-users/:isUserCreated?',
    user = 'manage-users/:userName/:userId/:userRole',
    addEditExternalUser = 'manage-users/external/:addEdit/:userId',
    addEditInternalUser = 'manage-users/internal/:addEdit/:userId',
    manageCountries = 'manage-countries/:isCountryCreated?',
    country = 'manage-countries/:countryName/:countryId',
    addCountries = 'manage-countries/add',
    editCountries = 'manage-countries/edit/:countryId',
    manageCompanies = 'manage-companies/:isCompanyCreated?',
    company = 'manage-companies/:companyName/:companyId',
    addCompanies = 'manage-companies/add',
    editCompanies = 'manage-companies/edit/:companyId',
    manageFrameworks = 'manage-frameworks/:isFrameworkCreated?',
    uploadFrameworks = 'manage-frameworks/upload',
    framework = 'manage-frameworks/:frameworkName/:frameworkId',
    manageCampaigns = 'manage-campaigns',
    manageSettings = 'manage-settings',
    notificationHub = 'notification-hub',
    Reports = 'Reports',
    loggedOutRoutes = '/*'
}

export class DynamicRoutes {

    public static goToCountries(
        isCountryCreated?: string
    ) {
        if (isCountryCreated === 'false')
            return '/main/manage-countries'
        return `/main/manage-countries/${isCountryCreated}`;
    };

    public static goToCountry(
        countryId:string,
        countryName:string
    ) {
        const encodeName=encodeURIComponent(countryName);
        const encodeId=encodeURIComponent(countryId);
        return `/main/manage-countries/${encodeName}/${encodeId}`;
    };

    public static goToCountryEdit(
        countryId: string
    ) {
        const encodeId=encodeURIComponent(countryId);
        return `/main/manage-countries/edit/${encodeId}`;
    };

    public static goToCompanies(
        isCompanyCreated?:string
    ) {
        if (isCompanyCreated === 'false')
            return '/main/manage-companies'
        return `/main/manage-companies/${isCompanyCreated}`;
    };

    public static goToCompany(
        companyId: string,
        companyName: string
    ) {
        const encodeName=encodeURIComponent(companyName);
        const encodeId=encodeURIComponent(companyId);
        return `/main/manage-companies/${encodeName}/${encodeId}`;
    };

    public static goToCompanyEdit(
        companyId: string
    ) {
        const encodeId=encodeURIComponent(companyId);
        return `/main/manage-companies/edit/${encodeId}`;
    };

    public static goToFrameworks(
        isFrameworkCreated?:string
    ) {
        if (isFrameworkCreated === 'false')
            return '/main/manage-frameworks'
        return `/main/manage-frameworks/${isFrameworkCreated}`;
    };

    public static goToUsers(
        isUserCreated?:string
    ) {
        if (isUserCreated === 'false')
            return '/main/manage-users'
        return `/main/manage-users/${isUserCreated}`;
    };

    public static goToUser(
        userId:string,
        userName:string,
        userRole: string
    ) {
        const encodeName=encodeURIComponent(userName);
        const encodeId=encodeURIComponent(userId);
        const encodeRole=encodeURIComponent(userRole);
        return `/main/manage-users/${encodeName}/${encodeId}/${encodeRole}`;
    };

    public static goToAddEditExternalUser(
        addEdit:string,
        userId:string
    ) {
        const encodeName=encodeURIComponent(addEdit);
        const encodeId=encodeURIComponent(userId);
        return `/main/manage-users/external/${encodeName}/${encodeId}`;
    };

    public static goToAddEditInternalUser(
        addEdit:string,
        userId:string
    ) {
        const encodeName=encodeURIComponent(addEdit);
        const encodeId=encodeURIComponent(userId);
        return `/main/manage-users/internal/${encodeName}/${encodeId}`;
    };

    public static goToFramework(
        frameworkId:string,
        frameworkName:string
    ) {
        const encodeName=encodeURIComponent(frameworkName);
        const encodeId=encodeURIComponent(frameworkId);
        return `/main/manage-frameworks/${encodeName}/${encodeId}`;
    };

    public static goToManageFrameworks(
        isFrameworkDeleted?:string
    ) {
        if (isFrameworkDeleted === 'false')
            return '/main/manage-frameworks/'
        return `/main/manage-frameworks/${isFrameworkDeleted}`;
    };

}
