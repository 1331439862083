import { GlobalSettingsStatus } from './constants';

export interface GlobalSettings {
    id?: number;
    name: string;
    payload: {name?: string};
    status: boolean;
    updatedAt: Date;
    createdAt: Date;
}


export interface GlobalFlags {
    exportEnabled: boolean;
    readOnlyEnabled: boolean;
    isReadOnly: boolean;
}
export function isReadOnlyEnabled (globalSettings: GlobalSettings[]) {
    if(globalSettings !== undefined) {
        if(Object.values(globalSettings).length > 0) {
            const setting =  Object.values(globalSettings).find(s => s.name === GlobalSettingsStatus.READ_ONLY && s.status === true);
            if(setting === undefined) {
                return false;
            }
            return true;
        }
    }
    return false;
}


export function isNotificationsEnabled (globalSettings: GlobalSettings[]) {
    if(globalSettings !== undefined) {
        if(Object.values(globalSettings).length > 0) {
            const setting =  Object.values(globalSettings).find(s => s.name === GlobalSettingsStatus.NOTIFICATIONS && s.status === true);
            if(setting === undefined) {
                return false;
            }
            return true;
        }
    }
    return false;
}

export function isExportButtonEnabled (globalSettings: GlobalSettings[]) {
    if(globalSettings !== undefined) {
        if(Object.values(globalSettings).length > 0) {
            const setting =  Object.values(globalSettings).find(s => s.name === GlobalSettingsStatus.EXPORT_BUTTON && s.status === true);
            if(setting === undefined) {
                return false;
            }
            return true;
        }
    }
    return false;
}

export function visibilityExportButtonsByGlobalSettings(flags: GlobalFlags={exportEnabled:false,isReadOnly:false,readOnlyEnabled:false}){
   return flags.exportEnabled?(!(flags.readOnlyEnabled && flags.isReadOnly)):false;
}

export const isTargetScore = (globalSettings: GlobalSettings[]) => {
    const targetScoreObj = globalSettings.find((setting: GlobalSettings) => setting.name===GlobalSettingsStatus.TARGET_SCORE);
    if(!targetScoreObj){
        return false;
    } else {
        return targetScoreObj.status;
    }
};

export const isPointOneDecimalEnable = (globalSettings: GlobalSettings[]) => {
    const pointOneDecimalObj = globalSettings.find((setting: GlobalSettings) => setting.name===GlobalSettingsStatus.DECIMAL_POINT_ONE);
    if (!pointOneDecimalObj) {
        return false;
    }
    return pointOneDecimalObj.status;
};

export const isPowerBiReportsEnable = (globalSettings: GlobalSettings[]) => {
    const PowerBiReportsObj = globalSettings.find((setting: GlobalSettings) => setting.name===GlobalSettingsStatus.POWER_BI_REPORTS);
    if (!PowerBiReportsObj) {
        return false;
    }
    return PowerBiReportsObj.status;
};

export const isCesSearchEnable = (globalSettings: GlobalSettings[]) => {
    const cesSearchObj = globalSettings.find((setting: GlobalSettings) => setting.name===GlobalSettingsStatus.CES_SEARCH);
    if (!cesSearchObj) {
        return false;
    }
    return cesSearchObj.status;
};

export const isImportBenchmarkingEnable = (globalSettings: GlobalSettings[]) => {
    const pointOneDecimalObj = globalSettings.find((setting: GlobalSettings) => setting.name===GlobalSettingsStatus.IMPORT_BENCHMARKING);
    if (!pointOneDecimalObj) {
        return false;
    }
    return pointOneDecimalObj.status;
};