import { styled } from '@mui/material/styles';
import CustomButton from './Button';

const TransparentButtonInverted = styled(CustomButton)`
    && {
        background-color: transparent;
        cursor: pointer;
        border: 1.5px solid #ffffff;
        box-sizing: border-box;
        border-radius: 2px;
        color: #ffffff !important;
    }
`;

export default TransparentButtonInverted;
