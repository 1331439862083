import React from 'react';
import Modal from 'react-modal';
import { styled } from '@mui/material/styles';
import { ErrorModalProps } from '../modules/ManageUsers/userTypes';
import RedButton from '../components/base/Buttons/RedButton'
import { ADMIN_USER_DETAILS_CHANGE_ERROR_MESSAGE } from './Constant/messages';

export const ErrorModal: React.FC<ErrorModalProps> = ({
    openErrorModal,
    setOpenErrorModal,
    message = ''
}) => {
    const handleClose = () => {
        setOpenErrorModal(false);
    };
    return (
        <Modal
            isOpen={openErrorModal}
            shouldCloseOnOverlayClick
            ariaHideApp={false}
            style={customModalStyles}
            onRequestClose={() => setOpenErrorModal(false)}
        >
            <div style={{padding:'20px'}}>
                <p>
                    {message !== '' ? message : ADMIN_USER_DETAILS_CHANGE_ERROR_MESSAGE}
                </p>

                <div style={{ left:'calc(100% - 80px)', position:'relative' }}>
                    <SaveButton disabled={false} onClick={handleClose}>
                        Close
                    </SaveButton>
                </div>
            </div>

        </Modal>
    );
};
const customModalStyles = {
    content: {
        backgroundColor: '#fff',
        border: 'none',
        bottom: 'auto',
        maxHeight: 'calc(100% - 72px)',
        left: '50%',
        padding: 0,
        right: 'auto',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        zIndex: 99999,
        '&::after': {
            background: 'black',
            content: '""',
            height: '100%',
            left: 0,
            opacity: 0.5,
            position: 'absolute',
            right: 0,
            top: 0,
        },
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
};
const SaveButton = styled(RedButton)`
    margin-left: 10px
    width: 180px;
    font-size: 18px
    img {
        padding-right: 3px;
    }
`;
