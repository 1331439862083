import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useDropzone } from 'react-dropzone';
import iconXls from '../../assets/images/icon-xls.svg';


interface fileUpload {
    files: any;
    selectedFile: any;
    subTitle?: string;
    extraText?: string;
    errorUpload?: string;
    frameworkReadyToUpload: boolean;
}

const DropzoneUpload: React.FC<fileUpload> = ({
    files,
    selectedFile,
    subTitle,
    extraText,
    errorUpload,
    frameworkReadyToUpload 
}) => {
    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isDragActive
    } = useDropzone({
        maxFiles: 1,
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        },
        onDrop: acceptedFiles => {
            selectedFile(acceptedFiles);
        }
    });

    return (
        <ColumnWithMargin>
            <Dropzone {...getRootProps()}>
                {
                   frameworkReadyToUpload && isDragActive ? <p>Drop the file here...</p> : ''
                }
                <ThumbsContainer>
                    {files && files.map((file: any) => {
                        return (
                            <Thumb key={file.name}>
                                <ThumbInner>
                                    <CSVIcon src={iconXls} alt={file.name} aria-hidden={true}></CSVIcon> 
                                    <span style={{color: 'black'}}>{file.name}</span>
                                </ThumbInner>
                            </Thumb>
                        );
                    })}
                </ThumbsContainer>
                <input {...getInputProps()} />
                {subTitle && (<em>{subTitle}</em>)}
                {extraText && (<em>{extraText}</em>)}
                {errorUpload &&
                    (<ErrorText>{errorUpload}</ErrorText>)
                }
            </Dropzone>
        </ColumnWithMargin>
    );

}

const ColumnWithMargin = styled('div')`
    display: flex;
    flex-direction: column;
`;

const Dropzone = styled('div')`
display: flex;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
`;

const ThumbsContainer = styled('div')`
    display: flex;
    flexDirection: row;
    flexWrap: wrap;
    marginTop: 16;
`;

const Thumb = styled('div')`
    display: inline-flex;
    borderRadius: 2;
    border: 1px solid #eaeaea;
    marginBottom: 8;
    marginRight: 8;
    width: 100;
    height: 100;
    padding: 4;
    boxSizing: border-box;
    border-radius: 6px;
`;

const ThumbInner = styled('div')`
    display: flex;
    min-width: 0;
    overflow: hidden;
`;

const CSVIcon = styled('img')`
    width: 40px;
    margin: 5px;
    padding: 16px 12px;
    background: #eee;
    border: 1px dashed;
    border-radius: 6px;
`;

const ErrorText = styled('p')`
    color: #de3328
`;


export default DropzoneUpload;
