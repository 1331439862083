import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import dropdownArrow from '../../../assets/images/icon-drop-down-dark.svg';
import {
    List,
    ListItem,
    ListProps
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { 
    groupByParent,
    MenuButtonContainer, 
    TextContainer, 
    ImgContainer, 
    DropdownPopover, 
    FormControlUppercaseLabelWrapper, 
    ShowAllButton,
    ShowLessButton,
    CustomDivider
} from './dropdownHelpers';
import { DropdownModel, props } from './types';

import arrowIcon from '../../../assets/images/icon-indicator.svg';

const DropdownWithGroups: React.FC<props> = ({
    data,
    isOpen,
    setOpen,
    placeholder,
    buttonDisabled = false,
    selected,
    setSelected,
    labelText,
    errorMessage
}) => {
    const [showAllInParent, setParent] = useState<string[]>([]);
    const dataList = groupByParent(data);

    const anchorId = isOpen ? 'simple-popover' : undefined;
    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };


    useEffect(() => {
        if (!isOpen) {
            handleClose();
        }
    }, [isOpen]);

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <div>
            <DropdownLabel>{labelText}</DropdownLabel>
            <div>
                <MenuButtonContainer
                    background={buttonDisabled ? 'none' : '#ececec'}
                    disabled={buttonDisabled}
                    aria-describedby={anchorId}
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        handleClick(e);
                        setOpen(true);
                    }}
                >
                    <TextContainer

                    >
                        {placeholder}
                    </TextContainer>
                    <ImgContainer src={dropdownArrow} alt={dropdownArrow} />
                </MenuButtonContainer>

                <DropdownPopover
                    id={anchorId}
                    open={isOpen}
                    keepMounted={isOpen}
                    anchorEl={anchorEl}
                    onClose={() => setOpen(false)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <div style={{ padding: '16px' }}>
                        <CustomList
                            component="nav"
                            aria-label="main mailbox folders">
                            {dataList.map((item: DropdownModel, index: number) => (
                                <div key={index}>
                                    <ListSection>
                                        <FormControlUppercaseLabelWrapper  style={{width:'100%', margin:0}}>
                                        {item.parent}
                                        </FormControlUppercaseLabelWrapper>
                                    </ListSection>
                                    {item.children
                                        .slice(
                                            0,
                                            showAllInParent.includes(item.parent)
                                                ? item.children.length
                                                : 4
                                        )
                                        .map((child: string, i: number) => (
                                            <ListSection
                                                key={i}
                                                onClick={() => {
                                                    setSelected({child,parent:item.parent});
                                                    handleClose();
                                                }}
                                                style={
                                                    child === selected.child&&item.parent===selected.parent
                                                        ? {
                                                            backgroundColor:
                                                                '#f2f2f2',
                                                            marginLeft: 5
                                                        }
                                                        : { marginLeft: 5 }
                                                } >
                                                {child}
                                                {(child === selected.child&&item.parent===selected.parent) && (
                                                    <CheckIcon />
                                                )}
                                            </ListSection>
                                        ))}
                                    {item.children.length > 4 &&
                                        !showAllInParent.includes(item.parent) ? (
                                            <div style={{ margin: '16px 0' }}>
                                                <ShowAllButton
                                                    onClick={() =>
                                                        setParent([
                                                            ...showAllInParent,
                                                            item.parent
                                                        ])
                                                    }
                                                >
                                                    <img src={arrowIcon} alt="arrow for show more in dropdown"/>
                                                    Show all (
                                                    {item.children.length - 4})
                                                </ShowAllButton>
                                            </div>
                                        ) : null}

                                    {showAllInParent.includes(item.parent) &&
                                        <div style={{ margin: '16px 0' }}>
                                            <ShowLessButton
                                                onClick={() => {
                                                    const restOfParents = showAllInParent.filter((obj:string)=>obj!==item.parent)
                                                    setParent(restOfParents)
                                                }}
                                            >
                                                <img src={arrowIcon} alt="arrow for show more in dropdown"/>
                                                Show less
                                            </ShowLessButton>
                                        </div>
                                    }

                                    <CustomDivider variant="middle" />
                                </div>
                            ))}
                        </CustomList>
                    </div>
                </DropdownPopover>
            </div>
            { !!errorMessage &&
                <FormHelperText style={{color: 'red'}} error={errorMessage !== '' ? true : false}>
                    {errorMessage}
                </FormHelperText>
            }
        </div>
    );
};

const CustomList = styled((props: ListProps) => (
    <List 
        {...props}
    />
))({
    maxWidth: 300,
    minWidth: 150,
    margin: 0,
    padding: 0,
    backgroundColor: 'white',
    position: 'relative',
    overflow: 'auto',
    maxHeight: 450,
    display: 'flex',
    flexDirection: 'column',
    focus: {
        outline: 0
    }
});

const ListSection = styled(ListItem)({
    backgroundColor: 'inherit',
    padding: 0,
    margin: 0,
    height: '42px'
});

export default DropdownWithGroups;

const DropdownLabel = styled(InputLabel)`
    margin-bottom: 0.5em;
    margin-left: 0.3em;
    font-size: 14px !important;
`;
