export const YesNoDropdownValues = [
    {
        id: 0,
        name: "Yes"
    },
    {
        id: 1,
        name: "No"
    }
];

export const internalUserPermissionsValues = [
    {
        id:1,
        name:'ADMIN'
    },
    {
        id:2,
        name:'STANDARD'
    }
];

export const specialCharRegEx = /[-!@#$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/;