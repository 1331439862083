import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const CustomButton = styled(Button)`
    && {

        color: #000000DE !important;
        font-family: 'CustomHelveticaNeue';
        background-color: #f8f8f8;
        display: flex;
        align-items: center;
        outline: none;
        text-transform: none;
        border-radius: 2px;
    }
    .MuiOutlinedInput-input {
        font-family: 'CustomHelveticaNeue';
    }
`;

export default CustomButton;
