import React, {useContext} from 'react';
import { UserStore } from '../../context/user-store';
import { Navigate } from 'react-router-dom';
import { DynamicRoutes } from '../../routes/routes.enum';



const LoginCallback: React.FC = () => {

    const { state: { user } } = useContext(UserStore);

    if(user?.role){
        if(user.role === 'ADMIN') {
            return <Navigate to={{pathname: DynamicRoutes.goToCountries('false')}} />;
        } else if(user.role === 'STANDARD') {
            return <Navigate to={{ pathname: DynamicRoutes.goToCompanies('false') }} />;
        } else {
            return <Navigate to={{pathname: DynamicRoutes.goToCountries('false')}} />;
        }
    }

    return (
        <>
        </>
    );
};

export default LoginCallback;

