import React, { useState, useLayoutEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import axios from 'axios';
import './Reports.scss';
import { UserStore } from '../../context/user-store';

const Reports: React.FC = () => {

    const [token, setToken] = useState('');
    const [reportID, setReportID] = useState('');
    const [EmbedURL, setEmbedURL] = useState('');
    const { state: { user } } = useContext(UserStore);

    const dataSetID = '33148fbc-d8c9-4de5-bc13-6ad1cd4cbe82';
    let dataHTML = '';

    if (user.role === 'ADMIN') {
        dataHTML = (JSON.stringify({
            accessLevel: 'View',
            allowSaveAs: false
        }));
    } else {
        dataHTML = (JSON.stringify({
            accessLevel: 'View',
            allowSaveAs: false,
            identities: [
            {
                username: user.email,
                roles: ['Restrict by Country'],
                datasets: dataSetID
            }]

        }));
    }

    useLayoutEffect(() => {
        axios
            .get(
                window.__ENV__.REACT_APP_APP_URL.replace('/graphql', '') + '/dashboard/token'
                , {
                    params:
                        { dataBody: dataHTML }
                }
            )
            .then((response) => {
                setToken(response.data.token);
                setReportID(response.data.reportId);
                setEmbedURL(response.data.embedUrl);
            })
            .catch((error) => {
                console.log(error);
            });
    });

    return (
        <>
            <MainWrapper>
                <LinkWrapper />

                <SubHeading><h1>Dashboard</h1></SubHeading>
                <br />
                <Divider />
                <PowerBIEmbed
                    embedConfig={{
                        // hostname: "https://app.powerbigov.us/",
                        type: 'report', // Supported types: report, dashboard, tile, visual and qna
                        id: reportID,
                        embedUrl: EmbedURL,
                        accessToken: token,
                        tokenType: models.TokenType.Embed,
                        settings: {
                            panes: {
                                filters: {
                                    expanded: false,
                                    visible: false,
                                },
                                pageNavigation: {
                                    visible: true,
                                },
                            },
                            background: models.BackgroundType.Transparent,
                        },
                    }}
                    eventHandlers={
                        new Map([
                            [
                                'loaded',
                                function () {
                                    console.log('Report loaded');
                                },
                            ],
                            [
                                'rendered',
                                function () {
                                    console.log('Report rendered');
                                },
                            ],
                            [
                                'error',
                                function (event) {
                                    console.log('Error');
                                },
                            ],
                        ])
                    }
                    cssClassName={'report-style-class'}
                />
            </MainWrapper>
        </>
    );
};

const MainWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

const NameAndCTAs = styled('div')`
    display: flex;
    justify-content: space-between;
    min-height: 100px;
    padding: 5px 0;

    h2 {
        margin: 0;
    }
`;

const LinkWrapper = styled('div')`
    height: 46px;
`;

const SubHeading = styled('div')`
    padding: 0px;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: -6px;
`;

const Divider = styled('div')`
    border-top: 2px solid #808080;
    padding-bottom: 5px;
`;

export default Reports;
