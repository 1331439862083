import * as _ from 'lodash';

export const emailValidationRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
export const pwcEmailRegEx = /^[A-Z0-9._%+-]+@pwc+\.com$/i

const PermissionLabels = {
    ADMIN: 'Global admin',
    STANDARD: 'Standard user',
    EXTERNAL: 'External user',
    COUNTRY: 'Country admin'
};

//be careful, it mutates the array passed
export const sortAlphabetically = (objArray: any[], propertyName: string) => {
    return _.sortBy(objArray, [propertyName]);
};

export const convertPermissionLabels = (label:string) => {
    if(label === 'ADMIN' ||
        label === 'STANDARD' ||
        label === 'EXTERNAL' ||
        label === 'COUNTRY'
    ){
        return PermissionLabels[label]
    } else {
        return label
    }
}

// Sort Alphanumeric strings, and force a certain string to go to the top (eg. 'All')
// Based on dynamicSort from UtilsHelpers.tsx (pearl-assessment-web)
export const dynamicSortWithForceTop = (property: any, forceTop: string) => {
    return function (a: any, b: any) {
        if (b.name === forceTop) {
            return 1000;
        }
        if (a === b && a === 'number') {
            return a.Acr - b.Acr;
        }
        return a[property].localeCompare(b[property]);
    };
};


// File Downloader From Data
export const downloadFileFromData = (file_path: string, filename: string) => {
    const a = document.createElement('a');
    a.setAttribute('href', file_path);
    a.setAttribute('download', filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
