import axios, { AxiosError } from 'axios';
import {
    CountriesCsvParams,
    CountriesListPage,
    CountrySummary,
    FrameworksByCountryParams,
    UsersByCountryParams
} from './countryTypes';
import { SortDirection, TableCellTypes } from '../../components/Table/types';
import Router from '../../routes/router';
import { SortHandler } from '../ManageCompanies/companyTypes';
import { downloadFileFromData } from '../../utils/helpers';
import { MutationFunction } from '@apollo/client';

export const exportCountryCSV = (setSpinner: Function, params: CountriesCsvParams) => {
    setSpinner(true);
    const tkn = localStorage.getItem('pid-token');
    const clientLocal = localStorage.getItem('client');
    let clientUUID = null;
    if (clientLocal) {
        const clientParsed = JSON.parse(clientLocal);
        clientUUID = clientParsed.id;
    }
    axios
        .post(
            window.__ENV__.REACT_APP_APP_URL + '/admin/country/summaries/csv',
            { benchmarkingEnabled: params.benchmarkingEnabled, countryEnabled: params.countryEnabled },
            {
                headers: {
                    'pearl-client-uuid': clientUUID,
                    Authorization: `Bearer ${tkn}`
                }
            }
        )
        .then((response) => {
            setSpinner(false);
            downloadFileFromData(`data:text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(response.data)}`, 'Countries Report.csv');
        })
        .catch((error) => {
            setSpinner(false);
            console.log(error);
        });
};
export const exportUsersByCountryCSV = (setSpinner: Function, params: UsersByCountryParams) => {
    setSpinner(true);
    const tkn = localStorage.getItem('pid-token');
    const clientLocal = localStorage.getItem('client');
    let clientUUID = null;
    if (clientLocal) {
        const clientParsed = JSON.parse(clientLocal);
        clientUUID = clientParsed.id;
    }
    axios
        .post(
            window.__ENV__.REACT_APP_APP_URL + '/admin/country/'+params.countryId+'/user/summaries/csv',
            { countryId: params.countryId, countryUserEnabled: params.userEnabled, role: params.role },
            {
                headers: {
                    'pearl-client-uuid': clientUUID,
                    Authorization: `Bearer ${tkn}`
                }
            }
        )
        .then((response) => {
            setSpinner(false);
            downloadFileFromData(`data:text/csv;charset=utf-8,${response.data}`, 'UsersByCountry Report.csv');
        })
        .catch((error) => {
            setSpinner(false);
            console.log(error);
        });
};
export const exportFrameworksByCountryCSV = (setSpinner: Function, params: FrameworksByCountryParams) => {
    setSpinner(true);
    const tkn = localStorage.getItem('pid-token');
    const clientLocal = localStorage.getItem('client');
    let clientUUID = null;
    if (clientLocal) {
        const clientParsed = JSON.parse(clientLocal);
        clientUUID = clientParsed.id;
    }
    axios
        .post(
            window.__ENV__.REACT_APP_APP_URL + '/admin/country/'+params.countryId+'/framework/summaries/csv',
            { countryId: params.countryId, frameworkEnabled: params.frameworkEnabled },
            {
                headers: {
                    'pearl-client-uuid': clientUUID,
                    Authorization: `Bearer ${tkn}`
                }
            }
        )
        .then((response) => {
            setSpinner(false);
            downloadFileFromData(`data:text/csv;charset=utf-8,${response.data}`, 'FrameworksByCountry Report.csv');
        })
        .catch((error) => {
            setSpinner(false);
            console.log(error);
        });
};

export const disabledEnabledDropdownValues = [
    {
        id: 0,
        name: 'All'
    },
    {
        id: 1,
        name: 'Disabled'
    },
    {
        id: 2,
        name: 'Enabled'
    }
];

export const adminDropdownValues = [
    {
        id: 0,
        name: 'All'
    },
    {
        id: 1,
        name: 'ADMIN'
    },
    {
        id: 2,
        name: 'STANDARD'
    },
    {
        id: 3,
        name: 'EXTERNAL'
    }
];

export const internalPermissionsDropdownValues = [
    {
        id: 0,
        name: 'All'
    },
    {
        id: 1,
        name: 'ADMIN'
    },
    {
        id: 2,
        name: 'STANDARD'
    }
];

const handleCTA = (id: number, name: string) => {
    Router.goToCountry({ countryId: id.toString(), countryName: name });
};

export const setTableRows = (
    allSelectedBenchmarking: number[],
    handleToggleBenchmarkingChange: (cellID: number) => void,
    allSelectedCountries: number[],
    handleToggleActivateChange: (cellID: number) => void,
    countriesListPage?: CountriesListPage
) => {
    return (
        countriesListPage &&
        countriesListPage.items.map((country: CountrySummary) => {
            return {
                tableCells: [
                    {
                        cellName: country.name,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: country.usersCount.toString(),
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: country.frameworksCount.toString(),
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: country.clientsCount.toString(),
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        isCellSortable: false,
                        cellType: 'boolean' as TableCellTypes,
                        toggleLabels: {
                            trueLabel: 'ON',
                            falseLabel: 'OFF'
                        },
                        currentState: allSelectedBenchmarking.includes(country.id),
                        actionFunc: () => handleToggleBenchmarkingChange(country.id),
                        cellID: country.id
                    },
                    {
                        isCellSortable: false,
                        cellType: 'boolean' as TableCellTypes,
                        toggleLabels: {
                            trueLabel: 'Enabled',
                            falseLabel: 'Disabled'
                        },
                        currentState: allSelectedCountries.includes(country.id),
                        actionFunc: () => handleToggleActivateChange(country.id),
                        cellID: country.id
                    },
                    {
                        isCellSortable: false,
                        cellType: 'action' as TableCellTypes,
                        actionFunc: () => handleCTA(country.id, country.name)
                    }
                ]
            };
        })
    );
};

export const getHeaderData = (createSortHandler: SortHandler) => [
    {
        headerName: 'Country name',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc:()=> createSortHandler('name')
    },
    {
        headerName: 'Number of users',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc:()=> createSortHandler('usersCount')
    },
    {
        headerName: 'Active frameworks',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc:()=> createSortHandler('frameworksCount')
    },
    {
        headerName: 'Number of companies',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc:()=> createSortHandler('clientsCount')
    },
    {
        headerName: 'Benchmarking',
        isHeaderSortable: false
    },
    {
        headerName: 'Status',
        isHeaderSortable: false
    },
    {
        headerName: 'View',
        isHeaderSortable: false
    }
];

const editCountriesDTO = (
    allInteractedBenchmarking: number[],
    allInteractedCountries: number[],
    allSelectedCountries: number[],
    allSelectedBenchmarking: number[],
    countriesListPage?: CountriesListPage
) => {
    const mergedArray = [...allInteractedBenchmarking, ...allInteractedCountries];
    const allUniqueInteractedRows = Array.from(new Set(mergedArray));

    return allUniqueInteractedRows.map((row: number) => {
        let countryEnabledValue;
        let benchmarkingEnabled;

        if (allInteractedCountries.includes(row)) {
            countryEnabledValue = allSelectedCountries.includes(row);
        } else {
            if (!!countriesListPage) {
                const countryEnabledObj = countriesListPage.items.find((country: CountrySummary) => country.id === row);
                countryEnabledValue = countryEnabledObj ? countryEnabledObj.countryEnabled : null;
            }
        }
        if (allInteractedBenchmarking.includes(row)) {
            benchmarkingEnabled = allSelectedBenchmarking.includes(row);
        } else {
            if (!!countriesListPage) {
                const benchmarkingObj = countriesListPage.items.find((country: CountrySummary) => country.id === row);
                benchmarkingEnabled = benchmarkingObj ? benchmarkingObj.benchmarkingEnabled : null;
            }
        }
        return {
            id: row,
            countryEnabled: countryEnabledValue,
            benchmarkingEnabled
        };
    });
};

export const saveHandler = (
    allInteractedBenchmarking: number[],
    allInteractedCountries: number[],
    allSelectedBenchmarking: number[],
    allSelectedCountries: number[],
    editCountries: MutationFunction,
    setOpenSuccessModal: (state: boolean) => void,
    setAllInteractedBenchmarking: (benchmarkingIds: number[]) => void,
    setAllInteractedCountries: (countryIds: number[]) => void,
    setIsModalInternal: Function,
    countriesListPage?: CountriesListPage
) => {
    if (allInteractedBenchmarking.length === 0 && allInteractedCountries.length === 0) {
        return;
    }

    editCountries({
        variables: {
            data: editCountriesDTO(
                allInteractedBenchmarking,
                allInteractedCountries,
                allSelectedCountries,
                allSelectedBenchmarking,
                countriesListPage
            )
        }
    })
        .then(() => {
            setAllInteractedBenchmarking([]);
            setAllInteractedCountries([]);
            setIsModalInternal(true);
            setOpenSuccessModal(true);
        })
        .catch((err: AxiosError) => {
            console.log(err);
        });
};
