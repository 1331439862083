import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import {useParams} from 'react-router-dom';

import { RedButton, SearchField, Button, Tabs, ExportButton, SuccessModal, DropdownSingleSelection } from '../../../components';
import NewTable from '../../../components/Table/Table';
import Router from '../../../routes/router';

import { UserStore } from '../../../context/user-store';

import iconEdit from '../../../assets/images/icon-edit.svg';
import iconReset from '../../../assets/images/icon-reset.svg';
import iconSave from '../../../assets/images/icon-save.svg';
import arrowIcon from '../../../assets/images/icon-indicator.svg';

import { TableRow as TableRowInter } from '../../../components/Table/types';
import {
    CountryListModel,
    CountryListPage,
    ClientListModel,
    ClientListPage,
    UserDetailsProps,
    UsersOrder,
    CountrySummaries,
    ClientSummaries,
    CountryUserOverrides,
    CountryByUserFilters,
    ClientByUserFilters
} from '../userTypes';
import { COUNTRIES_BYUSER_MODEL, CLIENTS_BYUSER_MODEL, UPDATE_USER } from '../userQueries';
import {
    saveHandler,
    exportCountrySummariesByUserCSV,
    exportCompanySummariesByUserCSV
} from './userDetailHelpers';

import {
    getClientsTableHeader,
    getCountriesTableHeader,
    setTableRowsWithClient,
    setTableRowsWithCounrty,
    createTempCountryUserEnabled,
    disabledEnabledDropdownValues
} from '../userHelpers';
import { tabLabels, TabOptions } from '../userHelpers';
import iconDownload from '../../../assets/images/download-btn.svg';
import * as messages from '../../../utils/Constant/messages';
import { ErrorModal } from '../../../utils/ErrorModal';
import axios from 'axios';

const UserDetails: React.FC = () => {
    const params = useParams<UserDetailsProps>();
    const { state: { user } } = useContext(UserStore);
    const perPage = 50;
    const userName = decodeURIComponent(params.userName ?? '');
    const userId = decodeURIComponent(params.userId ?? '');
    const userRole = decodeURIComponent(params.userRole ?? '');
    const [userAccess, setUserAccess] = useState<string>('All');
    const [clientPage, setClientPage] = useState<number>(0);
    const [countryPage, setCountryPage] = useState<number>(0);
    const [countryTableRows, setCountryTableRows] = useState<TableRowInter[]>();
    const [clientTableRows, setClientTableRows] = useState<TableRowInter[]>();
    const [countrySummaries, setCountrySummaries] = useState<CountryListPage>();
    const [clientSummaries, setClientSummaries] = useState<ClientListPage>();
    const [searchCountryText, setSearchCountryText] = useState<string>('');
    const [searchClientText, setSearchClientText] = useState<string>('');
    const [currentTab, setCurrentTab] = useState<string>(TabOptions.COUNTRIES);
    const [order, setOrder] = useState<UsersOrder|undefined>();
    const [exportSpinner, setExportSpinner] = useState<boolean>(false);
    const [allSelectedIsAdmin, setAllSelectedIsAdmin] = useState<number[]>([]);
    const [allInteractedIsAdmin,setAllInteractedIsAdmin] = useState<number[]>([]);
    const [allSelectedCreateCampaigns, setAllSelectedCreateCampaigns] = useState<number[]>([]);
    const [allInteractedCreateCampaigns,setAllInteractedCreateCampaigns] = useState<number[]>([]);
    const [allSelectedCountryUserEnabled, setAllSelectedCountryUserEnabled] = useState<number[]>([]);
    const [allInteractedCountryUserEnabled,setAllInteractedCountryUserEnabled] = useState<number[]>([]);
    const [allSelectedClientUserEnabled, setAllSelectedClientUserEnabled] = useState<number[]>([]);
    const [allInteractedClientUserEnabled,setAllInteractedClientUserEnabled] = useState<number[]>([]);
    const [countryUserOverrides,setCountryUserOverrides] = useState<CountryUserOverrides[]>([]);
    const [editUser] = useMutation(UPDATE_USER);
    const [openErrorModal, setOpenErrorModal] = useState(false)
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [successModalMessage, setSuccessModalMessage] = useState(messages.USER_SUCCESS_MESSAGE);
    const [resetError, setResetError] = useState('');
    const [isResetLoading, setIsResetLoading] = useState<boolean>(false);

    const [clientFilters, setClientFilters] = useState<ClientByUserFilters>({
        userEnabled: undefined
    });
    const [countryfilters, setCountryFilters] = useState<CountryByUserFilters>({
        countryEnabled: undefined,
        countryUserEnabled: undefined
    });
    const [getCountriesSummaries,{loading:countryLoading}] = useLazyQuery<CountryListModel>(COUNTRIES_BYUSER_MODEL, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            setCountrySummaries(res.countrySummariesByUser);

            const allAdmins = res.countrySummariesByUser.items
                .filter((country: CountrySummaries) => country.countryAdmin && !allInteractedIsAdmin.includes(country.id))
                .map((country: CountrySummaries) => country.id);
            const allUniqueAdminIds = Array.from(new Set([...allAdmins, ...allSelectedIsAdmin]));
            setAllSelectedIsAdmin(allUniqueAdminIds);

            const allCampaignCreates = res.countrySummariesByUser.items
                .filter((country: CountrySummaries) => country.campaignCreateEnabled && !allInteractedCreateCampaigns.includes(country.id))
                .map((country: CountrySummaries) => country.id);
            const allUniqueCampaignCreatorIds = Array.from(new Set([...allCampaignCreates, ...allSelectedCreateCampaigns]));
            setAllSelectedCreateCampaigns(allUniqueCampaignCreatorIds);

            const allSelected = res.countrySummariesByUser.items
                .filter((country: CountrySummaries) => country.countryUserEnabled && !allInteractedCountryUserEnabled.includes(country.id))
                .map((country: CountrySummaries) => country.id);
            const allUniqueUserIds = Array.from(new Set([...allSelected, ...allSelectedCountryUserEnabled]));
            setAllSelectedCountryUserEnabled(allUniqueUserIds);
        },
        onError: (err) => {
            console.log(err);
        }
    });

    const [getClientSummaries,{loading:clientLoading}] = useLazyQuery<ClientListModel>(CLIENTS_BYUSER_MODEL, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            setClientSummaries(res.clientSummariesByUser);
            const allSelected = res.clientSummariesByUser.items
            .filter((client: ClientSummaries) => client.clientUserEnabled && !allInteractedClientUserEnabled.includes(client.clientId))
            .map((client: ClientSummaries) => client.clientId);
        const allUniqueUserIds = Array.from(new Set([...allSelected, ...allSelectedClientUserEnabled]));
        setAllSelectedClientUserEnabled(allUniqueUserIds);
        },
        onError: (err) => {
            console.log(err);
        }
    });

    useEffect(() => {
        if (currentTab === TabOptions.COUNTRIES) {
            getCountriesSummaries({
                variables: {
                    countryUserId: parseInt(userId),
                    pagination: {
                        order: !!order ? order : {field:'countryName',isAsc:true},
                        skip: countryPage * perPage,
                        take: perPage
                    },
                    search: searchCountryText,
                    filters: countryfilters
                }
            });
        }
        return undefined;
    }, [currentTab, countryPage,countryfilters, searchCountryText, order]);

    useEffect(() => {
        if (currentTab === TabOptions.COMPANIES) {
            getClientSummaries({
                variables: {
                    countryUserOverrides,
                    userId: parseInt(userId),
                    pagination: {
                        order: !!order ? order : {field:'companyName',isAsc:true},
                        skip: clientPage * perPage,
                        take: perPage
                    },
                    search: searchClientText,
                    filters: clientFilters
                }
            });
        }
        return undefined;
    }, [clientSummaries, currentTab, clientPage, clientFilters, searchClientText, order]);

   useEffect(()=>{
    const filter: ClientByUserFilters = {
        userEnabled: undefined
    };

    filter.userEnabled = userAccess === 'Enabled' || userRole === 'EXTERNAL' ? true : userAccess === 'Disabled' ? false : undefined;
    setClientPage(0);
    setClientFilters(filter);
   },[userAccess]);

   useEffect(() => {
        const filter: CountryByUserFilters = {
            countryUserEnabled: undefined
        };
        filter.countryUserEnabled = userAccess === 'Enabled' || userRole === 'EXTERNAL' ? true : userAccess === 'Disabled' ? false : undefined;
        setCountryPage(0)
        setCountryFilters(filter);
   },[userAccess]);
    useEffect(() => {
        setUserAccess('All');
     }, [currentTab]);

    useEffect(() => {
        if (!countrySummaries || currentTab !== TabOptions.COUNTRIES) {
            return undefined;
        }
        const rows = setTableRowsWithCounrty(countrySummaries,allSelectedIsAdmin,handleCountryAdminToggle,allSelectedCreateCampaigns,handleCreateCampignToggle,allSelectedCountryUserEnabled,handleCountryUserEnabledToggle, userRole, user.role);

        setCountryTableRows(rows);
    }, [countrySummaries, allSelectedIsAdmin, allSelectedCreateCampaigns, allSelectedCountryUserEnabled, openSuccessModal]);

    useEffect(() => {
        if (!clientSummaries || currentTab !== TabOptions.COMPANIES) {
            return undefined;
        }
        const rows = setTableRowsWithClient(clientSummaries,allSelectedClientUserEnabled,handleClientUserEnableToggle,userRole, user.role);

        setClientTableRows(rows);
    }, [clientSummaries, allSelectedClientUserEnabled, openSuccessModal]);

    useEffect(() => {
        const rows = createTempCountryUserEnabled(allSelectedCountryUserEnabled,allInteractedCountryUserEnabled);
        setCountryUserOverrides(rows);
    }, [allSelectedCountryUserEnabled,allInteractedCountryUserEnabled]);

    const handleCountryAdminToggle = (cellID: number) => {
        if (allSelectedIsAdmin.includes(cellID)) {
            const arrWithoutCell = allSelectedIsAdmin.filter((selFr: number) => {
                return selFr !== cellID;
            });
            setAllSelectedIsAdmin(arrWithoutCell);
        } else {
            setAllSelectedIsAdmin([...allSelectedIsAdmin, cellID]);
        }

        if (!allInteractedIsAdmin.includes(cellID)) {
            setAllInteractedIsAdmin([...allInteractedIsAdmin, cellID]);
        }
    };

    const handleCreateCampignToggle = (cellID: number) => {
        if (allSelectedCreateCampaigns.includes(cellID)) {
            const arrWithoutCell = allSelectedCreateCampaigns.filter((selFr: number) => {
                return selFr !== cellID;
            });
            setAllSelectedCreateCampaigns(arrWithoutCell);
        } else {
            setAllSelectedCreateCampaigns([...allSelectedCreateCampaigns, cellID]);
        }

        if (!allInteractedCreateCampaigns.includes(cellID)) {
            setAllInteractedCreateCampaigns([...allInteractedCreateCampaigns, cellID]);
        }
    };

    const handleClientUserEnableToggle = (cellID:number) => {
        if (allSelectedClientUserEnabled.includes(cellID)) {
            const arrWithoutCell = allSelectedClientUserEnabled.filter((selFr: number) => {
                return selFr !== cellID;
            });
            setAllSelectedClientUserEnabled(arrWithoutCell);
        } else {
            setAllSelectedClientUserEnabled([...allSelectedClientUserEnabled, cellID]);
        }
        if (!allInteractedClientUserEnabled.includes(cellID)) {
            setAllInteractedClientUserEnabled([...allInteractedClientUserEnabled, cellID]);
        }
    };

    const handleCountryUserEnabledToggle = (cellID:number) => {
        if (allSelectedCountryUserEnabled.includes(cellID)) {
            const arrWithoutCell = allSelectedCountryUserEnabled.filter((selFr: number) => {
                return selFr !== cellID;
            });
            setAllSelectedCountryUserEnabled(arrWithoutCell);
        } else {
            setAllSelectedCountryUserEnabled([...allSelectedCountryUserEnabled, cellID]);
        }
        if (!allInteractedCountryUserEnabled.includes(cellID)) {
            setAllInteractedCountryUserEnabled([...allInteractedCountryUserEnabled, cellID]);
        }
    };

    const editHandler = () => {
        if(userRole === 'EXTERNAL'){
            Router.goToAddEditExternalUser({userId, addEdit: 'Edit'});
        } else {
            Router.goToAddEditInternalUser({userId, addEdit: 'Edit'});
        }
    };

    const resetPasswordHandler = () => {
        if(userRole === 'EXTERNAL'){
            const tkn = localStorage.getItem('pid-token');
            const clientLocal = localStorage.getItem('client');
            let clientUUID = null;
            if (clientLocal) {
                const clientParsed = JSON.parse(clientLocal);
                clientUUID = clientParsed.id;
            }

            setIsResetLoading(true);
            setResetError('');

            axios.post(window.__ENV__.REACT_APP_APP_URL + '/admin/reset-password', {
                userId: userId
            }, {
                headers: {
                    'pearl-client-uuid': clientUUID,
                    'Authorization': `Bearer ${tkn}`
                }
            }).then((response) => {
                if (response && response.status === 200) {
                    if (response && response.data && response.data.ReturnCode !== 'Success') {
                        setResetError(response.data.ReturnCodeDescription);
                        setOpenErrorModal(true);
                    } else {
                        setSuccessModalMessage(messages.USER_PASSWORD_RESET_SUCCESS_MESSAGE);
                        setOpenSuccessModal(true);
                    }
                } else {
                    setResetError('Something went wrong');
                }
                setIsResetLoading(false);
            }).catch(error => {
                setResetError(error);
                setIsResetLoading(false);
            });
        }
    };

    const saveChanges = () => {
        if(user.id === parseInt(userId)){
            setOpenErrorModal(true);
        }
        else{
            setSuccessModalMessage(messages.USER_SUCCESS_MESSAGE);
            saveHandler(allInteractedIsAdmin,
                allInteractedCreateCampaigns,
                allInteractedCountryUserEnabled,
                allInteractedClientUserEnabled,
                editUser,
                userId,
                allSelectedIsAdmin,
                allSelectedCreateCampaigns,
                allSelectedCountryUserEnabled,
                allSelectedClientUserEnabled,
                setOpenSuccessModal,
                setAllInteractedIsAdmin,
                setAllInteractedCreateCampaigns,
                setAllInteractedCountryUserEnabled,
                setAllInteractedClientUserEnabled,
                countrySummaries
            );
     } };

    const createSortHandler = (property: string) => {
        if(order&&order.field===property)
        {
            setOrder({field:property,isAsc:!order.isAsc});
        }
        else{
            setOrder({field:property,isAsc:true});
        }

    };

    const handleChangeClientPage = (event: unknown, newPage: number) => {
        setClientPage(newPage);
    };

    const handleChangeCountryPage = (event: unknown, newPage: number) => {
        setCountryPage(newPage);
    };

    const handleChangeTabs = (newTab: string) => {
        setCurrentTab(newTab);
    };

    const countriesTableHeader = getCountriesTableHeader(createSortHandler, userRole, undefined, user.role);
    const clientsTableHeader = getClientsTableHeader(createSortHandler, userRole, user.role);

    const countryTablePagination = {
        rowsPerPage: perPage,
        currentPage: countryPage,
        handleChangePage: handleChangeCountryPage,
        totalCount: countrySummaries && countrySummaries.total
    };

    const clientTablePagination = {
        rowsPerPage: perPage,
        currentPage: clientPage,
        handleChangePage: handleChangeClientPage,
        totalCount: clientSummaries && clientSummaries.total
    };

    const countrySearchCallback = (value: string) => {
        setSearchCountryText(value);
        setCountryPage(0);
    };

    const clientSearchCallback = (value: string) => {
        setSearchClientText(value);
        setClientPage(0);
    };

    const isDisabled =
        allInteractedIsAdmin.length === 0 &&
        allInteractedCreateCampaigns.length === 0 &&
        allInteractedCountryUserEnabled.length === 0 &&
        allInteractedClientUserEnabled.length === 0;

    return (
        <>
            <MainWrapper>
                <div>
                    <BackLink
                        onClick={() => Router.goToUserManagement()}
                    >
                        <img src={arrowIcon} alt="back icon" />
                        Back to users
                    </BackLink>
                    <TitleAndButtons>
                        <CountryText>
                            <h1>{userName}</h1>
                        </CountryText>
                        <ButtonsWrapper>
                            {(userRole === 'EXTERNAL') &&
                                <EditButton disabled={isResetLoading ? true : false} onClick={resetPasswordHandler} style={{
                                    marginRight: 10
                                }}>
                                    <img src={iconReset} alt="reset user icon" />
                                    Reset password
                                </EditButton>
                            }
                            {!((userRole === 'ADMIN' || userRole === 'STANDARD') && user.role === 'STANDARD') &&
                                <EditButton disabled={false} onClick={editHandler}>
                                    <img src={iconEdit} alt="edit user icon" />
                                    Edit user
                                </EditButton>
                            }
                            <SaveButton disabled={isDisabled} onClick={saveChanges}>
                                <img src={iconSave} alt="save user icon" />
                                Save changes
                            </SaveButton>
                        </ButtonsWrapper>
                    </TitleAndButtons>
                    <Tabs tabLabels={tabLabels} activeTab={currentTab} action={handleChangeTabs} />
                    {currentTab === TabOptions.COUNTRIES && (
                        <>
                            {userRole !== 'EXTERNAL' &&
                            <TableFiltersAndSearch>
                                <TableFilters>
                                    <DropdownSingleSelection
                                        labelText="User access"
                                        isDisabled={false}
                                        selectedValue={userAccess}
                                        setValue={setUserAccess}
                                        allValues={disabledEnabledDropdownValues}
                                        width="14em"
                                    />
                                </TableFilters>
                                <SearchAndSave>
                                {exportSpinner ? (
                                        <CircularProgress />
                                    ) : (
                                        <ExportButton onClick={()=>exportCountrySummariesByUserCSV(setExportSpinner,{userId,countryEnabled:countryfilters.countryEnabled,countryUserEnabled:countryfilters.countryUserEnabled})}>
                                            <img src={iconDownload} alt="download" />
                                        </ExportButton>
                                    )}
                                    <SearchField
                                        placeholder="Search"
                                        callback={(value: string) => countrySearchCallback(value)}
                                    />
                                </SearchAndSave>
                            </TableFiltersAndSearch>
                            }
                            {countryLoading ? (
                                <CircularProgress />
                            ) :
                                <CustomTable
                                    header={countriesTableHeader}
                                    tablePagination={countryTablePagination}
                                    rows={countryTableRows}
                                />
                            }
                        </>
                    )}
                    {currentTab === TabOptions.COMPANIES && (
                        <>
                            {userRole !== 'EXTERNAL' &&
                                <TableFiltersAndSearch>
                                    <TableFilters>
                                        <DropdownSingleSelection
                                            labelText="User access"
                                            isDisabled={false}
                                            selectedValue={userAccess}
                                            setValue={setUserAccess}
                                            allValues={disabledEnabledDropdownValues}
                                            width="14em"
                                        />
                                    </TableFilters>
                                    <SearchAndSave>
                                    {exportSpinner ? (
                                            <CircularProgress />
                                        ) : (
                                            <ExportButton onClick={()=>exportCompanySummariesByUserCSV(setExportSpinner,{userId,userEnabled:clientFilters.userEnabled})}>
                                                <img src={iconDownload} alt="download" />
                                            </ExportButton>
                                        )}
                                        <SearchField
                                            placeholder="Search"
                                            callback={(value: string) => clientSearchCallback(value)}
                                        />
                                    </SearchAndSave>
                                </TableFiltersAndSearch>
                            }
                            {clientLoading?(
                                <CircularProgress />
                            ):
                            <CustomTable
                                header={clientsTableHeader}
                                tablePagination={clientTablePagination}
                                rows={clientTableRows}
                            />}
                        </>
                    )}
                </div>
            </MainWrapper>
            <SuccessModal
                openState={openSuccessModal}
                hideDuration={6000}
                setOpen={setOpenSuccessModal}
                message={successModalMessage}
            />
            <ErrorModal
                openErrorModal={openErrorModal}
                message={resetError}
                setOpenErrorModal={setOpenErrorModal}
            />
        </>
    );
};

const CustomTable = styled(NewTable)`
    && {
        && {
            &&.MuiTableContainer-root {
                max-height: 150px;
            }
        }
    }
`;

const MainWrapper = styled('div')`
    display: flex;
    flex-direction: column;
`;

const EditButton = styled(Button)`
    width: 180px;
    font-size: 16px !important;
    border: 1.5px solid #2d2d2d !important;
    box-sizing: border-box;
    background-color: #ffffff !important;
`;

const SaveButton = styled(RedButton)`
    margin-left: 10px !important;
    width: 180px;
    font-size: 16px !important;
    img {
        padding-right: 3px;
    }
`;

const ButtonsWrapper = styled('div')`
    display: flex;
    height: 50%;
`;

const TitleAndButtons = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 2em;
`;

const TableFiltersAndSearch = styled('div')`
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
`;

const TableFilters = styled('div')`
    display: flex;
`;

const SearchAndSave = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

const CountryText = styled('div')`
    font-style: normal;
    font-weight: bold;
    padding: 5px 0;
    h1 {
        margin: 0;
    }
`;

const BackLink = styled('div')`
    cursor: pointer;
    display: flex;

    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 2em;
    text-decoration: underline;
    line-height: 1.5em;
    img {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }
`;

export default UserDetails;
