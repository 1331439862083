import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useNavigate, useParams } from 'react-router';

import RedButton from '../../../components/base/Buttons/RedButton';
import Button from '../../../components/base/Buttons/Button';
import Tabs from '../../../components/Tabs/Tab';
import ExportButton from '../../../components/base/Buttons/ExportButton';
import SearchField from '../../../components/base/SearchField/SearchField';
import SuccessModal from '../../../components/base/SuccessModal/SuccessModal';
import DropdownSingleSelection from '../../../components/base/Dropdowns/DropdownSingleSelection';


import NewTable from '../../../components/Table/Table';
import Router from '../../../routes/router';

import iconSave from '../../../assets/images/icon-save.svg';
import iconEdit from '../../../assets/images/icon-edit.svg';
import arrowIcon from '../../../assets/images/icon-indicator.svg';
import { RotateRight, RotateLeft, DeleteForever } from '@mui/icons-material';

import { TableRow as TableRowInter } from '../../../components/Table/types';

import {
    exportUsersByCompanyCSV,
    exportFrameworksByCompanyCSV,
    disabledEnabledDropdownValues,
    adminDropdownValues
} from '../companyHelpers';

import {
    FrameworksResponse,
    FrameworkSummariesByCompany,
    Framework,
    UserListModel,
    UserSummaries,
    UserListPage,
    CompanyDetailsProps,
    UserFilters,
    FrameworkByCompanyFilters,
    CompanyOrder
} from '../companyTypes';
import iconDownload from '../../../assets/images/download-btn.svg';
import { COMPANY_DETAILS_MIN, DELETE_COMPANY, EDIT_COMPANY, FRAMEWORK_SUMMARIES_BY_COMPANY_MODEL, USERS_BY_COMPANY_MODEL } from '../companyQueries';
import {
    getFrameworksTableHeader,
    getUsersTableHeader, saveHandler,
    setTableRowsWithFramework,
    setTableRowsWithUser,
    TabOptions
} from './companyDetailsHelpers';
import * as messages from '../../../utils/Constant/messages';
import { UserStore } from '../../../context/user-store';
import { CompanyActiveStatus } from '../../../utils/constants';
import { getLocalStorageItem, setLocalStorageItem } from '../../../utils/local-storage';

const CompanyDetails: React.FC = () => {
    const params = useParams<CompanyDetailsProps>();
    const perPage = 50;
    const companyName = decodeURIComponent(params.companyName ?? '');
    const companyId = decodeURIComponent(params.companyId ?? '');

    const [selectedPermission, setPermission] = useState<string>('All');
    const [selectedFrameworkAccess, setFrameworkAccess] = useState<string>('All');
    const [userAccess, setUserAccess] = useState<string>('All');
    const [frameworkPage, setFrameworkPage] = useState<number>(0);
    const [userPage, setUserPage] = useState<number>(0);
    const [frameworkTableRows, setFrameworkTableRows] = useState<TableRowInter[]>();
    const [userTableRows, setUserTableRows] = useState<TableRowInter[]>();
    const [frameworkSummaries, setFrameworkSummaries] = useState<FrameworkSummariesByCompany>();
    const [userSummaries, setUserSummaries] = useState<UserListPage>();
    const [searchFrameworksText, setSearchFrameworksText] = useState<string>('');
    const [searchUserText, setSearchUsersText] = useState<string>('');
    const [currentTab, setCurrentTab] = useState<string>(TabOptions.FRAMEWORKS);
    const [allSelectedFrameworks, setAllSelectedFrameworks] = useState<number[]>([]);
    const [allSelectedUsers, setAllSelectedUsers] = useState<number[]>([]);
    const [allCreateCampaign, setAllCreateCampaign] = useState<number[]>([]);
    const [allInteractedFrameworks, setAllInteractedFrameworks] = useState<number[]>([]);
    const [allInteractedUsers, setAllInteractedUsers] = useState<number[]>([]);
    const [allInteractedCreateCampaign, setAllInteractedCreateCampaign] = useState<number[]>([]);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [firstLoading, setFirstLoading] = useState(true);
    const [companyIsActive, setCompanyIsActive] = useState<boolean>(true);
    const [openCompanyDeleteDialog, setOpenCompanyDeleteDialog] = useState<boolean>(false);
    const [companyIsDeleted, setCompanyIsDeleted] = useState<boolean>(false);
    const [agreeBtnLoading, setAgreeBtnLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const [getCompany] = useLazyQuery(COMPANY_DETAILS_MIN, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            const countryAdmin = user.countryAdminCountries?.find(countryData => countryData?.id === parseInt(res.client.countryId));
            setClientPresentForUser(user.role === 'ADMIN'|| (countryAdmin? true : false));
            setFirstLoading(false);
            setCompanyIsActive(res.client.clientEnabled);
        },
        onError: (err) => {
            setFirstLoading(false);
            console.log(err);
        }
    });

    const {  state: { user } } = useContext(UserStore);
    const [clientPresentForUser, setClientPresentForUser] = useState<boolean>(false);

    const [editCompany] = useMutation(EDIT_COMPANY);
    const [deleteCompany] = useMutation(DELETE_COMPANY);
    const [exportSpinner, setExportSpinner] = useState<boolean>(false);
    const [frameworkFilters, setFramewrokFilters] = useState<FrameworkByCompanyFilters>({
        frameworkEnabled: null
    });
    const [userFilters, setUserFilters] = useState<UserFilters>({
        role: 'All',
        userEnabled: undefined
    });
    const [orderFrameworks, setOrderFrameworks] = useState<CompanyOrder|undefined>();
    const [orderUsers, setOrderUsers] = useState<CompanyOrder|undefined>();

    const [getFrameworkSummaries,{loading:frameworkLoading}] = useLazyQuery<FrameworksResponse>(FRAMEWORK_SUMMARIES_BY_COMPANY_MODEL, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            setFrameworkSummaries(res.frameworkSummariesByCompany);

            const allEnabledFrameworks = res.frameworkSummariesByCompany.items
                .filter(
                    (framework: Framework) =>
                        framework.frameworkEnabled && !allInteractedFrameworks.includes(framework.frameworkId)
                )
                .map((framework: Framework) => framework.frameworkId);
            const allUniqueSelectedIds = Array.from(new Set([...allEnabledFrameworks, ...allSelectedFrameworks]));
            setAllSelectedFrameworks(allUniqueSelectedIds);
        },
        onError: (err) => {
            console.log(err);
        }
    });

    const [getUserSummaries,{loading:userLoading}] = useLazyQuery<UserListModel>(USERS_BY_COMPANY_MODEL, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            setUserSummaries(res.usersByClientPage);
            const allSelected = res.usersByClientPage.items
                .filter((users: UserSummaries) => users.userEnabled && !allInteractedUsers.includes(users.id))
                .map((users: UserSummaries) => users.id);
            const allUniqueUserIds = Array.from(new Set([...allSelected, ...allSelectedUsers,]));
            setAllSelectedUsers(allUniqueUserIds);

            const allSelectedCampaign = res.usersByClientPage.items
                .filter((users: UserSummaries) => users.createCampaign && !allInteractedCreateCampaign.includes(users.id))
                .map((users: UserSummaries) => users.id);
            const allUniqueCampaignUserIds = Array.from(new Set([...allSelectedCampaign, ...allCreateCampaign]));
            setAllCreateCampaign(allUniqueCampaignUserIds);
        },
        onError: (err) => {
            console.log(err);
        }
    });

    useEffect(() => {
        setFramewrokFilters({
            frameworkEnabled:
                selectedFrameworkAccess === 'Enabled' ? true : selectedFrameworkAccess === 'Disabled' ? false : null
        });
    }, [selectedFrameworkAccess]);

    useEffect(() => {
        getCompany({
            variables: {
                id: parseInt(companyId)
            }
        });
    }, [companyId]);

    useEffect(() => {
        setUserFilters({
            role: selectedPermission === 'All' ? null : selectedPermission,
            userEnabled: userAccess === 'Enabled' ? true : userAccess === 'Disabled' ? false : undefined
        });
    }, [selectedPermission, userAccess]);

    useEffect(() => {
        if (currentTab === TabOptions.FRAMEWORKS) {
            getFrameworkSummaries({
                variables: {
                    clientId: parseInt(companyId),
                    pagination: {
                        order: !!orderFrameworks ? orderFrameworks : {field:'frameworkName',isAsc:true},
                        skip: frameworkPage * perPage,
                        take: perPage
                    },
                    search: searchFrameworksText,
                    filters: frameworkFilters
                }
            });
        }
    }, [currentTab, frameworkPage, frameworkFilters, searchFrameworksText, orderFrameworks]);

    useEffect(() => {
        if (currentTab === TabOptions.USERS ) {
            getUserSummaries({
                variables: {
                    clientId: parseInt(companyId),
                    pagination: {
                        order: !!orderUsers ? orderUsers : {field:'name',isAsc:true},
                        skip: userPage * perPage,
                        take: perPage
                    },
                    search: searchUserText,
                    filters: userFilters
                }
            });
        }
    }, [currentTab, userPage, userFilters, searchUserText, orderUsers]);

    useEffect(() => {
        if (!frameworkSummaries || currentTab !== TabOptions.FRAMEWORKS) {
            return;
        }
        const rows = setTableRowsWithFramework(
            allSelectedFrameworks,
            handleFrameworksToggleChange,
            frameworkSummaries
        );

        setFrameworkTableRows(rows);
    }, [frameworkSummaries, allSelectedFrameworks, openSuccessModal]);

    useEffect(() => {
        if (!userSummaries || currentTab !== TabOptions.USERS) {
            return;
        }
        const rows = setTableRowsWithUser(
            allSelectedUsers,
            allCreateCampaign,
            handleUsersEnabledToggleChange,
            handleCreateCampaignToggleChange,
            userSummaries
        );
        setUserTableRows(rows);
    }, [userSummaries, allSelectedUsers, openSuccessModal,allCreateCampaign]);

    const editHandler = () => {
        Router.goToCompanyEdit({ companyId });
    };

    const saveChanges = () => {
        saveHandler(
            allInteractedFrameworks,
            allSelectedFrameworks,
            allInteractedUsers,
            allSelectedUsers,
            allCreateCampaign,
            allInteractedCreateCampaign,
            companyIsActive,
            editCompany,
            setOpenSuccessModal,
            companyId,
            companyName,
            setAllInteractedFrameworks,
            setAllInteractedUsers,
            setAllInteractedCreateCampaign,
            setCompanyIsActive
        );
    };

    const createSortFrameworksHandler = (property: string) => {
        if(orderFrameworks && orderFrameworks.field===property)
        {
            setOrderFrameworks({field:property,isAsc:!orderFrameworks.isAsc});
        }
        else{
            setOrderFrameworks({field:property,isAsc:true});
        }
    };

    const createSortUsersHandler = (property: string) => {
        if(orderUsers && orderUsers.field===property)
        {
            setOrderUsers({field:property,isAsc:!orderUsers.isAsc});
        }
        else{
            setOrderUsers({field:property,isAsc:true});
        }
    };

    const handleChangeFrameworkPage = (event: unknown, newPage: number) => {
        setFrameworkPage(newPage);
    };

    const handleChangeUsersPage = (event: unknown, newPage: number) => {
        setUserPage(newPage);
    };

    const handleChangeTabs = (newTab: string) => {
        setCurrentTab(newTab);
    };

    const handleFrameworksToggleChange = (cellID:number) => {
        if (allSelectedFrameworks.includes(cellID)) {
            const arrWithoutCell = allSelectedFrameworks.filter((selFr: number) => selFr !== cellID);
            setAllSelectedFrameworks(arrWithoutCell);
        } else {
            setAllSelectedFrameworks([...allSelectedFrameworks, cellID]);
        }

        if (!allInteractedFrameworks.includes(cellID)) { setAllInteractedFrameworks([...allInteractedFrameworks, cellID]); }
    };

    const handleUsersEnabledToggleChange = (cellID:number) => {
        if (allSelectedUsers.includes(cellID)) {
            const arrWithoutCell = allSelectedUsers.filter((selFr: number) => selFr !== cellID);

            setAllSelectedUsers(arrWithoutCell);
        } else {
            setAllSelectedUsers([...allSelectedUsers, cellID]);
        }
        if (!allInteractedUsers.includes(cellID)) { setAllInteractedUsers([...allInteractedUsers, cellID]); }
    };

    const handleCreateCampaignToggleChange = (cellID:number) => {
        if (allCreateCampaign.includes(cellID)) {
            const arrWithoutCell = allCreateCampaign.filter((selFr: number) => selFr !== cellID);
            setAllCreateCampaign(arrWithoutCell);
        } else {
            setAllCreateCampaign([...allCreateCampaign, cellID]);
        }
        if (!allInteractedCreateCampaign.includes(cellID)) { setAllInteractedCreateCampaign([...allInteractedCreateCampaign, cellID]); }
    };

    const frameworkSearchCallback = (value: string) => {
        setSearchFrameworksText(value);
        setFrameworkPage(0);
    };

    const userSearchCallback = (value: string) => {
        setSearchUsersText(value);
        setUserPage(0);
    };

    const tabLabels = [TabOptions.FRAMEWORKS, TabOptions.USERS];

    const frameworksTableHeader = getFrameworksTableHeader(createSortFrameworksHandler);
    const usersTableHeader = getUsersTableHeader(createSortUsersHandler);

    const userTablePagination = {
        rowsPerPage: perPage,
        currentPage: userPage,
        handleChangePage: handleChangeUsersPage,
        totalCount:
            currentTab === TabOptions.FRAMEWORKS
                ? frameworkSummaries && frameworkSummaries.total
                : userSummaries && userSummaries.total
    };

    const frameworkTablePagination = {
        rowsPerPage: perPage,
        currentPage: frameworkPage,
        handleChangePage: handleChangeFrameworkPage,
        totalCount:
            currentTab === TabOptions.FRAMEWORKS
                ? frameworkSummaries && frameworkSummaries.total
                : userSummaries && userSummaries.total
    };

    const handleCompanyActiveStatus = (status: string) => {
        setCompanyActiveStatus(status);
    }

    useEffect(() => {
        if (firstLoading) {
            return;
        }
        saveChanges();
    }, [companyIsActive]);

    const setCompanyActiveStatus = (status: string): CompanyActiveStatus => {
        if (status === CompanyActiveStatus.ACTIVATE) {
            setCompanyIsActive(true);
            return CompanyActiveStatus.ACTIVATE;
        } else {
            setCompanyIsActive(false);
            return CompanyActiveStatus.DEACTIVATE;
        }
    }

    const handleClose = () => {
        setOpenCompanyDeleteDialog(false);
        setAgreeBtnLoading(false);
    };

    const handleAgree = () => {
        setCompanyIsDeleted(false);
        setOpenSuccessModal(false);
        setAgreeBtnLoading(true);

        deleteCompany({
            variables: {
                id: parseInt(companyId),
            }
        }).then((res: any) => {
            if (res.data.deleteClient.deleted) {
                const companyDeletionValue = getLocalStorageItem('company-deletion');
                const companiesToBeDeleted = (companyDeletionValue !== undefined && companyDeletionValue !== '') ? JSON.parse(companyDeletionValue) : [];
                companiesToBeDeleted.push(parseInt(companyId));
                setLocalStorageItem('company-deletion', JSON.stringify(companiesToBeDeleted));

                setCompanyIsDeleted(res.data.deleteClient.deleted || false);
                setOpenSuccessModal(res.data.deleteClient.deleted || false);
                handleClose();
                navigate('/main/manage-companies');
            }
        }).catch((error: any) => {
            setCompanyIsDeleted(false);
            setOpenSuccessModal(false);
            handleClose();
        })
    }

    return (
        clientPresentForUser ?
        <>
            <MainWrapper>
                <div>
                    <BackLink onClick={() => Router.goToCompaniesManagement()}>
                        <img src={arrowIcon} alt="back icon" />
                        Back to Companies
                    </BackLink>
                    <TitleAndButtons>
                        <CountryText>
                            <h1>{companyName}</h1>
                        </CountryText>
                        <ButtonsWrapper>
                            {
                                companyIsActive ?
                                <>
                                    <EditButton disabled={false} onClick={editHandler}>
                                        <img src={iconEdit} alt="edit company icon" />
                                        Edit Company
                                    </EditButton>
                                    <SaveButton
                                        disabled={allInteractedFrameworks.length === 0 && allInteractedUsers.length === 0 && allInteractedCreateCampaign.length === 0}
                                        onClick={saveChanges}>
                                        <img src={iconSave} alt="add company icon" />
                                        Save Changes
                                    </SaveButton>
                                </> : null
                            }
                            { companyIsActive ?
                                <ActiveDeactiveButton disabled={false} onClick={() => {
                                    handleCompanyActiveStatus(CompanyActiveStatus.DEACTIVATE)
                                }}>
                                    <RotateLeft></RotateLeft>
                                    Deactive Company
                                </ActiveDeactiveButton> :
                                <>
                                    <ActiveDeactiveButton disabled={false} onClick={() => {
                                        handleCompanyActiveStatus(CompanyActiveStatus.ACTIVATE)
                                    }}>
                                        <RotateRight></RotateRight>
                                        Active Company
                                    </ActiveDeactiveButton>
                                    <DeleteButton disabled={false} onClick={() => {
                                        setOpenCompanyDeleteDialog(true);
                                    }}>
                                        <DeleteForever></DeleteForever>
                                        Delete Company
                                    </DeleteButton>
                                </>
                            }
                        </ButtonsWrapper>
                    </TitleAndButtons>
                    <TabContainer>
                        <Tabs tabLabels={tabLabels} activeTab={currentTab} action={handleChangeTabs} />
                        {companyIsActive === false &&
                            <NotActiveOverlay>
                                <NotActiveTitle>
                                    Company is Not Active.
                                </NotActiveTitle>
                            </NotActiveOverlay>
                        }
                        {currentTab === TabOptions.FRAMEWORKS && (
                            <>
                                <TableFiltersAndSearch>
                                    <TableFilters>
                                        <DropdownSingleSelection
                                            labelText="Framework access"
                                            isDisabled={false}
                                            selectedValue={selectedFrameworkAccess}
                                            setValue={setFrameworkAccess}
                                            allValues={disabledEnabledDropdownValues}
                                            width="10em"
                                        />
                                    </TableFilters>
                                    <SearchAndSave>
                                    {exportSpinner ? (
                                            <CircularProgress />
                                        ) :
                                        <ExportButton
                                            onClick={() => exportFrameworksByCompanyCSV(setExportSpinner, { frameworkFilters, companyId })}
                                        >
                                            <img src={iconDownload} alt="download" />
                                        </ExportButton>}
                                        <SearchField
                                            placeholder="Search"
                                            callback={(value: string) => frameworkSearchCallback(value)}
                                        />
                                    </SearchAndSave>
                                </TableFiltersAndSearch>
                                {frameworkLoading?(
                                            <CircularProgress />
                                        ):
                                <CustomTable
                                    header={frameworksTableHeader}
                                    tablePagination={frameworkTablePagination}
                                    rows={frameworkTableRows}
                                />}
                            </>
                        )}
                        {currentTab === TabOptions.USERS && (
                            <>
                                <TableFiltersAndSearch>
                                    <TableFilters>
                                        <DropdownSingleSelection
                                            labelText="Permission"
                                            isDisabled={false}
                                            selectedValue={selectedPermission}
                                            setValue={setPermission}
                                            allValues={adminDropdownValues}
                                            width="10em"
                                        />
                                        <DropdownSingleSelection
                                            labelText="User access"
                                            isDisabled={false}
                                            selectedValue={userAccess}
                                            setValue={setUserAccess}
                                            allValues={disabledEnabledDropdownValues}
                                            width="10em"
                                        />
                                    </TableFilters>
                                    <SearchAndSave>
                                        {exportSpinner ? (
                                            <CircularProgress />
                                        ) : (
                                            <ExportButton
                                                onClick={() => exportUsersByCompanyCSV(setExportSpinner, { userFilters, companyId })}
                                            >
                                                <img src={iconDownload} alt="download" />
                                            </ExportButton>
                                        )}
                                        <SearchField
                                            placeholder="Search"
                                            callback={(value: string) => userSearchCallback(value)}
                                        />
                                    </SearchAndSave>
                                </TableFiltersAndSearch>
                                {userLoading?(
                                            <CircularProgress />
                                        ):
                                <CustomTable
                                    header={usersTableHeader}
                                    tablePagination={userTablePagination}
                                    rows={userTableRows}
                                />}
                            </>
                        )}
                    </TabContainer>
                </div>
            </MainWrapper>
            <SuccessModal
                openState={openSuccessModal}
                hideDuration={6000}
                setOpen={setOpenSuccessModal}
                message={companyIsDeleted ? messages.COMPANY_DELETION_MESSAGE : messages.COMPANY_SUCCESS_MESSAGE}
            />
            <Dialog
                open={openCompanyDeleteDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Delete Company Permanently?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {messages.COMPANY_PERMANET_DELETION_PROMPT_MESSAGE}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" disabled={agreeBtnLoading}>
                        Disagree
                    </Button>
                    <RedButton onClick={handleAgree} autoFocus disabled={agreeBtnLoading}>
                        Agree
                    </RedButton>
                </DialogActions>
            </Dialog>
        </>
        :
        firstLoading? <CircularProgress/> :
        <>
          <ErrorText>
                {messages.ERROR_COMPANY_ACCESS_NOT_AVAILABLE}
          </ErrorText>

        </>
    );
};

const CustomTable = styled(NewTable)`
    && {
        && {
            &&.MuiTableContainer-root {
                max-height: 150px;
            }
        }
    }
`;

const MainWrapper = styled('div')`
    display: flex;
    flex-direction: column;
`;

const EditButton = styled(Button)`
    width: 180px;
    font-size: 16px !important;
    border: 1.5px solid #2d2d2d !important;
    box-sizing: border-box;
    background-color: #ffffff !important;
`;

const ActiveDeactiveButton = styled(Button)`
    margin-left: 10px !important;
    width: auto;
    font-size: 16px !important;
    border: 1.5px solid #2d2d2d !important;
    box-sizing: border-box;
    background-color: #ffffff !important;

    .MuiSvgIcon-root {
        padding-right: 3px;
        padding-top: 3px;
    }
`;

const DeleteButton = styled(RedButton)`
    margin-left: 10px !important;
    width: auto;
    font-size: 16px !important;

    .MuiSvgIcon-root {
        padding-right: 3px;
        padding-top: 3px;
    }
`;

const SaveButton = styled(RedButton)`
    margin-left: 10px !important;
    width: 180px;
    font-size: 16px !important;
    img {
        padding-right: 3px;
    }
`;

const ButtonsWrapper = styled('div')`
    display: flex;
    height: 50%;
`;

const TitleAndButtons = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 2em;
`;

const TableFiltersAndSearch = styled('div')`
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
`;

const TableFilters = styled('div')`
    display: flex;
`;

const SearchAndSave = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

const CountryText = styled('div')`
    font-style: normal;
    font-weight: bold;
    padding: 5px 0;
    h1 {
        margin: 0;
    }
`;

const ErrorText = styled('div')`
    font-style: normal;
    font-weight: bold;
    padding: 5px 0;
    position: relative;
    top: 45%;
    left: 30%;
    font-size: 25px;
`;

const BackLink = styled('div')`
    cursor: pointer;
    display: flex;
    width: fit-content;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 2em;
    text-decoration: underline;
    line-height: 1.5em;
    img {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }
`;

const TabContainer = styled('div')`
    position: relative;
`;

const NotActiveOverlay = styled('div')`
    position: absolute;
    width: calc(100% + 50px);
    height: calc(100% + 40px);
    background: rgba(0, 0, 0, 0.75);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    z-index: 999;
    transform: translate(-25px, 10px);
    border-radius: 8px;
`;

const NotActiveTitle = styled('h1')`
    text-align: center;
    color: #efefef;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 52px;
    font-size: 2.5em;
`;

export default CompanyDetails;
