import { gql } from 'apollo-boost';

export const USER_DATA = gql`
    query USER_DATA {
        me {
            id
            firstName
            lastName
            jobTitle
            role
            clients {
                id
                name
                isSynced
                country
                createdAt
                updatedAt
            }
            campaigns {
                id
                title
                description
                createdAt
                updatedAt
            }
            countryAdminCountries {
                id
                name
                isoCode
                territory
                gcpRegionId
                countryEnabled
                benchmarkingEnabled
            }
            updatedAt
            createdAt
        }
    }
`;


export const GLOBAL_SETTINGS = gql`
    query globalSettings {
        globalSettings {
        id
        name
        payload {
            name
        }
        status
        updatedAt
        createdAt
        }
    }
`;
