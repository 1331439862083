import { gql } from 'apollo-boost';

export const GET_CLIENTS = gql`
    query clientSummaries(
        $pagination: PaginationInput
        $search: String
        $filters: ClientFilters
    ) {
        clientSummaries(
            pagination: $pagination
            search: $search
            filters: $filters
        ) {
            items {
                id
                name
            }
        }
    }
`;

export const GET_ALL_USERS = gql`
    query users {
        users {
            id
        }
    }
`;

export const DELETE_FRAMEWORK = gql`
mutation admin_deleteFramework($data: DeleteFrameworkInput!) {
    admin_deleteFramework(data: $data) {
        message
    }
  }
`;

export const GET_USERS_BY_CLIENT = gql`
    query usersByClient($clientId: Int!) {
        usersByClient(clientId: $clientId) {
            id
        }
    }
`;

export const GET_USERS_BY_COUNTRY = gql`
    query usersByCountry(
        $countryId: ID!
        $pagination: PaginationInput
        $search: String
        $enabledClientUserIds: [Int!]
        $filters: CountryUserFilters
    ) {
        usersByCountry(
            countryId: $countryId
            pagination: $pagination
            search: $search
            enabledClientUserIds: $enabledClientUserIds
            filters: $filters
        ) {
            items {
                id
            }
        }
    }
`;

export const GET_COUNTRIES = gql`
    query countries {
        countries {
            id
            name
        }
    }
`;

export const CREATE_NOTIFICATION = gql`
    mutation createNotification($input: CreateNotificationInput!) {
        createNotification(input: $input) {
            userId
            notificationType
            notificationCategory
            referenceID
            notificationMessage
            wasRead
        }
    }
`;

export const TRIGGER_OPEN_CAMPAIGNS_NOTIFICATION = gql`
    mutation TriggerOpenCampaignNotification {
        TriggerOpenCampaignNotification {
            userId
            notificationType
            notificationCategory
            referenceID
            notificationMessage
            wasRead
            createdAt
        }
    }
`;
