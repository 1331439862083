import React from 'react';
import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

const SpinnerWithOverlay = () => (
    <Overlay>
        <CircularProgress />
    </Overlay>
);

const Overlay = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: brightness(0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 2;
`;

export default SpinnerWithOverlay;
