import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Menu, MenuProps } from '@mui/material';

type Props = {
    children(buttonDisabled?:boolean): JSX.Element;
    content: React.ReactNode;
    arrowColor?: string;
    top?: string;
    isMenuOpen?: boolean;
    isBoxShadowHide?: boolean;
    menuStyles?: object;
    setMenuStatus?: Function;
    buttonWrapperWidth?: string;
    buttonDisabled?: boolean;
    keepMounted?: boolean;
};

const CustomMenu: React.FC<Props> = ({
    children,
    content,
    arrowColor = '#ffffff',
    top = '-5%',
    isMenuOpen,
    isBoxShadowHide,
    menuStyles,
    setMenuStatus,
    buttonWrapperWidth,
    buttonDisabled = false,
    keepMounted
}) => {
    
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const StyledMenu = styled((props: MenuProps) => 
        <Menu
            elevation={0}
            anchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            {...props}
        />
    )({
        paper: {
            boxShadow: isBoxShadowHide
                ? 'none'
                : '0 0 13px 0 rgba(0, 0, 0, 0.15)',
            marginTop: 10,
            maxWidth: 350,
            overflow: 'visible',
            '&::before': {
                content: '""',
                position: 'absolute',
                right: '45%',
                top: `${top}`,
                width: 0,
                height: 0,
                borderStyle: 'solid',
                borderWidth: '0 10px 10px 10px',
                borderColor: `transparent transparent ${arrowColor} transparent`,
                zIndex: 99981
            }
        }
    });

    useEffect(() => {
        if (!isMenuOpen) {
            handleClose();
        }
    }, [isMenuOpen]);
    
    function handleClick(event: React.MouseEvent<HTMLElement>) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setMenuStatus && setMenuStatus(false);
        !keepMounted && setAnchorEl(null);
    }
    return (
        <ButtonWrapper width={buttonWrapperWidth}>
            <div
                onClick={!buttonDisabled ? handleClick : undefined}
                style={{
                    textTransform: 'none',
                    minWidth: 0,
                    padding: 0,
                    ...menuStyles
                }}
                data-testid="styled-button"
            >
                {children(buttonDisabled)}
            </div>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                // keepMounted
                open={Boolean(anchorEl)}
                onClick={() => !keepMounted && handleClose()}
                onClose={() => !keepMounted && handleClose()}
                data-testid="styled-menu"
            >
                {content}
            </StyledMenu>
        </ButtonWrapper>
    );
};

CustomMenu.defaultProps = {
    menuStyles: {},
    buttonWrapperWidth: ''
};

const ButtonWrapper = styled('div')<{ width?: string }>`
    width: ${props => (props.width ? props.width : 'auto')};
`;

export default CustomMenu;
