import { styled } from '@mui/material/styles';
import CustomButton from './Button';

const RedButton = styled(CustomButton)`
    && {
        background-color: ${props => props.disabled ? '#F49791' : '#DE3328'} !important;
        color: white !important;
        cursor: ${props => props.disabled ? 'auto' : 'pointer'};

        &:hover {
            background-color: ${props => props.disabled ? '#F49791' : '#BF1B10'} !important;
        }
        &:disabled {
            background-color: #f5928b;
            color: white;
           }
    }
`;


export default RedButton;
